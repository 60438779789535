import React from 'react';
import styled from 'styled-components';
import { helix } from 'ldrs';

helix.register();

const Wrapper = styled.div``;
export default function Cell() {
  return (
    <Wrapper>
      <l-helix size="45" speed="2.5" color="#3075cf"></l-helix>
    </Wrapper>
  );
}
