import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import styled from 'styled-components';
import { IntegrationIconSvg, ArrowForwardSvg, CheckIconSvg } from 'assets/img';
import IntegrationCard from 'components/IntegrationCard';
import CustomizedSteppers from 'components/CustomizedSteppers';
import MainButton from 'components/MainButton';
import { serverRequestApi } from 'gql';
import {
  UserTasksDetailVo,
  TaskProgressVo,
  TaskProgressMapEntryVo,
} from 'gql/server/__generated__/request';
import { useHistory } from 'react-router-dom';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import { useToast } from 'components/ToastContext';
import WebApp from '@twa-dev/sdk';

const gradientBackground = `
  radial-gradient(
    83.47% 83.47% at 21.88% 27.08%,
    #7aee51 16.69%,
    #73eb9e 100%
  )
`;
const alternateColor = 'rgba(255, 255, 255, 0.50)';

const ContainerBox = styled(Box)`
  flex: 1;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  z-index: 99999;
`;

const ContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Title = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 20px;
  font-weight: 700;
`;

const IntegrationBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const IntegrationIcon = styled(IntegrationIconSvg)`
  width: 66px;
  height: auto;
  margin-right: 6px;
  margin-top: 4px;
`;

const IntegrationText = styled(Box)`
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 58px;
  font-weight: 400;
  background: ${gradientBackground};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const DescText = styled(Typography)`
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const AchievedButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
`;

const AchievedButtonText = styled(Typography)`
  color: #fff;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

const ArrowForward = styled(ArrowForwardSvg)`
  width: 20px;
  height: auto;
  margin-left: 4px;
`;

const DailyBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  padding: 16px 0;
`;

const DailyTitleBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
`;

const DailyTitle = styled(Typography)`
  color: #fff;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-weight: 700;
`;

const DailyIntegrationBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DailyIntegrationIcon = styled(IntegrationIconSvg)`
  width: 30px;
  height: auto;
  margin-top: 2px;
  margin-right: 4px;
`;

const DailyIntegrationText = styled(({ ...props }) => (
  <Typography {...props} />
))`
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-weight: 700;
  background: ${(props) =>
    props.isZero ? alternateColor : gradientBackground};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const DailyDesc = styled(Typography)`
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 12px;
  width: 100%;
  padding: 0 20px;
`;

const DailyIntegrationCardBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  padding: 0 20px;
  margin: 16px 0;
  width: 100%;
`;

const ClaimButton = styled(Button)`
  display: flex;
  height: 52px;
  padding: 16px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0px 8px 68px rgba(0, 0, 0, 0.2);
  color: #111;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  width: 100%;
`;

const ItemContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
`;

const ItemBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 0;
  border-radius: 20px;
  background: radial-gradient(
    150.2% 50% at 50% 50%,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  box-shadow: inset -0.5px 0.5px 2px rgba(144, 159, 148, 0.55);
  margin-top: 20px;
`;

const ItemButtonBox = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
`;

const ItemButton = styled(Box)`
  display: flex;
  width: 100%;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #111;
  color: #fff;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  border: 1.5px solid #5efb4c;
`;

const CheckBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StepperBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  gap: 12px;
  width: 100%;
`;

const CheckIcon = styled(CheckIconSvg)`
  width: 20px;
  height: auto;
  margin-right: 10px;
`;

const TitleBox = React.memo(
  ({ title, points }: { title: string; points: number }) => (
    <DailyTitleBox>
      <DailyTitle>{title}</DailyTitle>
      <DailyIntegrationBox>
        <DailyIntegrationIcon />
        <DailyIntegrationText isZero={points === 0}>
          +{points}
        </DailyIntegrationText>
      </DailyIntegrationBox>
    </DailyTitleBox>
  ),
);
TitleBox.displayName = 'TitleBox';

const EarnInPicwiz: React.FC = () => {
  const history = useHistory();
  const { startLoading, stopLoading } = useToast();

  const [tanksDetail, setTanksDetail] = useState<UserTasksDetailVo>({});
  const [initialDetail, setInitialDetail] = useState<TaskProgressVo>({});
  const [dailyDetail, setDailyDetail] = useState<TaskProgressVo>({});
  const [inviteDetail, setInviteDetail] = useState<TaskProgressVo>({});
  const [createDetail, setCreateDetail] = useState<TaskProgressVo>({});
  const [likesDetail, setLikesDetail] = useState<TaskProgressVo>({});

  const [inviteCode, setInviteCode] = useState<string>('');

  const handleClaim = useCallback(() => {
    startLoading();
    async function ClaimDailyTask() {
      try {
        const response = await serverRequestApi.dailySign();
        stopLoading();
        if (response && response.dailySign) {
          fetchRewardsAndTasks();
        }
      } catch (error) {
        stopLoading();
        console.log('Error fetching user ranks:', error);
      }
    }

    ClaimDailyTask();
  }, []);

  const handleCreateAvatar = () => {
    history.push('/chat');
  };

  async function fetchRewardsAndTasks() {
    startLoading();
    try {
      const response = await serverRequestApi.getRewardsAndTasks();

      console.log('response', response);
      formatTasks(response.rewardsAndTasks?.tasks ?? []);

      setTanksDetail(response.rewardsAndTasks || {});

      stopLoading();
    } catch (error) {
      console.log('Error fetching user ranks:', error);
      stopLoading();
    }
  }

  useEffect(() => {
    fetchRewardsAndTasks();
    serverRequestApi.getInviteCode().then((response) => {
      setInviteCode(response.inviteCode ?? '');
    });
  }, []);

  const formatTasks = (tasks: TaskProgressMapEntryVo[]) => {
    const defaultTask: TaskProgressVo = { totalReward: 0, rewards: [] };

    const likeValue =
      tasks.find((item) => item.key === 'like')?.value || defaultTask;
    setLikesDetail(likeValue);

    const dailyValue =
      tasks.find((item) => item.key === 'sign')?.value || defaultTask;
    setDailyDetail(dailyValue);

    const inviteValue =
      tasks.find((item) => item.key === 'invite')?.value || defaultTask;
    setInviteDetail(inviteValue);

    const initialValue =
      tasks.find((item) => item.key === 'initial')?.value || defaultTask;
    setInitialDetail(initialValue);

    const createValue =
      tasks.find((item) => item.key === 'create_avatar')?.value || defaultTask;
    setCreateDetail(createValue);
  };

  const handleInviteAction = () => {
    const appName = 'LazAITestBot';
    const shortAppName = 'LazAITest';
    const inviteLink = `https://t.me/${appName}/${shortAppName}?startapp=${inviteCode}`;
    const shareLink = `https://t.me/share/url?url=${inviteLink}`;

    WebApp.openTelegramLink(shareLink);
  };

  const variants = {
    hidden: {
      opacity: 0,
      y: 80,
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.4,
        duration: 0.5,
      },
    }),
  };

  return (
    <ContainerBox>
      <ContentBox>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={0}
          style={{ width: '100%' }}
        >
          <Title>LazAI Royalty Point in PicWiz</Title>
        </motion.div>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={0.5}
          style={{ width: '100%' }}
        >
          <IntegrationBox>
            <IntegrationIcon />
            <IntegrationText>
              <CountUp
                start={0}
                end={tanksDetail.userPoints ?? 0}
                duration={1.5}
                delay={1.5}
                separator=","
                style={{
                  fontFamily: '"Bai Jamjuree", sans-serif',
                  fontWeight: 400,
                }}
              />
            </IntegrationText>
          </IntegrationBox>
        </motion.div>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={1}
          style={{ width: '100%' }}
        >
          <DescText>
            Collect Points & Earn
            <br />
            The more you participate, the more your earn
          </DescText>
        </motion.div>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={1.5}
          style={{
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <AchievedButton
            onClick={() => {
              history.push('/explore');
            }}
          >
            <AchievedButtonText>Check Total Points</AchievedButtonText>
            <ArrowForward />
          </AchievedButton>
        </motion.div>
      </ContentBox>

      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={2}
        style={{ width: '100%' }}
      >
        <DailyBox>
          <TitleBox
            title="Daily Checkin"
            points={dailyDetail.totalReward ?? 0}
          />
          <DailyDesc>
            Log in 7 days in a row, rewards will grow. Refresh every week
          </DailyDesc>
          <DailyIntegrationCardBox>
            {(dailyDetail.rewards ?? []).map((item, idx) => (
              <IntegrationCard
                key={`day-${idx}`}
                points={item.reward ?? 0}
                dayText={`Day ${item.condition}`}
                selected={item.reward !== 0}
              />
            ))}
          </DailyIntegrationCardBox>
          <ItemButtonBox>
            <MainButton
              text="Claim Now"
              disabled={tanksDetail.hasSignedIn === 1}
              onClick={handleClaim}
            />
          </ItemButtonBox>
        </DailyBox>
      </motion.div>

      <ItemContentBox>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={2.5}
          style={{ width: '100%' }}
        >
          <ItemBox>
            <TitleBox
              title="Invite Friends"
              points={inviteDetail.totalReward ?? 0}
            />
            <DailyDesc>Rewards increase with the number of friends</DailyDesc>
            <StepperBox>
              <CustomizedSteppers
                steps={inviteDetail.rewards ?? []}
                contentStr="friend"
              />
            </StepperBox>
            <ItemButtonBox>
              <ItemButton onClick={handleInviteAction}>
                INVITE FRIENDS
              </ItemButton>
            </ItemButtonBox>
          </ItemBox>
        </motion.div>

        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={3}
          style={{ width: '100%' }}
        >
          <ItemBox>
            <TitleBox
              title="Create My Avatar"
              points={createDetail.totalReward ?? 0}
            />
            <DailyDesc>
              The first creation of each day will have a reward
            </DailyDesc>
            <DailyIntegrationCardBox>
              {(createDetail.rewards ?? []).map((item, idx) => (
                <IntegrationCard
                  key={`day-${idx}`}
                  points={idx === 0 ? item.reward ?? 0 : 50}
                  width="38vw"
                  dayText={
                    idx === 0
                      ? `Created ${item.count} ${
                          item.count && Number(item.count) > 1 ? 'Days' : 'Day'
                        }`
                      : `Today`
                  }
                  selected={item.isFinish === 1}
                />
              ))}
            </DailyIntegrationCardBox>
            <ItemButtonBox onClick={handleCreateAvatar}>
              <ItemButton>START CREATE</ItemButton>
            </ItemButtonBox>
          </ItemBox>
        </motion.div>

        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={3.5}
          style={{ width: '100%' }}
        >
          <ItemBox>
            <TitleBox
              title="I’m be like"
              points={likesDetail.totalReward ?? 0}
            />
            <DailyDesc>Share your Full NFT to get like</DailyDesc>
            <CustomizedSteppers
              steps={likesDetail.rewards ?? []}
              contentStr="like"
            />
          </ItemBox>
        </motion.div>

        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={4}
          style={{ width: '100%' }}
        >
          <ItemBox
            sx={{ flexDirection: 'row !important', marginBottom: '20px' }}
          >
            <DailyTitleBox>
              <CheckBox>
                <CheckIcon />
                <DailyTitle>Account Connected</DailyTitle>
              </CheckBox>
              <DailyIntegrationBox>
                <DailyIntegrationIcon />
                <DailyIntegrationText
                  isZero={
                    !initialDetail.totalReward ||
                    initialDetail.totalReward === 0
                  }
                >
                  +{initialDetail.totalReward ?? 0}
                </DailyIntegrationText>
              </DailyIntegrationBox>
            </DailyTitleBox>
          </ItemBox>
        </motion.div>
      </ItemContentBox>
    </ContainerBox>
  );
};

export default EarnInPicwiz;
