import React, { useState } from 'react';
import { Box, Typography, Button, Chip } from '@mui/material';
import { styled } from '@mui/system';
import { AddImageIconSvg, RemoveIconSvg } from 'assets/img';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CreateStyle.css';

const AddImageIcon = styled(AddImageIconSvg)(() => ({
  width: '24px',
  height: 'auto',
}));

const RemoveIcon = styled(RemoveIconSvg)(() => ({
  width: '36px',
  height: '36px',
}));

const ImageContainer = styled(Box)(() => ({
  display: 'flex',
  width: '218px',
  height: '218px',
  position: 'relative',
  alignItems: 'center',
  justifyItems: 'center',
  overflow: 'revert',
}));

const StyledImage = styled('img')(() => ({
  display: 'flex',
  width: '200px',
  height: '200px',
  borderRadius: '12px',
  objectFit: 'cover',
  position: 'relative',
  transition: 'all 0.5s ease',
}));

const TagChips = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  left: '50%',
  bottom: 0,
  transform: 'translateX(-50%)',
  zIndex: 1000,
}));

const NextButton = styled(Button)(() => ({
  display: 'flex',
  height: '52px',
  padding: '16px 10px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  flex: '1 0 0',
  borderRadius: '16px',
  background:
    'radial-gradient(92.07% 129.88% at 7.99% 8.33%, #B2FF51 0%, #4EE88C 100%)',
  boxShadow: '0px 8px 68px 0px rgba(0, 0, 0, 0.20)',
  color: '#111',
  textAlign: 'center',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: '"Bai Jamjuree", sans-serif',
}));

const UploadTitle = styled(Typography)(() => ({
  color: '#53EB8D',
  textAlign: 'center',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 590,
  lineHeight: '32px',
  marginTop: '68px',
  position: 'relative',
  fontFamily: '"Bai Jamjuree", sans-serif',
}));

const UploadDesc = styled(Typography)(() => ({
  color: '#fff',
  textAlign: 'center',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  marginTop: '20px',
  marginBottom: '28px',
  position: 'relative',
  fontFamily: '"Bai Jamjuree", sans-serif',
}));

const RemoveBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: -8,
}));

const ChipItem = styled(Chip)(() => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '16px',
  border: '4px solid #638070',
  background:
    'var(--Bubble-highlight, radial-gradient(179.22% 92.71% at 50% 7.29%, #274446 0%, #546962 100%))',
  boxShadow: '0px 6px 10.8px 0px rgba(203, 237, 188, 0.50) inset',
  color: '#fff',
  textAlign: 'center',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 590,
  lineHeight: '20px',
  fontFamily: '"Bai Jamjuree", sans-serif',
}));

const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  paddingLeft: '24px',
  paddingRight: '24px',
  position: 'absolute',
  left: 0,
  bottom: '40px',
}));

const AddButton = styled(Button)(() => ({
  display: 'flex',
  minWidth: '52px',
  height: '52px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '16px',
  border: '1px solid rgba(83, 235, 141, 0.08)',
  background:
    'radial-gradient(79.76% 79.76% at 64.64% 35.45%, rgba(83, 235, 141, 0.40) 0%, rgba(83, 235, 141, 0.04) 100%)',
  marginRight: '16px',
  textTransform: 'none',
}));

const UploadedImagesPreview: React.FC<{
  images: Array<{ src: string; tags: string[] }>;
}> = ({ images }) => {
  const history = useHistory();

  const [imageList, setImageList] = useState(images);
  const [imageIndex, setImageIndex] = useState(0);

  const handleDelete = (index: number) => {
    setImageList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleNextClick = () => {
    console.log('Next clicked');
    history.push('/styleDetail');
  };

  const sliderSettings = {
    className: 'center',
    centerMode: true,
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    variableWidth: true,
    focusOnSelect: true,
    beforeChange: (current: number, next: number) => {
      setImageIndex(next);
    },
  };

  return (
    <Box sx={{ position: 'relative', flexGrow: 1 }}>
      <UploadTitle>Upload image</UploadTitle>
      <UploadDesc>
        LazAI agent has added tags to these
        <br />
        images you prepared.
        <br />
        Click NEXT if you confirm images
      </UploadDesc>

      <Slider {...sliderSettings}>
        {imageList.map((image, index) => (
          <ImageContainer key={index}>
            <StyledImage
              src={image.src}
              alt={`Uploaded ${index + 1}`}
              sx={{
                width: imageIndex === index ? '200px' : '180px',
                height: imageIndex === index ? '200px' : '180px',
                marginTop: imageIndex === index ? '9px' : '19px',
                marginLight: imageIndex === index ? '9px' : '19px',
                marginRight: imageIndex === index ? '9px' : '10px',
              }}
            />
            {imageIndex === index ? (
              <RemoveBox onClick={() => handleDelete(index)}>
                <RemoveIcon />
              </RemoveBox>
            ) : null}
            {imageIndex === index ? (
              <TagChips>
                {image.tags.map((tag, tagIndex) => (
                  <ChipItem key={tagIndex} label={tag} />
                ))}
              </TagChips>
            ) : null}
          </ImageContainer>
        ))}
      </Slider>
      <ButtonBox>
        <AddButton
          onClick={() => document.getElementById('fileInput')?.click()}
        >
          <AddImageIcon />
        </AddButton>
        <input
          id="fileInput"
          type="file"
          accept="image/*"
          multiple
          hidden
          onChange={(e) => {
            console.log(e.target.files);
          }}
        />
        <NextButton onClick={handleNextClick}>Next</NextButton>
      </ButtonBox>
    </Box>
  );
};

export default UploadedImagesPreview;
