import Back from 'components/Back';
import { AutoColumn } from 'components/Column';
import { RowCenter } from 'components/Row';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { TYPE } from 'theme';
import Card from '@mui/material/Card';
import { getFacechainImageSrc } from 'utils/index';
import { observer } from 'mobx-react-lite';
import models from 'stores/models';

const Wrapper = styled.div`
  padding: 0 20px;
`;
const MyModelsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const MyModel = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(50% - 10px);
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
`;
const MyModelTextWrapper = styled(AutoColumn)`
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  gap: 10px;
`;

function ModelList() {
  const history = useHistory();

  useEffect(() => {
    models.getModals();
  }, [models]);

  return (
    <Wrapper>
      <Back />
      <AutoColumn gap="20px">
        <RowCenter>
          <TYPE.main fontSize={16} fontWeight={600} color={'t03'}>
            模型列表
          </TYPE.main>
        </RowCenter>
        <MyModelsWrapper>
          {models.models.map((model) => {
            return (
              <MyModel
                key={model.id}
                onClick={() =>
                  history.push(`/selectstyle?model_id=${model.id}`)
                }
              >
                <img src={getFacechainImageSrc(model.styles[0].image)} />
                <MyModelTextWrapper>
                  <TYPE.main fontSize={14} color={'#fff'}>
                    {model.name}
                  </TYPE.main>
                  <TYPE.desc fontSize={12} color="#CED0DE">
                    作者：PicWiz
                  </TYPE.desc>
                </MyModelTextWrapper>
              </MyModel>
            );
          })}
        </MyModelsWrapper>
      </AutoColumn>
    </Wrapper>
  );
}

export default observer(ModelList);
