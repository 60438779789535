import dayjs from 'dayjs';
const SERVER_URI = process.env.REACT_APP_SERVER_URI;
// export function getFacechainImageSrc(path: string): string {
//   return `${SERVER_URI}/file?path=${path}`;
// }

export function getFacechainImageSrc(path: string): string {
  // return `${SERVER_URI}/file?path=${path}`;
  return path;
}

export function formatDateTime(timestamp: number): string {
  return dayjs(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
}

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export function isLocal() {
  return (
    ['https://localhost', 'https://127.0.0.1'].indexOf(
      window.location.origin,
    ) >= 0
  );
}
