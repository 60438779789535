import Back from 'components/Back';
import Column, { AutoColumn } from 'components/Column';
import Row, { RowBetween, RowCenter } from 'components/Row';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { TYPE } from 'theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useHistory, useParams } from 'react-router-dom';
import { useTaskOperations } from 'hooks/useTask';
import { Task, Style, Model } from 'gql/query';
import { getFacechainImageSrc, formatDateTime } from 'utils/index';
import Button from 'components/Button';
import { observer } from 'mobx-react';
import models from 'stores/models';
// import SliderImage from 'components/SliderImage';
import {
  CameraSvg,
  ShareSvg,
  TimeSvg,
  LeftArrowSvg,
  RightArrowSvg,
} from 'assets/img';

const Wrapper = styled(Column)`
  gap: 4px;
  padding: 0 10px;
  padding-bottom: 36px;
`;

const ContentWrapper = styled(Column)`
  align-items: center;
  gap: 20px;
`;

function PrewArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <LeftArrowSvg
      className={className}
      style={{ ...style, display: 'block', width: '32px', height: '32px ' }}
      onClick={onClick}
    />
  );
}

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <RightArrowSvg
      className={className}
      style={{ ...style, display: 'block', width: '32px', height: '32px ' }}
      onClick={onClick}
    />
  );
}

const ItemWrapper = styled.div`
  width: 45%;

  height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: #fff;
  border-radius: 16px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
  }
`;

const SliderWrapper = styled(Slider)`
  width: calc(100% - 20px);
  flex: 1;
  .slider {
    height: calc(100vw - 20px);
  }
  // height: 50%;

  .slick-prev {
    left: -20px;
    z-index: 9;
  }

  .slick-next {
    right: -20px;
    z-index: 9;
  }
`;

// const SliderImg = styled.img`
//   width: 100%;
// `;

const SliderImg = styled.img`
  height: 45vh;
  width: auto;
  margin: 0 auto;
`;

const StyledItemButton = styled(Button)`
  font-size: 12px;
  padding: 4px 8px !important;
  height: 26px !important;
  &.MuiButton-outlined {
    border-radius: 13px !important;
    border-color: #3772ff;
    color: #3772ff;
  }
`;

const StyledRowCenter = styled(RowCenter)`
  gap: 20px;
  button {
    height: 54px;
    background: #ffffff !important;
    color: ${({ theme }) => theme.c.t03} !important;
    border-radius: 30px;
    gap: 20px;
    font-weight: 600;
    width: 45%;
    justify-content: space-between;
    padding-right: 12%;

    svg {
      flex-shrink: 0;
    }
  }
`;

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <PrewArrow />,
  nextArrow: <NextArrow />,
};
function TaskDetail() {
  const [task, setTask] = useState<Task>();
  const [model, setModel] = useState<Model>();
  const [style, setStyle] = useState<Style>();
  const history = useHistory();
  const { id: taskId } = useParams<{ id: string }>();

  const { getTask } = useTaskOperations();

  useEffect(() => {
    if (taskId) {
      getTask(taskId).then((taskFromQuery) => {
        if (taskFromQuery) {
          setTask(taskFromQuery);
        }
      });
    }
  }, [taskId]);

  useEffect(() => {
    models.getModals();
  }, [models]);

  useEffect(() => {
    if (task) {
      for (const _model of models.models) {
        for (const _style of _model.styles) {
          if (_style.id === task.style.id) {
            setStyle(_style);
            setModel(_model);
            return;
          }
        }
      }
    }
  }, [task, models.models]);

  function share() {
    // if ((window as any)?.Telegram?.WebApp) {
    //   (window as any).Telegram.WebApp.openLink(
    //     `https://t.me/share?url=https://google.com&text=google`,
    //   );
    // }
  }

  return (
    <Wrapper>
      <Back path="/my" />
      <ContentWrapper style={{}}>
        <Column style={{ gap: '10px', alignItems: 'center' }}>
          {/* <TYPE.main>ID: {task?.id}</TYPE.main> */}
          <TYPE.main fontSize={20} mobileFz={20} color={'t03'}>
            tg-{task?.id}
          </TYPE.main>
        </Column>
        <RowCenter style={{ gap: '10px', alignItems: 'center' }}>
          {/* <TYPE.main>ID: {task?.id}</TYPE.main> */}
          <TimeSvg />

          <TYPE.desc alignItems={'center'}>
            {task?.createTime ? formatDateTime(task.createTime) : '----'}
          </TYPE.desc>
        </RowCenter>
        <RowBetween>
          <ItemWrapper>
            {/* <img src={require('assets/img/ghost.png')} alt="" /> */}
            <img src={getFacechainImageSrc(model?.styles[0].image || '')} />
            <AutoColumn gap="4px">
              <TYPE.main fontSize={12} color={'t03'} ellipsis>
                模型：{model?.name.substring(0, 6)}
              </TYPE.main>
              <StyledItemButton
                variant="outlined"
                size="small"
                onClick={() => {
                  history.push('/models');
                }}
              >
                更换模型
              </StyledItemButton>
            </AutoColumn>
          </ItemWrapper>
          <ItemWrapper>
            {/* <img src={require('assets/img/ghost.png')} alt="" /> */}
            <img src={getFacechainImageSrc(style?.image || '')} />
            <AutoColumn gap="4px">
              <TYPE.main fontSize={12} color={'t03'} ellipsis>
                风格：{style?.title.substring(0, 6)}
              </TYPE.main>
              <StyledItemButton
                variant="outlined"
                onClick={() => {
                  history.push(
                    `/selectstyle?model_id=${model?.id}&style_id=${style?.id}`,
                  );
                }}
              >
                更换风格
              </StyledItemButton>
            </AutoColumn>
          </ItemWrapper>
        </RowBetween>
        <SliderWrapper {...settings}>
          {/* <SliderImage
            className={'slider'}
            elementType="img"
            images={task?.images || []}
          /> */}
          {task?.images.map((img) => {
            return (
              <div key={img}>
                <SliderImg src={img} />
              </div>
            );
          })}
        </SliderWrapper>
        <StyledRowCenter>
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              history.push(
                `/selectstyle?model_id=${model?.id}&style_id=${style?.id}`,
              );
            }}
          >
            <CameraSvg />
            再次生成
          </Button>
          <Button variant="contained" size="medium" onClick={share}>
            <ShareSvg />
            分享
          </Button>
        </StyledRowCenter>
      </ContentWrapper>
    </Wrapper>
  );
}

export default observer(TaskDetail);
