import React, { useState } from 'react';
import { Box, TextField, Chip, Button, Typography } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { DeleteIconSvg, AddIconSvg } from 'assets/img';
import styled from 'styled-components';
import Back from 'components/Back';

const DeleteIcon = styled(DeleteIconSvg)`
  width: 14px;
  height: auto;
`;

const AddIcon = styled(AddIconSvg)`
  width: 14px;
  height: auto;
`;

const ContainerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding-left: 24px;
  padding-right: 24px;
`;

const TextFieldBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex-direction: row;
  width: 100%;
`;

const CustomTextField = styled(TextField)`
  display: flex;
  & .MuiOutlinedInput-root {
    border-radius: 0;
    & fieldset {
      border: none;
    }
  }
  & .MuiInputBase-input::placeholder {
    color: rgba(255, 255, 255, 0.2);
    opacity: 1;
  }
  & .MuiInputBase-input {
    caret-color: #2be4d9;
    color: #ffffff;
    font-size: 32px;
    font-weight: 700;
    padding: 0;
    font-family: 'Bai Jamjuree', sans-serif;
  }
  flex-grow: 1;
`;

const TitleTag = styled(Typography)`
  display: flex;
  padding: 4px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--white-pale, linear-gradient(90deg, #fff 0%, #e3ffe6 100%));
  color: #162a69;
  text-align: center;
  font-size: 14px;
  font-weight: 860;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const TagsBox = styled(Box)`
  margin-top: 48px;
  width: 100%;
`;

const TagTitle = styled(Typography)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const TagsContentBox = styled(Box)`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const AddTagBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 4px solid var(--Bubble-border, #638070);
  background: var(
    --Bubble-highlight,
    radial-gradient(179.22% 92.71% at 50% 7.29%, #274446 0%, #546962 100%)
  );
  box-shadow: 0px 6px 10.8px 0px rgba(203, 237, 188, 0.5) inset;
`;

const AddTagTextField = styled(TextField)`
  height: 32px;
  width: 115px;
  display: flex;
  & .MuiInputBase-root {
    height: 32px;
    padding: 0;
  }
  & .MuiOutlinedInput-root {
    border-radius: 12px;
    & fieldset {
      border: none;
    }
  }
  & .MuiInputBase-input {
    caret-color: #2be4d9;
    color: #ffffff;
  }
`;

const AddTagChip = styled(Chip)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px 0 0;
  height: 32px;
  gap: 8px;
  border-radius: 12px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 860;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const TagChip = styled(Chip)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px 0 0;
  height: 40px;
  gap: 8px;
  border-radius: 12px;
  border: 4px solid rgba(82, 101, 113, 0.5);
  background: radial-gradient(
    150.2% 50% at 50% 50%,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 860;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const SwitchBox = styled(Box)`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  width: 100%;
  border-radius: 12px;
  background: var(
    --Subtle-box-bg,
    radial-gradient(
      150.2% 50% at 50% 50%,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.1) 100%
    )
  );
  margin-top: 40px;
  justify-content: space-between;
`;

const SwitchTitle = styled(Typography)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const StartButton = styled(Button)`
  display: flex;
  height: 52px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: radial-gradient(
    92.07% 129.88% at 7.99% 8.33%,
    #b2ff51 0%,
    #4ee88c 100%
  );
  box-shadow: 0px 8px 68px 0px rgba(0, 0, 0, 0.2);
  color: #111;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  width: 90%;
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  font-family: 'Bai Jamjuree', sans-serif;
`;

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))`
  width: 42px;
  height: 26px;
  padding: 0;

  & .MuiSwitch-switchBase {
    padding: 0;
    margin: 2px;
    transition-duration: 300ms;

    &.Mui-checked {
      transform: translateX(16px);
      color: #7aee51;

      & + .MuiSwitch-track {
        background-color: #132828;
        opacity: 1;
        border: 0;
      }

      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
    }
  }

  & .MuiSwitch-thumb {
    box-sizing: border-box;
    width: 22px;
    height: 22px;
  }

  & .MuiSwitch-track {
    border-radius: 13px; /* 26px / 2 */
    background-color: #132828;
    opacity: 1;
  }
`;

const StyleDetail: React.FC = () => {
  const [tags, setTags] = useState<string[]>([
    'POP star',
    'Jewelry',
    '1 girl',
    'addTag',
  ]);
  const [newTag, setNewTag] = useState<string>('');
  const [publicUse, setPublicUse] = useState<boolean>(true);
  const [enhanceFace, setEnhanceFace] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [isAddingTag, setIsAddingTag] = useState<boolean>(false); // 控制新标签输入框的显示

  const handleAddTag = () => {
    if (newTag.trim() && !tags.includes(newTag.trim())) {
      const tagList = tags.filter((tag) => tag !== 'addTag');
      tagList.push(newTag.trim());
      tagList.push('addTag');
      setTags(tagList);
      setNewTag('');
      setIsAddingTag(false);
    }
  };

  const handleDeleteTag = (tagToDelete: string) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const handleStartTraining = () => {};

  return (
    <ContainerBox>
      <Back />
      <TextFieldBox>
        <CustomTextField
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter style name"
          variant="outlined"
        />
        <TitleTag>FulNFT</TitleTag>
      </TextFieldBox>

      <TagsBox>
        <TagTitle>Manually modify trigger tags</TagTitle>
        <TagsContentBox>
          {tags.map((tag, index) =>
            tag === 'addTag' ? (
              <AddTagBox key={index}>
                {isAddingTag ? (
                  <AddTagTextField
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    variant="outlined"
                    onKeyDown={(e) => {
                      if (e.key === 'Done') handleAddTag();
                    }}
                    onBlur={handleAddTag}
                    autoFocus
                    inputProps={{
                      enterKeyHint: 'done',
                      type: 'text',
                    }}
                  />
                ) : (
                  <AddTagChip
                    key={index}
                    label={'New tag '}
                    onClick={() => setIsAddingTag(true)}
                    onDelete={() => setIsAddingTag(true)}
                    deleteIcon={<AddIcon />}
                  />
                )}
              </AddTagBox>
            ) : (
              <TagChip
                key={index}
                label={tag}
                onDelete={() => handleDeleteTag(tag)}
                deleteIcon={<DeleteIcon />}
              />
            ),
          )}
        </TagsContentBox>
      </TagsBox>

      <SwitchBox>
        <SwitchTitle>Public use</SwitchTitle>
        <IOSSwitch
          checked={publicUse}
          onChange={(e) => setPublicUse(e.target.checked)}
        />
      </SwitchBox>
      <SwitchBox sx={{ marginTop: '20px' }}>
        <SwitchTitle>Enhancing face similarly</SwitchTitle>
        <IOSSwitch
          checked={enhanceFace}
          onChange={(e) => setEnhanceFace(e.target.checked)}
        />
      </SwitchBox>

      <StartButton onClick={handleStartTraining}>
        Start training style
      </StartButton>
    </ContainerBox>
  );
};

export default StyleDetail;
