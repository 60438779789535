import React from 'react';
import { Dialog, Button, Box, Typography } from '@mui/material';
import {
  TestAvatarSvg,
  StyleCardIconSvg,
  CloseIconSvg,
  GlendaFunkSvg,
} from 'assets/img';
import styled from 'styled-components';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { serverRequestApi } from 'gql';
import { StyleDetailVo, StyleImageVo } from 'gql/server/__generated__/request';
import MainButton from 'components/MainButton';
import { useToast } from 'components/ToastContext';

const Avatar = styled('img')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const TagBox = styled(Box)`
  display: flex;
  width: 90px;
  height: 28px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 16px;
  background: #010500;
  position: absolute;
  top: 10px;
  left: 10px;
`;

const StyleCardIcon = styled(StyleCardIconSvg)`
  width: 24px;
  height: auto;
`;

const TagLabel = styled(Box)`
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 860;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
  margin-right: 5px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  min-width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
`;

const CloseIcon = styled(CloseIconSvg)`
  width: 17px;
  height: auto;
`;

const ContentBox = styled(Box)`
  display: flex;
  padding: 32px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 28px;
  border: 4px solid var(--Bubble-border, #638070);
  background: radial-gradient(
    147.88% 86.15% at 90.51% 9.74%,
    rgba(84, 105, 98, 0.85) 0%,
    rgba(39, 68, 70, 0.85) 77.63%
  );
  box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2.5px);
`;

const ContentTitle = styled(Typography)`
  color: var(--White, #fff);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  font-family: 'Bai Jamjuree', sans-serif;
  margin-top: 20px;
`;

const UserAvatar = styled(GlendaFunkSvg)`
  width: 20px;
  height: auto;
  margin-right: 10px;
`;

const UserName = styled(Typography)`
  color: var(--White-50, rgba(255, 255, 255, 0.5));
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const ContentDesc = styled(Typography)`
  color: var(--White, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const EmptyBox = styled(Typography)`
  flex-grow: 1;
  min-height: 200px;
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EmptyMessage = styled(Typography)`
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.442px;
`;

const ImageBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow-y: auto;
  padding: 10px;
`;

const ImageItem = styled(Box)`
  aspect-ratio: 1 / 1;
  position: relative;
  padding-top: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const LikeBox = styled(Box)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  height: 36px;
  padding: 8px 12px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
`;

const LikeLabel = styled(Typography)`
  color: white;
  margin-left: 4px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

interface StyleCardProps {
  open: boolean;
  styleDetail: StyleDetailVo;
  imageList: StyleImageVo[];
  handleClose: () => void;
  handleUseBtn: () => void;
  getStyleDetail: (uuid: string) => void;
}

const StyleCard: React.FC<StyleCardProps> = ({
  open,
  styleDetail,
  imageList,
  handleClose,
  handleUseBtn,
  getStyleDetail,
}) => {
  const { startLoading, stopLoading } = useToast();

  const handleLikeBtn = (image: StyleImageVo) => {
    const styleLikeReq = { uuid: image.uuid ?? '' };
    startLoading();
    if (image.isLiked === '0') {
      serverRequestApi
        .likeImage({ styleLikeReq })
        .then((response) => {
          stopLoading();
          if (response.likeImage) {
            getStyleDetail(styleDetail.uuid ?? '');
          }
        })
        .catch((error) => {
          stopLoading();
          console.log('Error liking image:', error);
        });
    } else {
      serverRequestApi
        .cancelLikeImage({ styleLikeReq })
        .then((response) => {
          stopLoading();
          if (response.cancelLikeImage) {
            getStyleDetail(styleDetail.uuid ?? '');
          }
        })
        .catch((error) => {
          stopLoading();
          console.log('Error liking image:', error);
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
      }}
    >
      <ContentBox>
        <TagBox>
          <StyleCardIcon />
          <TagLabel>Model</TagLabel>
        </TagBox>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <ContentTitle>{styleDetail.name}</ContentTitle>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <UserAvatar />
          <UserName>{`Created by ${styleDetail.username}`}</UserName>
        </Box>
        <ContentDesc>
          View the output generated by other DAO members for reference
        </ContentDesc>
        {imageList.length > 0 ? (
          <ImageBox>
            {imageList.map((image) => (
              <ImageItem key={image.uuid}>
                <Avatar src={image.generatedImagePath} />
                <LikeBox
                  onClick={() => {
                    handleLikeBtn(image);
                  }}
                >
                  <FavoriteIcon
                    sx={{
                      color: image.isLiked === '1' ? '#FF4438' : '#fff',
                      fontSize: '18px',
                    }}
                  />
                  <LikeLabel>{image.likes}</LikeLabel>
                </LikeBox>
              </ImageItem>
            ))}
          </ImageBox>
        ) : (
          <EmptyBox>
            <EmptyMessage>
              {
                'No member of Picwiz iDAO has used this style you will be the first to try it out'
              }
            </EmptyMessage>
          </EmptyBox>
        )}

        <MainButton text="Use this style" onClick={handleUseBtn} />
      </ContentBox>
    </Dialog>
  );
};

export default StyleCard;
