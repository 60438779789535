import Axios, { type AxiosResponse } from 'axios';
import user from 'stores/user';

declare module 'axios' {
  interface AxiosResponse {
    msg: string;
    code: number;
  }
}

const api = Axios.create({
  timeout: 100000,
  baseURL: process.env.REACT_APP_SERVER_URI || '',
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  async function (config) {
    const accessToken = user.getToken();
    if (accessToken) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    console.log('----1111', config);

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

const errorCode: { [key: string]: string } = {
  404: '404: not found',
};

const initError = (status: number) => {
  const code = String(status);
  if (errorCode[code]) {
  } else {
  }
};

const initResponse = (response: AxiosResponse) => {
  const status = response?.data?.statusCode || response?.status || 404;
  if (status && status >= 200 && status < 300) {
    return response.data;
  } else {
    initError(status);
    return response;
  }
};

api.interceptors.response.use(
  function (response) {
    return initResponse(response);
  },
  function (error) {
    if (error && error.response) {
      if (error.response?.status === 401) {
        user.logout;
      }
    }
    return initResponse(error.response);
  },
);

export default api;
