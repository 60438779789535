import React, { useState } from 'react';
import { Drawer, Typography, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FileVo } from 'gql/server/__generated__/request';
import MainButton from 'components/MainButton';
import { AddPhotoSvg } from 'assets/img';

const StyleAvatar = styled('img')`
  width: 96px;
  height: 96px;
  object-fit: cover;
`;

const TitleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px 10px 24px;
`;

const PhotoBox = styled(Box)`
  display: flex;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  padding-left: 20px;
  padding-right: 8px;
  margin: 16px 0;
  gap: 12px;
`;

const StyleItem = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
  transition: background 0.3s, border 0.3s;
  min-width: 96px;
  min-height: 96px;
  box-shadow: -6px 2px 6px 0px #00000080;
`;

const ButtonBox = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0 20px;
`;

const ButtonText = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #111;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const drawerPaperStyle = {
  borderRadius: '24px 24px 0px 0px',
  background: 'linear-gradient(180deg, rgba(23, 25, 27, 0.01) 0%, #17191B 20%)',
  boxShadow: '0px -8px 40px 0px rgba(0, 0, 0, 0.20)',
  backdropFilter: 'blur(6px)',
  paddingBottom: '32px',
};

const AddButtonBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 102px;
  height: 96px;
  padding: 8px;
  gap: 12px;
  border-radius: 16px;
  background: radial-gradient(
    71.06% 81.05% at 79.14% 43.6%,
    rgba(112, 234, 161, 0.12) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
  border: 1px solid;
  border-image-source: linear-gradient(
    189.14deg,
    rgba(255, 255, 255, 0.12) 6.93%,
    rgba(255, 255, 255, 0.04) 93.07%
  );
  box-shadow: 0px 4px 48px 0px #00000033;
  backdrop-filter: blur(12px);
`;

const AddPhoto = styled(AddPhotoSvg)`
  width: 32px;
  height: auto;
`;

const AddPhotoLabel = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

interface UserPhotosProps {
  open: boolean;
  photoList: FileVo[];
  selectedFile: FileVo | null;
  onClose: () => void;
  handleGenerateBtnClick: () => void;
  handlePhotoItem: (file: FileVo) => void;
  handleAddButtonClick: () => void;
}

const UserPhotos: React.FC<UserPhotosProps> = ({
  open,
  photoList,
  selectedFile,
  onClose,
  handleGenerateBtnClick,
  handlePhotoItem,
  handleAddButtonClick,
}) => {
  const history = useHistory();

  const [showContent, setShowContent] = useState(false);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOptionClick = (option: FileVo) => {
    handlePhotoItem(option);
  };

  React.useEffect(() => {
    if (!selectedFile) {
      setShowContent(false);
    }

    if (selectedFile) {
      setShowContent(true);
    }
  }, [selectedFile]);

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: drawerPaperStyle,
      }}
      sx={{
        '& .MuiBackdrop-root': {
          background: 'transparent',
        },
      }}
    >
      <TitleBox>
        <Typography
          sx={{
            color: '#fff',
            fontSize: '20px',
            fontFamily: '"Bai Jamjuree", sans-serif',
          }}
        >
          {'Select your image to base on'}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ color: '#fff' }} />
        </IconButton>
      </TitleBox>

      <PhotoBox>
        {photoList.map((option) =>
          option.fileUrl === 'AddButton' ? (
            <AddButtonBox key={option.fileId} onClick={handleAddButtonClick}>
              <AddPhoto />
              <AddPhotoLabel>Upload new </AddPhotoLabel>
            </AddButtonBox>
          ) : (
            <StyleItem
              key={option.fileId}
              onClick={() => handleOptionClick(option)}
              sx={{
                border:
                  selectedFile?.fileId === option.fileId
                    ? '2px solid #B2FF51'
                    : '2px solid #2A2C2E',
              }}
            >
              <StyleAvatar src={option.fileUrl} />
            </StyleItem>
          ),
        )}
      </PhotoBox>

      <ButtonBox>
        <MainButton
          disabled={!showContent}
          onClick={() => {
            if (selectedFile) {
              handleGenerateBtnClick();
            }
          }}
        >
          <ButtonText>
            <Typography>Generate</Typography>
          </ButtonText>
        </MainButton>
      </ButtonBox>
    </Drawer>
  );
};

export default UserPhotos;
