import Back from 'components/Back';
import Button from 'components/Button';
import Cell from 'components/Loading/cell';
import { RowCenter } from 'components/Row';
import SliderImage from 'components/SliderImage';
import { Character, CharacterTrainingStatus } from 'gql/query';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import user from 'stores/user';
import styled from 'styled-components';
import { TYPE } from 'theme';

const Wrapper = styled.div`
  gap: 4px;
  padding: 8px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SliderWrapper = styled.div`
  width: 100%;
  height: 60%;
  margin-top: 50px;
  position: relative;
`;

const ActionButton = styled(Button)`
  width: 100%;
  margin-top: 50px;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
`;

const StyleLoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
`;

const BeforeCompleteStatus = [
  CharacterTrainingStatus.PENDING,
  CharacterTrainingStatus.IN_QUEUE,
  CharacterTrainingStatus.IN_PROGRESS,
];

const TextMap = {
  [CharacterTrainingStatus.IN_PROGRESS]: '分身训练中',
  [CharacterTrainingStatus.IN_QUEUE]: '分身队列中',
  [CharacterTrainingStatus.PENDING]: '分身待训练',
  [CharacterTrainingStatus.COMPLETE]: '训练完成',
  [CharacterTrainingStatus.FAILED]: '训练失败',
};

function Lora() {
  const { id: characterId } = useParams<{ id: string }>();
  const { serverUserInfo, getServerUserInfo } = user;
  const [loading, setLoading] = useState<boolean>(false);

  const currentCharacter = useMemo(() => {
    return serverUserInfo?.characters.find((item) => item.id === characterId);
  }, [serverUserInfo, characterId]);

  const isDefaultCharacter = useMemo(() => {
    return !!(
      characterId && characterId === serverUserInfo?.defaultCharacter?.id
    );
  }, [characterId, serverUserInfo?.defaultCharacter]);

  const handleSetCurrentRole = () => {
    setLoading(true);
    if (
      user.user &&
      currentCharacter &&
      currentCharacter.status === CharacterTrainingStatus.COMPLETE &&
      currentCharacter.id !== user.serverUserInfo?.defaultCharacter?.id
    ) {
      const confirmRes = confirm('确认选择此分身作为当前分身吗？');

      //   if (confirmRes) {
      //     user
      //       .setCurrentCharacter(currentCharacter)
      //       .then(() => getServerUserInfo())
      //       .then(() => {
      //         setLoading(false);
      //         history.go(-1);
      //       })
      //       .catch((err) => {
      //         setLoading(false);
      //         console.log(err);
      //         alert('设置分身失败！');
      //       });
      //   }
    }
  };

  useEffect(() => {
    if (currentCharacter) {
      const operaterCharWithStatus = async (character: Character) => {
        switch (character.status) {
          case CharacterTrainingStatus.PENDING:
            if (character.files.length > 0) {
              await user.trainingCharacter(character.id);
            } else {
              alert('分身没有对应的训练图片!');
              history.go(-1);
            }
            break;

          default:
            break;
        }
      };

      const updateCharacter = async () => {
        const res = await user.getServerCharacterInfo(currentCharacter.id);
        // if (res) {
        //   await operaterCharWithStatus(res);
        // }
      };

      let interval: any;

      if (
        [
          CharacterTrainingStatus.IN_PROGRESS,
          CharacterTrainingStatus.PENDING,
        ].indexOf(currentCharacter.status) >= 0
      ) {
        updateCharacter();
        interval = setInterval(updateCharacter, 15000);
      }

      return () => clearInterval(interval);
    }
  }, [currentCharacter, user]);

  const beforeComplete = currentCharacter
    ? BeforeCompleteStatus.indexOf(currentCharacter?.status) >= 0
    : false;

  return (
    <Wrapper>
      <Back />
      <RowCenter style={{ marginBottom: 6 }}>
        <TYPE.main fontSize={16} fontWeight={600}>
          数字分身{currentCharacter?.id}
        </TYPE.main>
      </RowCenter>
      <SliderWrapper>
        {beforeComplete && (
          <LoadingWrapper>
            <StyleLoadingWrapper>
              <Cell />
            </StyleLoadingWrapper>
          </LoadingWrapper>
        )}
        <SliderImage images={currentCharacter?.files || []} />
      </SliderWrapper>
      {currentCharacter?.status === CharacterTrainingStatus.COMPLETE && (
        <ActionButton
          size="medium"
          disabled={loading || isDefaultCharacter}
          onClick={() => {
            handleSetCurrentRole();
          }}
        >
          {isDefaultCharacter ? '分身使用中' : '使用分身'}
        </ActionButton>
      )}
      {/* {beforeComplete && currentCharacter && (
        <ActionButton size="medium" disabled>
          {TextMap[currentCharacter.status]}
        </ActionButton>
      )} */}
      {currentCharacter?.status === CharacterTrainingStatus.FAILED && (
        <ActionButton size="medium" disabled>
          训练失败
        </ActionButton>
      )}
    </Wrapper>
  );
}

export default observer(Lora);
