import React from 'react';
import Button from '@mui/material/Button';
import styles from './MainButton.module.css';

interface MainButtonProps {
  text?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

const MainButton: React.FC<MainButtonProps> = ({
  text,
  width = '100%',
  height = '52px',
  borderRadius = '16px',
  children,
  disabled = false,
  onClick,
}) => {
  return (
    <Button
      className={styles.button}
      style={{ width, height, borderRadius }}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
    >
      {children || text}
    </Button>
  );
};

export default MainButton;
