import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import './HoneycombLoader.css';

interface HoneycombLoaderProps {
  duration?: number;
  onStop?: () => void;
  loop?: boolean;
}

const HoneycombLoader: React.FC<HoneycombLoaderProps> = ({
  duration = 2.1,
  onStop,
  loop = true,
}) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const honeycombRef = useRef<HTMLDivElement>(null);
  const stopTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (honeycombRef.current) {
      const honeycombs = honeycombRef.current.querySelectorAll('div');
      honeycombs.forEach((div) => {
        (div as HTMLDivElement).style.animationDuration = `${duration}s`;
        (div as HTMLDivElement).style.webkitAnimationDuration = `${duration}s`;
      });
    }
  }, [duration]);

  useEffect(() => {
    if (!loop) {
      const stopAnimation = () => {
        setIsPlaying(false);
        if (onStop) {
          onStop();
        }
      };

      stopTimeoutRef.current = setTimeout(() => {
        stopAnimation();
      }, 10000);

      return () => {
        if (stopTimeoutRef.current) {
          clearTimeout(stopTimeoutRef.current);
        }
      };
    }
  }, [onStop, loop]);

  return (
    <Box
      sx={{
        display: 'flex',
        textAlign: 'center',
        width: '56px',
        height: '80px',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'scale(0.75)',
        transformOrigin: 'center',
      }}
    >
      <Box
        className={`honeycomb ${!isPlaying ? 'stopped' : ''}`}
        ref={honeycombRef}
        sx={{
          display: 'inline-block',
          position: 'relative',
          width: '56px',
          height: '56px',
        }}
      >
        {[...Array(7)].map((_, index) => (
          <div key={index}></div>
        ))}
      </Box>
    </Box>
  );
};

export default HoneycombLoader;
