import { AutoColumn } from 'components/Column';
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Row, { RowBetween } from 'components/Row';
import { NavLink, useHistory } from 'react-router-dom';
import { useGetUserInfo } from 'hooks/useUserInfo';
import {
  UserInfo,
  Character,
  CharacterTrainingStatus,
  TaskStatus,
  Task,
} from 'gql/query';
import { formatDateTime, getFacechainImageSrc } from 'utils/index';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Fab from '@mui/material/Fab';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import BottomNav from 'components/BottomNav';
import { observer } from 'mobx-react';
import user from 'stores/user';
import Header from 'components/Header';
import { SuccessFlagSvg } from 'assets/img';
import Button from 'components/Button';

const FabBigButton = styled(Fab)`
  width: 100%;
  padding: 0 16px;
  border-radius: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 0 10px 72px 10px;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
  }
`;

const MyRolesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const MyRole = styled(Card)`
  display: flex;
  flex-direction: column;
  width: calc(50% - 5px);
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  img {
    width: 100%;
  }
`;

const RoleCoverWrapper = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const RoleStatus = styled(Chip)`
  position: absolute;
  top: 8px;
  left: 8px;
  color: #000;
`;

const RoleStatusWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  color: #000;
`;

const MyRoleTextWrapper = styled(AutoColumn)`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  gap: 10px;
  height: 80px;
`;

const HistoryWrapper = styled(AutoColumn)`
  gap: 20px;

  .imgWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    overflow: hidden;
    min-height: 70px;

    img {
      width: 70px;
      height: auto;
      border-radius: 8px;
      overflow: hidden;
    }
  }
`;

const SwitchWrapper = styled.div`
  width: 255px;

  height: 56px;

  background: #f3f5f6;

  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 6px;
  margin: 0 auto;
`;

const SwiutchItem = styled.div`
  text-decoration: none;
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  cursor: pointer;
  height: 100%;
  border-radius: 25px;

  &.active {
    font-weight: bold;
    background-color: #fff;
    color: ${({ theme }) => theme.c.t03};
  }
`;

const FakeImage = styled.div`
  width: 70px;
  height: 70px;
  background: #dddddd;
  border-radius: 8px;
`;

function StatusTag(props: { status: CharacterTrainingStatus }) {
  const TextMap = {
    [CharacterTrainingStatus.IN_PROGRESS]: '训练中',
    [CharacterTrainingStatus.IN_QUEUE]: '队列中',
    [CharacterTrainingStatus.PENDING]: '待训练',
    [CharacterTrainingStatus.COMPLETE]: '训练完成',
    [CharacterTrainingStatus.FAILED]: '训练失败',
  };

  const StatusColor = {
    [CharacterTrainingStatus.IN_PROGRESS]: 'blue',
    [CharacterTrainingStatus.IN_QUEUE]: 'blue',
    [CharacterTrainingStatus.PENDING]: 'purple',
    [CharacterTrainingStatus.COMPLETE]: 'green',
    [CharacterTrainingStatus.FAILED]: 'yellow',
  };

  const StatusTextColor = {
    [CharacterTrainingStatus.IN_PROGRESS]: '#fff',
    [CharacterTrainingStatus.IN_QUEUE]: '#fff',
    [CharacterTrainingStatus.PENDING]: '#000',
    [CharacterTrainingStatus.COMPLETE]: '#000',
    [CharacterTrainingStatus.FAILED]: '#000',
  };

  if (props.status === CharacterTrainingStatus.COMPLETE) {
    return (
      <RoleStatusWrapper>
        <SuccessFlagSvg />
      </RoleStatusWrapper>
    );
  }

  return (
    <RoleStatus
      style={{
        backgroundColor: StatusColor[props.status],
        color: StatusTextColor[props.status],
      }}
      label={TextMap[props.status]}
    />
  );
}

function My() {
  const [tab, setTab] = React.useState(0);
  const {
    serverUserInfo,
    // getServerCharacterInfo,
    getServerUserInfo,
  } = user;
  const history = useHistory();

  useEffect(() => {
    getServerUserInfo();
  }, []);

  const currentCharacter = useMemo(() => {
    return serverUserInfo?.defaultCharacter;
  }, [serverUserInfo]);

  // useEffect(() => {
  //   function updateUnCompleteCharacter() {
  //     const beforeCompleteItem = serverUserInfo?.characters.find(
  //       (item) =>
  //         [
  //           CharacterTrainingStatus.PENDING,
  //           CharacterTrainingStatus.IN_QUEUE,
  //           CharacterTrainingStatus.IN_PROGRESS,
  //         ].indexOf(item.status) >= 0,
  //     );

  //     if (beforeCompleteItem) {
  //       getServerCharacterInfo(beforeCompleteItem.id);
  //     }
  //   }

  //   updateUnCompleteCharacter();

  //   const interval = setInterval(() => {
  //     updateUnCompleteCharacter();
  //   }, 10000);

  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, [serverUserInfo, getServerCharacterInfo]);

  return (
    <Wrapper className="page">
      <Header />
      <Title>
        {currentCharacter ? (
          <Avatar
            sx={{ height: 60, width: 60 }}
            src={currentCharacter.files[0]}
          />
        ) : (
          <Avatar
            sx={{ height: 60, width: 60 }}
            src={require('assets/img/ghost.png')}
          />
        )}
        <AutoColumn gap="6px">
          <TYPE.main fontSize={16} fontWeight={600}>
            我的数字分身
          </TYPE.main>
          {currentCharacter ? (
            <>
              <TYPE.desc fontSize={14}>ID: {currentCharacter.id}</TYPE.desc>
              <TYPE.desc fontSize={14}>
                完成时间：
                {currentCharacter.completedTime
                  ? formatDateTime(currentCharacter.completedTime)
                  : ''}
              </TYPE.desc>
            </>
          ) : (
            <>
              {!!serverUserInfo?.characters.length ? (
                <TYPE.desc fontSize={14}>
                  请在下方训练完成的分身中设置自己的分身
                </TYPE.desc>
              ) : (
                <Button onClick={() => history.push('/createrole')}>
                  制作自己的分身
                </Button>
              )}
            </>
          )}
        </AutoColumn>
      </Title>
      <AutoColumn gap="20px">
        <SwitchWrapper>
          <SwiutchItem
            className={tab === 0 ? 'active' : ''}
            onClick={() => {
              setTab(0);
            }}
          >
            我的分身
          </SwiutchItem>
          <SwiutchItem
            className={tab === 1 ? 'active' : ''}
            onClick={() => {
              setTab(1);
            }}
          >
            制作历史
          </SwiutchItem>
        </SwitchWrapper>
        {tab == 0 && (
          <MyRolesWrapper>
            {serverUserInfo?.characters.map((charac) => {
              return (
                <MyRole
                  key={charac.id}
                  onClick={() => {
                    history.push(`/my/lora/${charac.id}`);
                    // if (
                    //   [
                    //     CharacterTrainingStatus.COMPLETE,
                    //     CharacterTrainingStatus.FAILED,
                    //   ].indexOf(charac.status) === -1
                    // ) {
                    //   handleContinueTrain(charac);
                    // }
                    // if (CharacterTrainingStatus.COMPLETE === charac.status) {
                    //   handleSetCurrentRole(charac);
                    // }
                  }}
                >
                  {/* <img src={charac.files[0]} alt="" /> */}
                  <RoleCoverWrapper
                    style={{
                      backgroundImage: `url(${charac.files[0]})`,
                    }}
                  ></RoleCoverWrapper>
                  <StatusTag status={charac.status} />
                  <MyRoleTextWrapper>
                    <TYPE.desc fontSize={14} color="t03">
                      ID: {charac.id}
                    </TYPE.desc>
                    <TYPE.desc fontSize={12}>
                      {charac.status ===
                        CharacterTrainingStatus.IN_PROGRESS && (
                        <>创建时间：{formatDateTime(charac.createTime)}</>
                      )}

                      {[
                        CharacterTrainingStatus.COMPLETE,
                        CharacterTrainingStatus.FAILED,
                      ].indexOf(charac.status) > -1 && (
                        <>
                          完成时间：
                          {charac.completedTime &&
                            formatDateTime(charac.completedTime)}
                        </>
                      )}
                    </TYPE.desc>
                  </MyRoleTextWrapper>
                </MyRole>
              );
            })}

            {/* <FabBigButton
              color="primary"
              disabled={!currentUser}
              onClick={() => {
                history.push('/createrole');
              }}
            >
              制作新的分身
              <CameraIcon sx={{ ml: 1 }} />
            </FabBigButton> */}
          </MyRolesWrapper>
        )}
        {tab === 1 && (
          <AutoColumn gap="20px">
            {serverUserInfo?.tasks.map((task) => {
              return (
                <HistoryWrapper key={task.id}>
                  <Row style={{ flexWrap: 'wrap' }}>
                    <TYPE.main marginRight={4} color={'t03'}>
                      {task.style.title}
                    </TYPE.main>
                    {task.completedTime && (
                      <TYPE.desc fontSize={12} marginRight={4}>
                        {formatDateTime(task.completedTime)}
                      </TYPE.desc>
                    )}
                    {task.status === TaskStatus.IN_QUEUE && (
                      <Chip
                        style={{
                          background: '#45B26B',
                          fontWeight: 'bold',
                          color: '#fff',
                        }}
                        label="排队中..."
                      ></Chip>
                    )}
                    {task.status === TaskStatus.IN_PROGRESS && (
                      <Chip
                        style={{
                          background: '#45B26B',
                          fontWeight: 'bold',
                          color: '#fff',
                        }}
                        label="进行中..."
                      ></Chip>
                    )}
                    {task.status === TaskStatus.FAILED && (
                      <TYPE.desc marginLeft={10} color="red" flexShrink={1}>
                        出图失败
                      </TYPE.desc>
                    )}
                    {task.status === TaskStatus.COMPLETED && (
                      <NavLink to={`/my/tasks/${task.id}`}>
                        <TYPE.main>查看</TYPE.main>
                      </NavLink>
                    )}
                  </Row>
                  <div className="imgWrapper">
                    {task.status === TaskStatus.COMPLETED &&
                      task.images.slice(0, 4).map((image) => {
                        return <img key={image} src={image} alt="" />;
                      })}

                    {task.status !== TaskStatus.COMPLETED &&
                      [1, 2, 3, 4].map((index) => <FakeImage key={index} />)}
                    {/* <img src={require('assets/img/ghost.png')} alt="" />
                      <img src={require('assets/img/ghost.png')} alt="" />
                      <img src={require('assets/img/ghost.png')} alt="" />
                      <img src={require('assets/img/ghost.png')} alt="" /> */}
                  </div>
                </HistoryWrapper>
              );
            })}
          </AutoColumn>
        )}
      </AutoColumn>
      <BottomNav />
    </Wrapper>
  );
}

export default observer(My);
