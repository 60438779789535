import React, { useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import './Carousel.css';
import styled from 'styled-components';
import CustomLinearProgress from 'components/CustomLinearProgress';
import HoneycombLoader from 'components/HoneycombLoader';
import { motion } from 'framer-motion';
import user from 'stores/user';

const CustomFont = styled(Typography)`
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-family: 'Bai Jamjuree', sans-serif;
  font-weight: 510;
  line-height: 100%;
  /* background: var(
    linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0.6) 46%, #fff 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #fff;
  margin-top: 30px;
`;

const SmallFont = styled(Typography)`
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const ContainerBox = styled(Box)`
  overflow: hidden;
  position: relative;
  padding: 8px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 240px;
  margin-bottom: 40px;
`;

const Mask = styled(Box)`
  display: flex;
  position: absolute;
  width: 100%;
  height: 56px;
  background: linear-gradient(180deg, #171b21 0%, rgba(25, 29, 34, 0) 100%);
  top: 0;
  left: 0;
  z-index: 1000;
`;

interface CarouselProps {
  onEndBootAction?: () => void;
}

const Carousel: React.FC<CarouselProps> = ({ onEndBootAction }) => {
  // const soundRef = useRef<HTMLAudioElement | null>(null);

  const [changeLoading, setChangeLoading] = useState(false);

  const variants = {
    hidden: {
      opacity: 0,
      y: 80,
      height: 0,
      scale: 0.8,
      filter: 'blur(2px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      height: 'auto',
      scale: 1,
      filter: 'none',
      transition: {
        delay: i === 6 ? (i + 1) * 2.5 : i * 2.5,
        duration: 0.8,
        ease: 'easeIn',
      },
    }),
  };

  const fadeOutVariants = {
    initial: { opacity: 1 },
    fade: {
      opacity: 0,
      filter: 'blur(2px)',
      transition: {
        delay: 16,
        duration: 1.5,
        ease: 'easeOut',
      },
    },
  };

  const containerFadeOutVariants = {
    initial: { opacity: 1 },
    fade: {
      opacity: 0,
      filter: 'blur(2px)',
      transition: {
        delay: 27,
        duration: 1,
        ease: 'easeOut',
      },
    },
  };

  const fadeInVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1.5,
        ease: 'easeOut',
      },
    },
  };

  const onLoadingComplete = () => {};

  const onHoneycombLoaderStop = () => {};

  const handleAnimationStart = () => {
    // if (soundRef.current) {
    //   soundRef.current.play().catch((error) => {
    //     console.log('Error playing sound:', error);
    //   });
    // }
  };

  return (
    <ContainerBox>
      {/* <audio
        ref={soundRef}
        src="../../assets/audio/textShow.mp3"
        preload="auto"
      /> */}
      <Mask />
      <motion.div
        variants={containerFadeOutVariants}
        initial="initial"
        animate="fade"
        custom={8}
        style={{ width: '100%' }}
        onAnimationComplete={onEndBootAction}
      >
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={0}
          style={{ width: '100%', zIndex: 1 }}
          onAnimationStart={handleAnimationStart}
        >
          <CustomFont>{`Hi ${user.user?.username},`}</CustomFont>
        </motion.div>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={1}
          style={{ width: '100%', zIndex: 1 }}
          onAnimationStart={handleAnimationStart}
        >
          <CustomFont>{'I’m PicWiz Agent'}</CustomFont>
        </motion.div>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={2}
          style={{ width: '100%', zIndex: 1, marginTop: '10px' }}
          onAnimationStart={handleAnimationStart}
        >
          <SmallFont>{'powered by the LazAI'}</SmallFont>
        </motion.div>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={3}
          style={{ width: '100%', zIndex: 1, marginTop: '10px' }}
          onAnimationStart={handleAnimationStart}
        >
          <SmallFont>
            {
              'I’m Representing all PicWiz iDAO contributors on the LazAI blockchain'
            }
          </SmallFont>
        </motion.div>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={6}
          style={{ width: '100%', zIndex: 1 }}
          onAnimationStart={handleAnimationStart}
        >
          <SmallFont sx={{ marginBottom: '20px' }}>
            Welcome!
            <br />
            {"You're now part of the PicWiz iDAO on LazAI blockchain"}
            <br />
          </SmallFont>
        </motion.div>

        {changeLoading ? (
          <motion.div
            variants={fadeInVariants}
            initial="hidden"
            animate="visible"
            custom={7}
            style={{
              width: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <HoneycombLoader onStop={onHoneycombLoaderStop} loop={false} />
          </motion.div>
        ) : (
          <motion.div
            variants={fadeOutVariants}
            initial="initial"
            animate="fade"
            custom={5}
            style={{ width: '100%' }}
            onAnimationComplete={() => {
              setChangeLoading(true);
            }}
          >
            <motion.div
              variants={variants}
              initial="hidden"
              animate="visible"
              custom={4}
              style={{ width: '100%', marginTop: '20px' }}
            >
              <CustomLinearProgress onLoadingComplete={onLoadingComplete} />
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    </ContainerBox>
  );
};

export default Carousel;
