/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CircularProgress } from '@mui/material';
import MButton, { ButtonProps } from '@mui/material/Button';
import styled from 'styled-components';
import { darken } from 'polished';

export interface Props extends ButtonProps {
  children?: React.ReactNode;
  className?: string;
  loading?: boolean;
  onClick?:
    | ((event?: React.SyntheticEvent) => void)
    | ((event?: React.SyntheticEvent) => Promise<any>);
}

const StyledButton = styled(MButton)`
  .loading {
    color: ${({ theme }) => theme.c.t01};
  }
  text-transform: none !important;
  :disabled {
    cursor: not-allowed;
  }
  &.MuiButton-outlined {
    border-radius: 0px !important;
  }
  &.MuiButton-contained {
    background-color: ${({ theme }) => '#0064C1'};

    :hover,
    :focus {
      background-color: ${({ theme }) => darken(0.1, '#0064C1')};
    }
    .loading {
      color: ${({ theme }) => theme.c.t01};
    }

    :disabled {
      color: #fff;

      background: rgba(55, 114, 255, 0.5);
      cursor: not-allowed;
    }
  }

  &.MuiButton-outlined {
    border-color: ${({ theme }) => theme.c.t02};
    color: ${({ theme }) => theme.c.t01};
  }

  &.MuiButton-sizeMedium {
    height: 54px;
    border-radius: 30px;
  }

  &.MuiButton-sizeSmall {
    height: 36px;
    border-radius: 20px;
    padding: 8px 16px;
  }
`;
const StyledLoading = styled(CircularProgress)``;

export default function Button(props: Props) {
  const {
    onClick,
    children,
    disabled,
    variant = 'contained',
    className,
    size = 'small',
    loading: pLoading = false,
    ...rest
  } = props;
  const [loading, setLoading] = useState(pLoading);
  const loadingRef = useRef(loading);
  const mountedRef = useRef(true);

  loadingRef.current = loading;

  const handleLoading = useCallback(
    (value: boolean) => {
      if (mountedRef.current) {
        setLoading(value);
      }
    },
    [setLoading],
  );

  const handleClick = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (loadingRef.current || disabled || !onClick) {
        return;
      }

      const promise = onClick(event);
      if (
        promise != null &&
        typeof (promise as Promise<any>).then === 'function'
      ) {
        handleLoading(true);
        (promise as Promise<any>)
          .then((result: any) => {
            handleLoading(false);
            return result;
          })
          .catch((e) => {
            console.log('promise reject', e);
            handleLoading(false);
          });
      }
    },
    [loadingRef, mountedRef, disabled, handleLoading, onClick],
  );

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <StyledButton
      {...rest}
      onClick={handleClick}
      variant={variant}
      className={className}
      disabled={disabled}
      size={size}
    >
      {!loading && children}
      {loading && <StyledLoading size={24} className="loading" />}
    </StyledButton>
  );
}

export function LoadingButton({
  loading,
  variant = 'contained',
  ...rest
}: Props) {
  if (!loading) return null;
  return (
    <StyledButton variant={variant} {...rest}>
      {loading && <StyledLoading size={24} className="loading" />}
    </StyledButton>
  );
}
