import * as React from 'react';
import { Box, Stepper, Step, StepLabel, Typography } from '@mui/material';
import styled from 'styled-components';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { CheckSvg, UncheckedSvg } from 'assets/img';
import { TaskRewardsVo } from 'gql/server/__generated__/request';

const LinearGradientConnector = styled(StepConnector)`
  &.${stepConnectorClasses.alternativeLabel} {
    top: 14px;
    left: -50%;
    right: 50%;
  }
  &.${stepConnectorClasses.active} .${stepConnectorClasses.line} {
    background-image: var(
      --Primary-btn-circle,
      radial-gradient(
        46.17% 37.09% at 27.08% 26.04%,
        #eefcdc 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        83.47% 83.47% at 21.88% 27.08%,
        #7aee51 16.69%,
        #73eb9e 100%
      )
    );
  }
  &.${stepConnectorClasses.completed} .${stepConnectorClasses.line} {
    background-image: var(
      --Primary-btn-circle,
      radial-gradient(
        46.17% 37.09% at 27.08% 26.04%,
        #eefcdc 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        83.47% 83.47% at 21.88% 27.08%,
        #7aee51 16.69%,
        #73eb9e 100%
      )
    );
  }
  & .${stepConnectorClasses.line} {
    height: 8px;
    border: 0;
    background-color: #0d0c19;
    border-radius: 4px;
  }
`;

const CheckIcon = styled(CheckSvg)`
  width: 16px;
  height: auto;
`;

const UncheckedIcon = styled(UncheckedSvg)`
  width: 10px;
  height: auto;
`;

const LinearGradientStepIconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;

const IntegrationLabel = styled(Typography)`
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -1px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const LikesLabel = styled(Typography)`
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.442px;
`;

const LinearGradientStepIconRoot = styled(Box)<{
  ownerState: { completed?: boolean; active?: boolean };
}>`
  background-color: #0d0c19;
  z-index: 1;
  color: ${({ ownerState }) =>
    ownerState.completed || ownerState.active ? '#4c4784' : '#ccc'};
  width: ${({ ownerState }) =>
    ownerState.completed || ownerState.active ? '32px' : '24px'};
  height: ${({ ownerState }) =>
    ownerState.completed || ownerState.active ? '32px' : '24px'};
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  ${({ ownerState }) =>
    ownerState.active &&
    `
    background-image: var(--Primary-btn-circle, radial-gradient(46.17% 37.09% at 27.08% 26.04%, #EEFCDC 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(83.47% 83.47% at 21.88% 27.08%, #7AEE51 16.69%, #73EB9E 100%));
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.40);
  `}

  ${({ ownerState }) =>
    ownerState.completed &&
    `
    background-image: var(--Primary-btn-circle, radial-gradient(46.17% 37.09% at 27.08% 26.04%, #EEFCDC 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(83.47% 83.47% at 21.88% 27.08%, #7AEE51 16.69%, #73EB9E 100%));
  `}
`;

function LinearGradientStepIcon(props: any) {
  const { active, completed } = props;

  return (
    <LinearGradientStepIconBox>
      <LinearGradientStepIconRoot ownerState={{ completed, active }}>
        {active || completed ? <CheckIcon /> : <UncheckedIcon />}
      </LinearGradientStepIconRoot>
    </LinearGradientStepIconBox>
  );
}

function CustomStepLabel(props: {
  line1: string;
  line2: string;
  active: boolean;
}) {
  return (
    <div>
      <IntegrationLabel
        sx={{
          background: props.active
            ? 'var(--Primary-btn-circle, radial-gradient(83.47% 83.47% at 21.88% 27.08%, #7AEE51 16.69%, #73EB9E 100%))'
            : 'rgba(255, 255, 255, 0.50)',
        }}
      >
        {props.line1}
      </IntegrationLabel>
      <LikesLabel color={props.active ? 'white' : 'rgba(255, 255, 255, 0.50)'}>
        {props.line2}
      </LikesLabel>
    </div>
  );
}

interface StepData {
  line1: string;
  line2: string;
}

interface CustomizedSteppersProps {
  steps: TaskRewardsVo[];
  contentStr: string;
}

export default function CustomizedSteppers({
  steps,
  contentStr,
}: CustomizedSteppersProps) {
  function findLastFinishedIndex(steps: TaskRewardsVo[]) {
    for (let i = steps.length - 1; i >= 0; i--) {
      if (steps[i].isFinish === 1) {
        return i;
      }
    }
    return -1;
  }
  const activeStep = findLastFinishedIndex(steps);

  return (
    <Box sx={{ width: '100%', marginTop: '20px' }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<LinearGradientConnector />}
      >
        {steps.map((step, index) => (
          <Step key={`${step.count}_${index}`} sx={{ minWidth: '80px' }}>
            <StepLabel StepIconComponent={LinearGradientStepIcon}>
              <CustomStepLabel
                line1={`+${step.reward === 0 ? '?' : step.reward}`}
                line2={`${step.count} ${
                  step.count === '1' ? contentStr : contentStr + 's'
                }`}
                active={index <= activeStep}
              />
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
