import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import Back from 'components/Back';
import BottomNav from 'components/BottomNav';
import { GlendaFunkSvg, LazAISvg, IntegrationIconSvg } from 'assets/img';
import ExploreCard from 'components/ExploreCard';
import { serverRequestApi } from 'gql';
import { RankVo } from 'gql/server/__generated__/request';
import user from 'stores/user';
import { useHistory } from 'react-router-dom';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import { useToast } from 'components/ToastContext';

const ContainerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  padding-top: calc(
    var(--tg-safe-area-inset-top) + var(--tg-content-safe-area-inset-top)
  );
  padding-bottom: env(safe-area-inset-bottom, 0px);
`;

const ContentBox = styled(Box)`
  flex: 1;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-grow: 1;
  width: 100%;
  z-index: 999;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  flex-grow: 1;
`;

const LogoBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 6px;
`;

const UserBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const GlendaFunk = styled(GlendaFunkSvg)`
  width: 28px;
  height: auto;
  margin-right: 8px;
`;

const UserAvatar = styled('img')`
  width: 28px;
  height: auto;
  margin-right: 8px;
  border-radius: 14px;
`;

const UserName = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
`;

const LazAI = styled(LazAISvg)`
  width: 120px;
  height: auto;
  margin-right: 10px;
`;

const IntegrationBox = styled(Box)`
  display: flex;
  padding: 20px var(--Space-0, 0px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  align-self: stretch;
  width: 100%;
`;

const IntegrationTitleBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  align-self: stretch;
`;

const IntegrationIcon = styled(IntegrationIconSvg)`
  width: 46px;
  height: auto;
  margin-top: 4px;
`;

const IntegrationTitle = styled(Typography)`
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const IntegrationLabel = styled(Box)`
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 1px;
  background: var(
    --Primary-btn-circle,
    radial-gradient(
      83.47% 83.47% at 21.88% 27.08%,
      #7aee51 16.69%,
      #73eb9e 100%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const IntegrationDesc = styled(Typography)`
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const TagBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

const IDaoBox = styled(Box)`
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 16px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: var(
    --Subtle-box-bg,
    radial-gradient(
      150.2% 50% at 50% 50%,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.1) 100%
    )
  );
  width: 35vw;
`;

const FullNFTBox = styled(Box)`
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 16px;
  border: 2px solid var(--Bubble-border, #638070);
  background: var(
    --Bubble-highlight,
    radial-gradient(179.22% 92.71% at 50% 7.29%, #274446 0%, #546962 100%)
  );
  box-shadow: -2px 4px 17.9px 0px rgba(81, 231, 150, 0.35) inset;
  width: 60vw;
`;

const TagTitle = styled(Typography)`
  color: #00ff87;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const TagDesc = styled(Typography)`
  color: #fff;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.442px;
`;

const ExploreTitle = styled(Typography)`
  color: #fff;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 100%;
  margin-top: 20px;
`;

const Explore: React.FC = () => {
  const [myRank, setMyRank] = useState<RankVo>({});

  const history = useHistory();
  const { startLoading, stopLoading } = useToast();

  useEffect(() => {
    async function fetchMyRank() {
      startLoading();
      try {
        const response = await serverRequestApi.getMyRank();

        setMyRank(response.myRank || {});

        stopLoading();
      } catch (error) {
        console.log('Error fetching top ranks:', error);

        stopLoading();
      }
    }

    fetchMyRank();
  }, []);

  const handleSelectionChange = (selected: string) => {
    console.log('选中的按钮:', selected);
  };

  // const buttonList = [
  //   'Recommend',
  //   'Learn',
  //   'Draw',
  //   'Sitsed',
  //   'Learn1',
  //   'Draw1',
  //   'Sitsed1',
  // ];

  const cardData = [
    {
      title: 'iDAO PicWiz',
      creator: 'admin',
      description:
        'Create various styles of photo avatars about you in AI digital world',
      status: 'join',
      achieved: 324,
    },
  ];

  const variants = {
    hidden: {
      opacity: 0,
      y: 80,
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.4,
        duration: 0.5,
      },
    }),
  };

  return (
    <ContainerBox>
      <Back />
      <ContentBox>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={0}
          style={{ width: '100%' }}
        >
          <LogoBox>
            <UserBox>
              {user?.user?.photoUrl && user?.user?.photoUrl.length > 0 ? (
                <UserAvatar src={user?.user?.photoUrl} />
              ) : (
                <GlendaFunk />
              )}
              <UserName>{user?.user?.username}</UserName>
            </UserBox>
            <LazAI />
          </LogoBox>
        </motion.div>
        <IntegrationBox>
          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            custom={0.5}
            style={{ width: '100%' }}
          >
            <IntegrationTitleBox>
              <IntegrationIcon />
              <IntegrationTitle>My LazAI Royalty Point</IntegrationTitle>
            </IntegrationTitleBox>
          </motion.div>
          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            custom={1}
            style={{ width: '100%' }}
          >
            <IntegrationLabel>
              <CountUp
                start={0}
                end={myRank.points ?? 0}
                duration={1.5}
                delay={1.5}
                separator=","
                style={{
                  fontFamily: '"Bai Jamjuree", sans-serif',
                  fontWeight: 400,
                }}
              />
            </IntegrationLabel>
          </motion.div>

          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            custom={1.5}
            style={{ width: '100%' }}
          >
            <IntegrationDesc>
              Collect Points & Earn
              <br />
              The more you participate, the more your earn
            </IntegrationDesc>
          </motion.div>
        </IntegrationBox>

        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={2}
          style={{ width: '100%' }}
        >
          <TagBox>
            <IDaoBox>
              <TagTitle>iDAO</TagTitle>
              <TagDesc>Powered by LazAI Blockchain & AI Protocol</TagDesc>
            </IDaoBox>
            <FullNFTBox>
              <TagTitle>Full NFT</TagTitle>
              <TagDesc>
                A New NFT Standard
                <br />
                Enhancing Your generated
                <br />
                Asset Value
              </TagDesc>
            </FullNFTBox>
          </TagBox>
        </motion.div>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={2.5}
          style={{ width: '100%' }}
        >
          <ExploreTitle>Explore iDAO</ExploreTitle>
        </motion.div>
        {/* <RadioButton
          buttons={buttonList}
          onSelectedChange={handleSelectionChange}
        /> */}
        {cardData.map((card, index) => (
          <motion.div
            key={index}
            variants={variants}
            initial="hidden"
            animate="visible"
            custom={index * 0.5 + 3}
            style={{ width: '100%' }}
          >
            <ExploreCard
              title={card.title}
              creator={card.creator}
              description={card.description}
              status={card.status}
              achieved={myRank.points ?? 0}
              onClick={() => {
                history.push('/chat');
              }}
            />
          </motion.div>
        ))}
      </ContentBox>
      <BottomNav />
    </ContainerBox>
  );
};

export default Explore;
