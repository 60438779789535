// ToastContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-toastify/dist/ReactToastify.css';

interface ToastContextType {
  startLoading: () => void;
  stopLoading: () => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

let setGlobalBackdrop: React.Dispatch<React.SetStateAction<boolean>> | null =
  null;

export const showErrorToast = (message: string) => {
  if (setGlobalBackdrop) {
    setGlobalBackdrop(true);
  }

  toast(message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    theme: 'dark',
    style: {
      fontSize: '16px',
      lineHeight: '20px',
      textAlign: 'center',
      fontFamily: "'Bai Jamjuree', sans-serif",
    },
    onClose: () => {
      if (setGlobalBackdrop) {
        setGlobalBackdrop(false);
      }
    },
  });
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast 必须在 ToastProvider 中使用');
  }
  return context;
};

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setGlobalBackdrop = setOpenBackdrop;
    return () => {
      setGlobalBackdrop = null;
    };
  }, []);

  const startLoading = () => {
    setOpenBackdrop(true);
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
    setOpenBackdrop(false);
  };

  return (
    <ToastContext.Provider value={{ startLoading, stopLoading }}>
      {children}
      <Backdrop open={openBackdrop} style={{ zIndex: 9998 }}>
        {loading && <CircularProgress sx={{ color: '#00ff87' }} />}
      </Backdrop>
      <ToastContainer
        limit={1}
        style={{
          width: 'fit-content',
          maxWidth: '75%',
          position: 'fixed',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -40%)',
          zIndex: 99999,
        }}
      />
    </ToastContext.Provider>
  );
};
