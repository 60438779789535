import { AutoColumn } from 'components/Column';
import Row, { RowBetween } from 'components/Row';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { TYPE } from 'theme';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Button from 'components/Button';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import DrawIcon from '@mui/icons-material/AutoFixHigh';
import { getFacechainImageSrc } from 'utils/index';
import BottomNav from 'components/BottomNav';
import userStore from 'stores/user';
import models from 'stores/models';
import { observer } from 'mobx-react';
import Header from 'components/Header';

const Wrapper = styled(AutoColumn)`
  padding: 10px;
  padding-bottom: 100px;
  box-sizing: border-box;
  width: 100%;
  height: unset;
  gap: 30px;
`;

const Banner = styled(Card)`
  width: 300px;
  min-height: 100px;
  margin: 0 auto;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const Item = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(50% - 10px);
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
`;
const ItemTextWrapper = styled(AutoColumn)`
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  gap: 10px;
`;

const FabBigButton = styled(Button)`
  width: 160px;
  padding: 0 16px;
  background: #fff !important;
  color: #3772ff !important;
  font-weight: 600;
  height: 40px;
  margin-top: 20px;
`;

const TopBanner = styled.div`
  background: url(${require('assets/img/home/banner.png')});
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 172px;
  border-radius: 20px;
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;

  &.created {
    background-image: none;
    background-color: #3772ff;
  }
`;

const CreateCharButton = styled(Button)`
  background-color: #fff !important;
  color: #3772ff !important;
  border-radius: 20px !important;
  width: 100% !important;
  font-weight: bold;
  background-image: url(${require('assets/img/home/camera.png')});
  background-position: left bottom;
  background-size: contain;
  background-repeat: no-repeat;
`;

function Home() {
  const { serverUserInfo, getServerUserInfo } = userStore;
  const history = useHistory();

  useEffect(() => {
    getServerUserInfo();
  }, []);

  useEffect(() => {
    models.getModals();
  }, [models]);

  return (
    <Wrapper className="page">
      <Header />
      <TopBanner className={!serverUserInfo?.defaultCharacter ? 'created' : ''}>
        {serverUserInfo?.defaultCharacter ? (
          <>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                marginBottom: '4px',
                alignItems: 'center',
              }}
            >
              <Avatar
                sx={{ width: 60, height: 60, marginRight: '4px' }}
                src={serverUserInfo?.defaultCharacter.files[0] || ''}
                alt="role"
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'center',
                  gap: '8px',
                }}
              >
                <TYPE.main fontSize={16} mobileFz={16} color={'#fff'}>
                  当前数字分身
                </TYPE.main>
                <TYPE.main fontSize={12} mobileFz={12} color={'#fff'}>
                  ID: {serverUserInfo?.defaultCharacter.id}
                </TYPE.main>
              </Box>
              <Box sx={{ height: '24px' }}>
                <Button
                  style={{ background: '#1053F4', boxShadow: 'none' }}
                  onClick={() => history.push('/createrole')}
                  size="small"
                >
                  制作新分身
                </Button>
              </Box>
            </Box>
            <Box alignItems="center">
              <CreateCharButton
                size="medium"
                onClick={() => history.push('/models')}
              >
                制作写真
              </CreateCharButton>
            </Box>
          </>
        ) : (
          <>
            <AutoColumn gap="4px">
              <TYPE.main fontSize={20} mobileFz={20} color="#fff">
                您还没有自己的数字分身
              </TYPE.main>
              <TYPE.desc
                fontSize={14}
                mobileFz={14}
                color={'rgba(255,255,255,0.6)'}
              >
                上传照片制作自己的数字分身
              </TYPE.desc>
            </AutoColumn>
            <FabBigButton
              color="primary"
              aria-label="new-role"
              onClick={() => history.push('/createrole')}
            >
              制作自己的分身
            </FabBigButton>
          </>
        )}
      </TopBanner>
      <RowBetween>
        <Typography variant="h5" fontSize={16}>
          热门模型
        </Typography>
        {/* <TYPE.main
          fontSize={14}
          onClick={() => {
            history.push('/models');
          }}
        >
          查看更多
        </TYPE.main> */}

        <Button
          variant="text"
          onClick={() => {
            history.push('/models');
          }}
        >
          查看更多
        </Button>
      </RowBetween>
      <ItemsWrapper>
        {models.models.map((model) => {
          return (
            <Item
              key={model.id}
              onClick={() => history.push(`/selectstyle?model_id=${model.id}`)}
            >
              <img src={getFacechainImageSrc(model.styles[0].image)} />
              <ItemTextWrapper>
                <TYPE.main fontSize={14} color={'#fff'}>
                  {model.name}
                </TYPE.main>
                <TYPE.desc fontSize={12} color="#CED0DE">
                  作者：PicWiz
                </TYPE.desc>
              </ItemTextWrapper>
            </Item>
          );
        })}
      </ItemsWrapper>
      <BottomNav />
    </Wrapper>
  );
}

export default observer(Home);
