import { gql } from '@apollo/client';

export interface Style {
  id: string;
  image: string;
  title: string;
}

export interface Model {
  id: string;
  name: string;
  styles: Style[];
}

export enum TaskStatus {
  PENDING = 'PENDING',
  IN_QUEUE = 'IN_QUEUE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum CharacterTrainingStatus {
  PENDING = 'PENDING',
  IN_QUEUE = 'IN_QUEUE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface Task {
  id: string;
  style: Style;
  character: Character;
  status: TaskStatus;
  progress: number;
  images: string[];
  createTime: number;
  completedTime?: number;
}

export interface Character {
  id: string;
  name: string;
  title: string;
  status: CharacterTrainingStatus;
  progress: number;
  files: string[];
  task: Task[];
  createTime: number;
  completedTime?: number;
  uploadPosts: string[];
}

export interface UserInfo {
  id: string;
  characters: Character[];
  tasks: Task[];
  defaultCharacter?: Character;
}

export const QUERY_MODELS = gql`
  query models {
    models {
      id
      name
      styles {
        id
        image
        title
      }
    }
  }
`;

export const QUERY_USER_DATA = gql`
  query user {
    user {
      id
      name
      characters {
        id
        name
        status
        progress
        files
        createTime
        completedTime
        uploadPosts
      }
      tasks {
        id
        character {
          id
          name
        }
        style {
          id
          image
          title
        }
        status
        progress
        images
        createTime
        completedTime
      }
      defaultCharacter {
        id
        name
        status
        progress
        files
        createTime
        completedTime
      }
    }
  }
`;

export const QUERY_CHARACTER = gql`
  query character($id: String!) {
    character(id: $id) {
      id
      name
      status
      progress
      files
      createTime
      completedTime
      uploadPosts
    }
  }
`;

export const QUERY_TASK = gql`
  query task($id: String!) {
    task(id: $id) {
      id
      character {
        id
        name
      }
      style {
        id
        image
        title
      }
      status
      progress
      images
      createTime
      completedTime
    }
  }
`;
