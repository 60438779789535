import { LeftArrowSvg, RightArrowSvg } from 'assets/img';
import React, { useEffect, useMemo, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 60vh;
  width: 100%;

  .slick-slider {
    height: 100%;
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
        .slick-slide {
          height: 100%;
          div {
            height: 100%;
            .img {
              display: flex !important;
              align-items: center;
              justify-content: center;
            }
          }
          img {
            width: 100%;
          }
        }
      }
    }

    .slick-prev {
      left: 4px;
      z-index: 999;
    }

    .slick-next {
      right: 4px;
      z-index: 999;
    }
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const ImageWrapper2 = styled.img`
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
`;

function PrewArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <LeftArrowSvg
      className={className}
      style={{ ...style, display: 'block', width: '32px', height: '32px ' }}
      onClick={onClick}
    />
  );
}

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <RightArrowSvg
      className={className}
      style={{ ...style, display: 'block', width: '32px', height: '32px ' }}
      onClick={onClick}
    />
  );
}

interface Props {
  images: string[];
  onChange?(index: number): void;
  initSlide?: number;
  elementType?: 'div' | 'img';
  className?: string;
}

export default function SliderImage({
  images,
  onChange,
  initSlide = 0,
  elementType = 'div',
  className,
}: Props) {
  const [ref, setRef] = useState<Slider>();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrewArrow />,
    nextArrow: <NextArrow />,
    afterChange: onChange,
  };

  useEffect(() => {
    if (ref && initSlide) {
      ref.slickGoTo(initSlide);
    }
  }, [ref, initSlide]);

  return (
    <Wrapper className={className}>
      <Slider ref={(r) => setRef(r as any)} {...settings}>
        {images.map((item, index) => (
          <div key={index} className={elementType}>
            {elementType === 'div' ? (
              <ImageWrapper style={{ backgroundImage: `url(${item})` }} />
            ) : (
              <ImageWrapper2 src={item} />
            )}
          </div>
        ))}
      </Slider>
    </Wrapper>
  );
}
