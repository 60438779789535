import React, { useState } from 'react';
import { Drawer, Typography, IconButton, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { TestAvatarSvg, MoreButtonSvg } from 'assets/img';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { serverRequestApi } from 'gql';
import { StyleVo, StyleImageVo } from 'gql/server/__generated__/request';
import MainButton from 'components/MainButton';
import { useToast } from 'components/ToastContext';
import { isGenerator } from 'mobx/dist/internal';

const TestAvatar = styled(TestAvatarSvg)`
  width: 32px;
  height: auto;
  margin-left: 4px;
  margin-right: 12px;
`;

const StyleAvatar = styled('img')`
  width: 32px;
  height: auto;
  margin-left: 4px;
  margin-right: 12px;
  border-radius: 6px;
`;

const MoreButtonIcon = styled(MoreButtonSvg)`
  width: 32px;
  height: auto;
`;

const TestExample = styled(TestAvatarSvg)`
  width: 96px;
  height: 96px;
`;

const ExampleImage = styled('img')`
  width: 96px;
  height: 96px;
  border-radius: 10px;
`;

const TitleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px 10px 24px;
`;

const ExampleBox = styled(Box)`
  display: flex;
  overflow: auto;
  padding-right: 30px;
  padding-left: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
`;

const ExampleItem = styled(Box)`
  position: relative;
  margin-right: -10px;
  width: 96px;
  height: 96px;
`;

const LikeBox = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 8px 8px;
  color: #fff;
  padding: 4px;
`;

const LikeLabel = styled(Typography)`
  color: white;
  margin-left: 4px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const StyleBox = styled(Box)`
  display: flex;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  padding-left: 20px;
  padding-right: 8px;
  margin: 16px 0;
`;

const MoreButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: linear-gradient(
    94deg,
    rgba(60, 70, 70, 0.8) 4.44%,
    rgba(53, 60, 60, 0.7) 96.91%
  );
  border: 4px solid #2a2c2e;
  height: 48px;
  min-width: 48px;
  margin-right: 12px;

  color: #fff;
  margin-left: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const StyleItem = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  transition: background 0.3s, border 0.3s;
  height: 48px;
  cursor: pointer;
  margin-right: 12px;
`;

const ButtonBox = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0 20px;
`;

const ButtonText = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #111;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const drawerPaperStyle = {
  borderRadius: '24px 24px 0px 0px',
  background: 'linear-gradient(180deg, rgba(23, 25, 27, 0.01) 0%, #17191B 20%)',
  boxShadow: '0px -8px 40px 0px rgba(0, 0, 0, 0.20)',
  backdropFilter: 'blur(6px)',
  paddingBottom: '32px',
};

interface StyleOption {
  label: string;
  image: string;
  likes: number;
}

interface BottomDrawerProps {
  open: boolean;
  isGenerator: boolean;
  styleList: StyleVo[];
  selectedStyle: StyleVo | null;
  onClose: () => void;
  handleNextBtnClick: () => void;
  handleGenerateBtnClick: () => void;
  handleStyleItem: (style: StyleVo) => void;
}

const BottomDrawer: React.FC<BottomDrawerProps> = ({
  open,
  isGenerator,
  styleList,
  selectedStyle,
  onClose,
  handleNextBtnClick,
  handleGenerateBtnClick,
  handleStyleItem,
}) => {
  const history = useHistory();
  const { startLoading, stopLoading } = useToast();

  const [showContent, setShowContent] = useState(false);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [styleImages, setStyleImages] = useState<StyleImageVo[]>([]);

  const getStyleImages = async (uuid: string) => {
    if (uuid && uuid !== '') {
      const response = await serverRequestApi.getStyleImages({
        uuid: uuid,
        page: 1,
        pageSize: 10,
      });

      stopLoading();
      setStyleImages(response.styleImages || []);
    }
  };

  const handleOptionClick = (option: StyleVo) => {
    handleStyleItem(option);
    getStyleImages(option.uuid || '');
    setShowContent(true);
  };

  const handleMoreButtonClick = () => {
    onClose();
    history.push('/styleLibrary');
  };

  React.useEffect(() => {
    if (!selectedStyle) {
      setShowContent(false);
    }

    if (selectedStyle) {
      setShowContent(true);
      getStyleImages(selectedStyle.uuid || '');
    }
  }, [selectedStyle]);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    setTouchEnd(e.changedTouches[0].clientX);
    handleSwipe();
  };

  const handleSwipe = () => {
    if (touchStart !== null && touchEnd !== null) {
      const distance = touchStart - touchEnd;

      if (distance > 50) {
        if (currentIndex < styleList.length - 1) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else if (currentIndex >= styleList.length - 2) {
          handleMoreButtonClick();
        }
      }
    }
  };

  const handleLikeBtn = (image: StyleImageVo) => {
    const styleLikeReq = { uuid: image.uuid ?? '' };
    startLoading();
    if (image.isLiked === '0') {
      serverRequestApi
        .likeImage({ styleLikeReq })
        .then((response) => {
          if (response.likeImage) {
            getStyleImages(selectedStyle?.uuid || '');
          }
        })
        .catch((error) => {
          stopLoading();
          console.log('Error liking image:', error);
        });
    } else {
      serverRequestApi
        .cancelLikeImage({ styleLikeReq })
        .then((response) => {
          if (response.cancelLikeImage) {
            getStyleImages(selectedStyle?.uuid || '');
          }
        })
        .catch((error) => {
          stopLoading();
          console.log('Error liking image:', error);
        });
    }
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: drawerPaperStyle,
      }}
      sx={{
        '& .MuiBackdrop-root': {
          background: 'transparent',
        },
      }}
    >
      <TitleBox>
        <Typography
          sx={{
            color: '#fff',
            fontSize: '20px',
            fontFamily: '"Bai Jamjuree", sans-serif',
          }}
        >
          {selectedStyle && styleImages && styleImages.length > 0
            ? 'Others earning points with this style'
            : 'Select a style'}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ color: '#fff' }} />
        </IconButton>
      </TitleBox>

      {selectedStyle && styleImages && styleImages.length > 0 && (
        <ExampleBox
          sx={{
            opacity: showContent ? 1 : 0,
            transform: showContent ? 'translateY(0)' : 'translateY(50px)',
          }}
        >
          {styleImages.map((option) => (
            <ExampleItem
              key={option.uuid}
              onClick={() => {
                handleLikeBtn(option);
              }}
            >
              <ExampleImage src={option.generatedImagePath} />
              <LikeBox>
                <FavoriteIcon
                  sx={{ color: option.isLiked === '1' ? '#FF4438' : '#fff' }}
                />
                <LikeLabel>{option.likes}</LikeLabel>
              </LikeBox>
            </ExampleItem>
          ))}
        </ExampleBox>
      )}

      <StyleBox onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        {styleList.map((option) => (
          <Box
            key={option.uuid}
            onClick={() => handleOptionClick(option)}
            sx={{ flexShrink: 0 }}
          >
            {option.name === 'MoreButton' ? (
              <MoreButton onClick={handleMoreButtonClick}>More</MoreButton>
            ) : (
              <StyleItem
                sx={{
                  background:
                    selectedStyle?.uuid === option.uuid
                      ? 'linear-gradient(90deg, #FFF 0%, #E3FFE6 100%)'
                      : 'linear-gradient(94deg, rgba(60, 70, 70, 0.80) 4.44%, rgba(53, 60, 60, 0.70) 96.91%)',
                  border:
                    selectedStyle?.uuid === option.uuid
                      ? '4px solid #2B5D40'
                      : '4px solid #2A2C2E',
                }}
              >
                <StyleAvatar src={option.originalImagePath} />
                <Typography
                  sx={{
                    color:
                      selectedStyle?.uuid === option.uuid ? '#132828' : '#fff',
                    fontSize: '14px',
                    marginRight: '16px',
                    fontFamily: '"Bai Jamjuree", sans-serif',
                  }}
                >
                  {option.name}
                </Typography>
              </StyleItem>
            )}
          </Box>
        ))}
      </StyleBox>

      <ButtonBox>
        <MainButton
          disabled={!showContent}
          onClick={() => {
            if (selectedStyle) {
              if (isGenerator) {
                handleGenerateBtnClick();
              } else {
                handleNextBtnClick();
              }
            }
          }}
        >
          <ButtonText>
            <Typography>{isGenerator ? 'Generate' : 'Next'}</Typography>
            {/* <Typography sx={{ ml: '10px' }}>+20</Typography> */}
          </ButtonText>
        </MainButton>
      </ButtonBox>
    </Drawer>
  );
};

export default BottomDrawer;
