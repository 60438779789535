import { makeAutoObservable } from 'mobx';
import storage from 'utils/storage';

class FileStore {
  file: File | null = null;
  fileId: string | null = null;
  taskId: string | null = null;
  isFirstVisit = true;

  constructor() {
    makeAutoObservable(this);
  }

  getTaskId(): string | null {
    return storage.get('taskId9') || null;
  }

  getFileId(): string | null {
    return storage.get('fileId9') || null;
  }

  setFile(file: File | null) {
    this.file = file;
  }

  setTaskId(taskId: string | null) {
    this.taskId = taskId;
    if (taskId) {
      storage.set('taskId9', taskId);
    } else {
      storage.remove('taskId9');
    }
  }

  setFileId(fileId: string | null) {
    this.fileId = fileId;
    if (fileId) {
      storage.set('fileId9', fileId);
    } else {
      storage.remove('fileId9');
    }
  }

  setIsFirstVisit(isFirstVisit: boolean) {
    this.isFirstVisit = isFirstVisit;
  }
}

const fileStore = new FileStore();
export default fileStore;
