import { LogoSvg } from 'assets/img';
import Button from 'components/Button';
import Loading from 'components/Loading';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import user from 'stores/user';
import styled from 'styled-components';
import { TYPE } from 'theme';
import storage from 'utils/storage';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  padding-top: 40%;
`;

const Logo = styled(LogoSvg)`
  width: 238px;
  height: 52px;
`;

const isLogout = storage.get('loginOut');

export default function Login() {
  const [error, setError] = useState('');

  const history = useHistory();

  async function loginFunc() {
    const loginQs =
      (window as any).Telegram.WebApp.initData ||
      window.localStorage.getItem('loginQs');
    console.log('loginQs', loginQs);

    if (loginQs) {
      const res = await user.login(loginQs);
      console.log(res);
      if (res?.success) {
        history.push('/home');
      } else {
        setError(res?.message || 'Something Wrong');
      }
    }
  }
  useEffect(() => {
    loginFunc();
  }, [user, (window as any).Telegram.WebApp.initData, user]);

  return (
    <Wrapper>
      <Logo />
      {isLogout ? (
        <Button
          style={{
            width: '200px',
            marginTop: '60px',
          }}
          onClick={() => {
            loginFunc();
          }}
        >
          登录
        </Button>
      ) : error ? (
        <>
          <Button
            style={{
              width: '200px',
              marginTop: '60px',
            }}
            onClick={() => {
              loginFunc();
            }}
          >
            登录
          </Button>
          <TYPE.main color="red" fontSize={14} mobileFz={14} textAlign="center">
            {error}
          </TYPE.main>
          <Loading size={40} />
        </>
      ) : (
        <>
          <TYPE.main
            color="#3873FF"
            fontSize={24}
            mobileFz={24}
            textAlign="center"
            fontWeight={600}
            marginTop={60}
          >
            注册成功！
          </TYPE.main>
          <TYPE.main
            color="#032B43"
            fontSize={14}
            mobileFz={14}
            textAlign="center"
          >
            请等待... <br />
            您将进入主页
          </TYPE.main>
          <Loading size={40} />
        </>
      )}
    </Wrapper>
  );
}
