import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { StyleCardIconSvg } from 'assets/img';
import styled from 'styled-components';
import Back from 'components/Back';
import MainButton from 'components/MainButton';
import { serverRequestApi } from 'gql';
import { StyleImageVo } from 'gql/server/__generated__/request';
import { useHistory, useLocation } from 'react-router-dom';
import { useToast } from 'components/ToastContext';

const Avatar = styled('img')`
  width: 100%;
  height: auto;
  min-height: 70vw;
  border-radius: 12px;
`;

const StyleCardIcon = styled(StyleCardIconSvg)`
  width: 32px;
  height: auto;
  position: absolute;
  top: 10px;
  left: 10px;
`;

const TitleTag = styled(Typography)`
  display: flex;
  padding: 4px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--white-pale, linear-gradient(90deg, #fff 0%, #e3ffe6 100%));
  color: #162a69;
  text-align: center;
  font-size: 14px;
  font-weight: 860;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;

  position: absolute;
  top: 10px;
  left: 10px;
`;

const ContainerBox = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 28px;
  gap: 40px;
`;

const ImageBox = styled(Box)`
  width: 100%;
  position: relative;
`;

const PublicBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 16px;
  left: 16px;
  height: 52px;
`;

const GreenPoint = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #70f06b;
  margin-right: 4px;
`;

const PublicLabel = styled(Typography)`
  color: var(--White, #fff);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 590;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const LikeBox = styled(Box)`
  display: flex;
  position: absolute;
  bottom: 24px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  align-items: center;
  height: 36px;
`;

const LikeLabel = styled(Typography)`
  color: white;
  margin-left: 4px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const DescLabel = styled(Typography)`
  color: var(--White-80, rgba(255, 255, 255, 0.8));
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 100%;
  font-family: 'Bai Jamjuree', sans-serif;
`;

type AvatarDetailProps = {
  imageUrl: string;
  likes: number;
  isPublic: boolean;
  onShare: () => void;
};

interface LocationState {
  selectedImage?: StyleImageVo;
}

const AvatarDetail: React.FC = () => {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const { startLoading, stopLoading } = useToast();

  const [selectedImage, setSelectedImage] = useState<StyleImageVo | null>(null);

  useEffect(() => {
    if (location.state) {
      const stateImage = location.state.selectedImage;
      if (stateImage) {
        setSelectedImage(stateImage);
      }
    }

    return () => {};
  }, []);

  const handleShare = () => {
    startLoading();
    const setPublic = selectedImage?.isPublic === '0' ? '1' : '0';
    const styleLikeReq = {
      uuid: selectedImage?.uuid || '',
      isPublic: setPublic,
    };
    serverRequestApi
      .publicImage({ styleLikeReq })
      .then(() => {
        history.goBack();
        stopLoading();
      })
      .catch(() => {
        console.error('Error sharing image:');
        stopLoading();
      });
  };

  return (
    <ContainerBox>
      <Back />
      <ImageBox>
        <Avatar src={selectedImage?.generatedImagePath} />
        <TitleTag>Full NFT</TitleTag>

        {selectedImage?.isPublic === '1' && (
          <PublicBox>
            <GreenPoint />
            <PublicLabel>PUBLIC</PublicLabel>
          </PublicBox>
        )}
        <LikeBox>
          <FavoriteIcon sx={{ color: '#FF4438' }} />
          <LikeLabel>{selectedImage?.likes}</LikeLabel>
        </LikeBox>
      </ImageBox>

      <DescLabel>
        Your public avatar may serve as a reference for the same style. The more
        likes you get, the more rewards you will receive
      </DescLabel>

      <MainButton
        text={
          selectedImage?.isPublic === '0' ? 'Share to get like' : 'Set Private'
        }
        onClick={handleShare}
      />
    </ContainerBox>
  );
};

export default AvatarDetail;
