import React, { useRef, useState, useEffect, useCallback } from 'react';

interface SpriteAnimationProps {
  folderPath: string;
  filePrefix: string;
  fileSuffix: string;
  frameCount: number;
  fps?: number;
  canvasWidth: number | string;
  canvasHeight: number | string;
  useLeadingZero?: boolean;
}

const SpriteAnimation: React.FC<SpriteAnimationProps> = ({
  folderPath,
  filePrefix,
  fileSuffix,
  frameCount,
  fps = 10,
  canvasWidth,
  canvasHeight,
  useLeadingZero = true,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imagesRef = useRef<(HTMLImageElement | null)[]>(
    Array(frameCount).fill(null),
  );
  const [currentFrame, setCurrentFrame] = useState(0);
  const [loadedFrames, setLoadedFrames] = useState(0);

  const generateFileName = (index: number) => {
    const indexString = useLeadingZero
      ? String(index).padStart(5, '0')
      : String(index);
    return `${filePrefix}${indexString}${fileSuffix}`;
  };

  useEffect(() => {
    const loadImages = async () => {
      for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        const fileName = generateFileName(i);
        img.src = `${process.env.PUBLIC_URL}${folderPath}/${fileName}`;

        img.onload = () => {
          imagesRef.current[i] = img;
          setLoadedFrames((prev) => prev + 1);
        };
        img.onerror = () => console.error(`Image failed to load: ${img.src}`);
      }
    };

    loadImages();
  }, [frameCount, folderPath, filePrefix, fileSuffix, useLeadingZero]);

  const drawFrame = useCallback((frameIndex: number) => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const image = imagesRef.current[frameIndex];
    if (canvas && ctx && image) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    }
  }, []);

  useEffect(() => {
    let animationId: number;
    let lastFrameTime = Date.now();

    const playAnimation = () => {
      const now = Date.now();
      const elapsed = now - lastFrameTime;

      if (elapsed > 1000 / fps) {
        setCurrentFrame((prevFrame) => (prevFrame + 1) % frameCount);
        lastFrameTime = now;
      }

      animationId = requestAnimationFrame(playAnimation);
    };

    animationId = requestAnimationFrame(playAnimation);
    return () => cancelAnimationFrame(animationId);
  }, [frameCount, fps]);

  useEffect(() => {
    if (imagesRef.current[currentFrame]) {
      drawFrame(currentFrame);
    }
  }, [currentFrame, drawFrame, loadedFrames]);

  const canvasStyle = {
    width: typeof canvasWidth === 'number' ? `${canvasWidth}px` : canvasWidth,
    height:
      typeof canvasHeight === 'number' ? `${canvasHeight}px` : canvasHeight,
  };

  return (
    <canvas
      ref={canvasRef}
      width={typeof canvasWidth === 'number' ? canvasWidth : undefined}
      height={typeof canvasHeight === 'number' ? canvasHeight : undefined}
      style={canvasStyle}
    />
  );
};

export default SpriteAnimation;
