import { Model, QUERY_MODELS } from 'gql/query';
import { action, makeAutoObservable } from 'mobx';
export class ModelsStore {
  models: Model[] = [];
  constructor() {
    makeAutoObservable<this>(this);
  }

  @action async getModals() {}
}

export default new ModelsStore();
