import Row from 'components/Row';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BackSvg } from 'assets/img';
import WebApp from '@twa-dev/sdk';

const Wrapper = styled(Row)`
  gap: 10px;
  cursor: pointer;
  position: fixed;
  top: 8px;
  left: 10px;
  width: 100px;
  z-index: 10;
`;

const Button = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 24px;
`;

export default function Back(props: { path?: string }) {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.indexOf('/welcome') !== -1 ||
      location.pathname.indexOf('/chat') !== -1 ||
      location.pathname.indexOf('/explore') !== -1 ||
      location.pathname.indexOf('/myReward') !== -1
    ) {
      WebApp.BackButton.hide();
    } else {
      WebApp.BackButton.show();
    }
    const handleBackClick = () => {
      if (props.path) {
        history.push(props.path);
      } else {
        history.goBack();
      }
    };
    WebApp.BackButton.onClick(handleBackClick);
    return () => {
      WebApp.BackButton.offClick(handleBackClick);
    };
  }, [history, location, props.path]);

  return <></>;
}
