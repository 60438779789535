import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, Button } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';
import Back from 'components/Back';
import { serverRequestApi } from 'gql';
import { FileVo } from 'gql/server/__generated__/request';
import { DeleteOutlineSvg, AddPhotoSvg } from 'assets/img';
import api from 'request/api';
import { showErrorToast, useToast } from 'components/ToastContext';

const StyleImage = styled('img')`
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  object-fit: cover;
`;

const ContainerBox = styled(Box)`
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: calc(
    var(--tg-safe-area-inset-top) + var(--tg-content-safe-area-inset-top)
  );
  padding-bottom: env(safe-area-inset-bottom, 0px);
`;

const TitleBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 10px;
`;

const AddBox = styled(Box)`
  display: flex;
  padding: 8px 12px 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: radial-gradient(
    81.39% 83.59% at 79.14% 43.6%,
    rgba(112, 234, 161, 0.12) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
  box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
  width: 100%;
  height: 76px;
  margin-bottom: 40px;
`;

const AddPhoto = styled(AddPhotoSvg)`
  width: 24px;
  height: auto;
`;

const AddPhotoLabel = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const TitleLabel = styled(Typography)`
  font-size: 32px;
  font-weight: 700;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 28px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const AvatarsBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 20px;
`;

const AvatarItem = styled(Box)`
  aspect-ratio: 1 / 1;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const DeleteBox = styled(Box)`
  display: flex;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 40px;
  width: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
`;

const DeleteIcon = styled(DeleteOutlineSvg)`
  width: 24px;
  height: auto;
`;

const MyPhotos: React.FC = () => {
  const history = useHistory();
  const { startLoading, stopLoading } = useToast();
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const [imageList, setImageList] = useState<FileVo[]>([]);

  useEffect(() => {
    fetchPhotoList(1, 100);
  }, []);

  async function fetchPhotoList(page = 1, pageSize = 10) {
    startLoading();
    try {
      const response = await serverRequestApi.getUserFiles({
        page,
        pageSize,
      });

      console.log('Response:', response);
      setImageList(response.userFiles || []);
      stopLoading();
    } catch (error) {
      console.log('Error fetching style images:', error);
      stopLoading();
    }
  }

  const handleAddPhotoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleAvatarClick = (file: FileVo) => {};

  const handleDeleteImage = (file: FileVo) => {
    startLoading();
    try {
      serverRequestApi
        .deleteUserFile({
          fileId: file.fileId || '',
        })
        .then(() => {
          fetchPhotoList(1, 100);
          stopLoading();
        });
    } catch (error) {
      console.log('Error fetching style images:', error);
      stopLoading();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const uploadedFile = files[0];

      startLoading();
      upload(uploadedFile).then((fileStr) => {
        if (fileStr !== 'error') {
          fetchPhotoList(1, 100);
        }
        stopLoading();
      });
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  async function upload(file: any) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', '2');
    try {
      const response = await api.post('/uploadForGenerate', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('response', response);
      if (response.code === 200) {
        return response.data;
      } else {
        showErrorToast(
          response?.msg ??
            'Sorry!Something went wrong!Please try again in a few minutes.',
        );
        return 'error';
      }
    } catch (error) {
      console.log('图片上传失败:', error);
      return 'error';
    }
  }

  return (
    <ContainerBox>
      <Back />
      <TitleBox>
        <TitleLabel>My Photos</TitleLabel>
      </TitleBox>

      <AddBox onClick={handleAddPhotoClick}>
        <AddPhoto />
        <AddPhotoLabel>Upload new image</AddPhotoLabel>
      </AddBox>

      <AvatarsBox>
        {imageList.map((file) => (
          <AvatarItem key={file.fileId} onClick={() => handleAvatarClick(file)}>
            <StyleImage src={file.fileUrl} />
            <DeleteBox
              onClick={() => {
                handleDeleteImage(file);
              }}
            >
              <DeleteIcon />
            </DeleteBox>
          </AvatarItem>
        ))}
      </AvatarsBox>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </ContainerBox>
  );
};

export default MyPhotos;
