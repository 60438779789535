import Back from 'components/Back';
import { AutoColumn } from 'components/Column';
import { RowCenter } from 'components/Row';
import React, { useState, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Clear';
import { useHistory } from 'react-router-dom';
import { useCharacterOperations } from 'hooks/useCharacter';
import Fab from '@mui/material/Fab';
import { Character, CharacterTrainingStatus } from 'gql/query';
import Loading from 'components/Loading';
import user from 'stores/user';
import { observer } from 'mobx-react-lite';
import { CloseSvg, UploadSvg } from 'assets/img';

const Wrapper = styled.div`
  padding: 0 20px 12px 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // gap: 20px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  gap: 20px;
`;

const FullLoading = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 99;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const MyModelsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 10px;
`;

const MyModel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(50% - 20px);
  // flex: 1;

  img {
    width: 100%;
  }

  &.add {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(21, 101, 192);
    background-color: #fff;
    border-radius: 8px;

    svg {
      width: 60px;
      height: 60px;
    }
  }

  .remove {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer;
    background: red;
    border-radius: 50%;

    top: -5px;
    right: -5px;

    svg {
      color: #ffffff;
      width: 20px;
      height: 20px;
    }
  }
`;

const FabBigButton = styled(Fab)`
  width: 100%;
  padding: 0 16px;
  border-radius: 24px;
`;

const DemoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;

  > div {
    width: 28%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }
`;

interface ShowImage {
  type: 'file' | 'url';
  src: string;
  file?: File;
}

function CreateRole() {
  const history = useHistory();
  const [uploading, setUploading] = useState<boolean>(false);
  const [createdChar, setCreatedChar] = useState<Character | null>(null);
  const { createCharacter, uploadToCharacter, deleteCharacterImage } =
    useCharacterOperations();
  const [isCreate, setIsCreate] = useState(true);
  const [images, setImages] = useState<ShowImage[]>([]);

  useEffect(() => {
    if (user.serverUserInfo?.characters) {
      const pendingCharacter = user.serverUserInfo?.characters.find(
        (item) => item.status === CharacterTrainingStatus.PENDING,
      );

      if (pendingCharacter) {
        setCreatedChar(pendingCharacter);
        setIsCreate(false);
      }
    }
  }, [user.serverUserInfo]);

  useEffect(() => {
    if (createdChar?.files) {
      setImages(
        createdChar.files.map((item) => {
          return {
            type: 'url',
            src: item,
          };
        }),
      );
    }
  }, [createdChar]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length + images.length > 2) {
      alert('照片数量不能超过2张!');

      return;
    }

    if (event.target.files && event.target.files.length > 0) {
      const newImageUrls: ShowImage[] = Array.from(event.target.files).map(
        (file) => {
          return {
            type: 'file',
            src: URL.createObjectURL(file),
            file: file,
          };
        },
      );

      setImages([...images, ...newImageUrls]);
    }
  };

  const handleRemoveImage = async (img: ShowImage) => {
    const removeIndex = images.findIndex((item) => item === img);

    if (removeIndex !== -1) {
      if (img.type === 'file') {
        URL.revokeObjectURL(images[removeIndex].src);
        images.splice(removeIndex, 1);
      } else {
        if (createdChar) {
          const res = await deleteCharacterImage(createdChar?.id, img.src);
          if (res) {
            await user.getServerUserInfo();
            images.splice(removeIndex, 1);
          }
        }
      }
      setImages([...images]);
    }
  };

  const handleStart = async () => {
    if (
      user.serverUserInfo?.characters.find(
        (item) => item.status === CharacterTrainingStatus.IN_PROGRESS,
      )
    ) {
      alert('目前有分身训练中，请稍后重试');
      return;
    }
    if (uploading) {
      return;
    }

    setUploading(true);

    let operatedChar: Character | null = createdChar;

    if (!operatedChar) {
      const newCharName = `${user.user?.username}-${new Date().getTime()}`;
      operatedChar = await createCharacter(newCharName);
      setCreatedChar(operatedChar);
    }

    if (!operatedChar) {
      alert('创建分身失败！');
      return;
    }

    try {
      // character.files index
      const files = images.filter((item) => item.type === 'file');
      const serverImageUrl = images.filter((item) => item.type === 'url');
      if (files.length) {
        const currentUploadedImageUrlIndexs = serverImageUrl.map((item) =>
          operatedChar?.uploadPosts.findIndex((posts) => {
            const jsonPosts: any = JSON.parse(posts);
            return new RegExp(jsonPosts.fields.key).test(item.src);
          }),
        );

        const canUseUploadPosts: string[] = [];

        for (let i = 0, len = operatedChar.uploadPosts.length; i < len; i++) {
          if (currentUploadedImageUrlIndexs.indexOf(i) === -1) {
            canUseUploadPosts.push(operatedChar.uploadPosts[i]);
          }
        }

        await Promise.all(
          files.map((item, index) =>
            uploadToCharacter(canUseUploadPosts[index] || '', item.file),
          ),
        ).then(async () => {
          for (const file of files) {
            URL.revokeObjectURL(file.src);
          }
          setUploading(false);

          await user.getServerUserInfo();

          history.push(`/trainingloading?id=${operatedChar?.id}`);
        });
      } else if (operatedChar.files.length) {
        history.push(`/trainingloading?id=${operatedChar?.id}`);
      }
    } catch (error) {
      console.log(error);
      alert('图片分身上传失败, 请重新上传');
      setUploading(false);
      const files = images.filter((item) => item.type === 'file');
      for (const file of files) {
        URL.revokeObjectURL(file.src);
      }
      return;
    }
  };

  return (
    <Wrapper>
      {uploading && (
        <FullLoading>
          <TYPE.main style={{ marginBottom: 20 }}>
            图片上传中，请不要刷新页面......
          </TYPE.main>
          <Loading size={60} />
        </FullLoading>
      )}
      <Back path="/home" />
      <ContentWrapper>
        <RowCenter style={{ marginBottom: 6 }}>
          <TYPE.main fontSize={16} fontWeight={600}>
            {isCreate ? '制作数字分身' : `编辑分身${createdChar?.id}`}
          </TYPE.main>
        </RowCenter>
        <AutoColumn gap="20px" style={{ marginTop: '20px' }}>
          <TYPE.main color={'t03'} fontWeight={600}>
            请上传以下标准照片
          </TYPE.main>
          <DemoWrapper>
            <div>
              <img src={require('assets/img/demo/demo1.png')} alt="" />
              <TYPE.main color={'t03'} fontSize={14} mobileFz={14}>
                多角度
              </TYPE.main>
            </div>
            <div>
              <img src={require('assets/img/demo/demo2.png')} alt="" />
              <TYPE.main color={'t03'} fontSize={14} mobileFz={14}>
                多表情
              </TYPE.main>
            </div>
            <div>
              <img src={require('assets/img/demo/demo3.png')} alt="" />
              <TYPE.main color={'t03'} fontSize={14} mobileFz={14}>
                多背景
              </TYPE.main>
            </div>
          </DemoWrapper>
        </AutoColumn>
        <AutoColumn gap="10px">
          <TYPE.main fontSize={16} mobileFz={16} color={'t03'} fontWeight={600}>
            上传2张照片以内
          </TYPE.main>
          <TYPE.desc fontSize={14} mobileFz={14}>
            请避免图片中出现多人脸、脸部遮挡等情况，
            <br /> 否则可能导致效果异常。
          </TYPE.desc>
        </AutoColumn>

        <AutoColumn gap="20px" style={{ flex: 1, width: '100%' }}>
          <MyModelsWrapper>
            {images.map((item, index) => {
              return (
                <MyModel key={index}>
                  <span
                    className="remove"
                    onClick={() => handleRemoveImage(item)}
                  >
                    <CloseSvg />
                  </span>
                  <img src={item.src} alt={`file-preview-${index}`} />
                </MyModel>
              );
            })}
            {images.length < 2 && (
              <MyModel
                className="add"
                onClick={() => document.getElementById('file-upload')?.click()}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  id="file-upload"
                />
                <UploadSvg />
                <TYPE.desc>点击上传</TYPE.desc>
              </MyModel>
            )}
          </MyModelsWrapper>
        </AutoColumn>
      </ContentWrapper>
      <FabBigButton
        disabled={images.length === 0 || uploading}
        onClick={() => handleStart()}
        color="primary"
        sx={{ marginTop: '20px' }}
      >
        开始生成
      </FabBigButton>
    </Wrapper>
  );
}

export default observer(CreateRole);
