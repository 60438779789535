import { makeAutoObservable } from 'mobx';
import storage from 'utils/storage';
import { MessageProps } from 'pages/chat';

class ChatStore {
  messages: MessageProps[] = [];
  chatId: string | null = null;

  constructor() {
    makeAutoObservable(this);
    this.chatId = this.getChatId();
    this.messages = this.getMessages();
  }

  getChatId(): string | null {
    return storage.get('chatId9') || null;
  }

  setChatId(chatId: string | null) {
    this.chatId = chatId;
    if (chatId) {
      storage.set('chatId9', chatId);
    } else {
      storage.remove('chatId9');
    }
  }

  addMessage(message: MessageProps) {
    this.messages.push(message);
    this.saveMessages();
  }

  setMessages(newMessages: MessageProps[]) {
    this.messages = newMessages;
    this.saveMessages();
  }

  getMessages(): MessageProps[] {
    const savedMessages = storage.get('messages9');
    return savedMessages ? JSON.parse(savedMessages) : [];
  }

  saveMessages() {
    storage.set('messages9', JSON.stringify(this.messages));
  }

  clearMessages() {
    this.messages = [];
    storage.remove('messages9');
  }

  get allMessages() {
    return this.messages;
  }
}

export default new ChatStore();
