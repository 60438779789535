import React, { useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from '@mui/material';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Back from 'components/Back';
import fileStore from 'stores/fileStore';
import { observer } from 'mobx-react';
import Spline from '@splinetool/react-spline';
import { motion } from 'framer-motion';
import MainButton from 'components/MainButton';
import storage from 'utils/storage';

const CustomStepIcon = styled('div')<{ active?: boolean; completed?: boolean }>`
  width: 48px;
  height: 32px;
  border-radius: 20px;
  border: ${({ active }) =>
    active ? '2px solid #55EB64' : '1px solid rgba(255, 255, 255, 0.12)'};
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active }) => (active ? '#53EB8D' : 'rgba(255, 255, 255, 0.60)')};
  font-weight: bold;
`;

const CustomStepConnector = styled(StepConnector)`
  & .MuiStepConnector-line {
    border-color: rgba(255, 255, 255, 0.12);
    border-width: 2px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 2px;
  }
`;

const ContainerBox = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: calc(
    var(--tg-safe-area-inset-top) + var(--tg-content-safe-area-inset-top)
  );
  padding-bottom: env(safe-area-inset-bottom, 0px);
`;

const StepContainerBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StepBox = styled(Box)`
  display: flex;
  margin-top: 4vh;
  margin-bottom: 2.4vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StepTitle = styled(Typography)`
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 590;
  line-height: 36px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const Description = styled(Typography)`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.442px;
  padding: 0 26px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const ButtonBox = styled(Box)`
  width: 100%;
  margin-bottom: 4vh;
`;

const ContinueButton = styled(Button)`
  display: flex;
  height: 52px;
  width: 100%;
  padding: 16px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 16px;
  background: radial-gradient(
    92.07% 129.88% at 7.99% 8.33%,
    #b2ff51 0%,
    #4ee88c 100%
  );
  box-shadow: 0px 8px 68px 0px rgba(0, 0, 0, 0.2);
  color: #111;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
  letter-spacing: 6px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const steps = ['1', '2', '3'];

const FirstCreation: React.FC = () => {
  const soundRef = useRef<HTMLAudioElement | null>(null);

  const history = useHistory();

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const variants = {
    hidden: {
      opacity: 0,
      y: 80,
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i === 1 ? 1 : i * 1,
        duration: 1.5,
      },
    }),
  };

  const imageFadeInVariants = {
    hidden: {
      opacity: 0,
      filter: 'blur(2px)',
      scale: 0,
    },
    visible: (i: number) => ({
      opacity: 1,
      filter: 'none',
      scale: 1,
      transition: {
        delay: i * 1,
        duration: 1.5,
        ease: 'easeOut',
      },
    }),
  };

  const fadeInVariants = {
    hidden: {
      opacity: 0,
      filter: 'blur(2px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      filter: 'none',
      transition: {
        delay: i * 1,
        duration: 2,
        ease: 'easeOut',
      },
    }),
  };

  const handleButtonClick = () => {
    handleAnimationStart();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleAnimationStart = () => {
    if (soundRef.current) {
      soundRef.current.play().catch((error) => {
        console.log('Error playing sound:', error);
      });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      fileStore.setFile(files[0]);
      storage.set('hasVisited14', 'true');
      history.push('/chat');
    }
  };

  return (
    <ContainerBox>
      <Back />
      <audio ref={soundRef} src="/audio/textShow.mp3" preload="auto" />
      <StepContainerBox>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={0}
          style={{ width: '100%' }}
        >
          <StepBox>
            <StepTitle>Step 1：</StepTitle>
            <StepTitle sx={{ color: '#53EB8D' }}>Upload image</StepTitle>
          </StepBox>
        </motion.div>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={1}
          style={{ width: '100%' }}
        >
          <Stepper
            activeStep={0}
            alternativeLabel
            connector={<CustomStepConnector />}
          >
            {steps.map((label, idx) => (
              <Step key={label} sx={{ px: '30px' }}>
                <StepLabel
                  StepIconComponent={({ active, completed }) => (
                    <CustomStepIcon active={active} completed={completed}>
                      {idx + 1}
                    </CustomStepIcon>
                  )}
                ></StepLabel>
              </Step>
            ))}
          </Stepper>
        </motion.div>
      </StepContainerBox>
      <motion.div
        variants={imageFadeInVariants}
        initial="hidden"
        animate="visible"
        custom={3}
        style={{ width: '100%' }}
      >
        <Box sx={{ width: '100vw', height: '40vh' }}>
          <Spline scene="https://prod.spline.design/kRMsGMk66dHaL056/scene.splinecode" />
        </Box>
      </motion.div>
      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={2}
        style={{ width: '100%' }}
      >
        <Description>
          Upload a clear photo that shows your facial features. More visible
          facial points help create a better augmented avatar.
        </Description>
      </motion.div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      <motion.div
        variants={fadeInVariants}
        initial="hidden"
        animate="visible"
        custom={4.5}
        style={{ width: '80%' }}
      >
        <ButtonBox onClick={handleButtonClick}>
          <MainButton text="UPLOAD" />
        </ButtonBox>
      </motion.div>
    </ContainerBox>
  );
};

export default observer(FirstCreation);
