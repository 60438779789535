import React from 'react';
import { Card, CardMedia, Box, Typography, Chip } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { TestAvatarSvg } from 'assets/img';
import styled from 'styled-components';

const TestAvatar = styled(TestAvatarSvg)`
  width: 100px;
  height: 100px;
  position: relative;
`;

const ContainerBox = styled(Box)`
  display: flex;
  padding: 8px 12px 8px 8px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: radial-gradient(
    81.39% 83.59% at 79.14% 43.6%,
    rgba(112, 234, 161, 0.12) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
  box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
  margin-top: 16px;
`;

const LikeBox = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;
  bottom: 6px;
`;

const LikeLabel = styled(Typography)`
  margin-left: 4px;
  color: var(--White, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 590;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const TitleBox = styled(Box)`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

const TitleLabel = styled(Typography)`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const PublicBox = styled(Box)`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const GreenPoint = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #70f06b;
  margin-right: 4px;
`;

const PublicLabel = styled(Typography)`
  color: var(--White, #fff);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 590;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const TagsBox = styled(Box)`
  display: flex;
  gap: 8px;
  margin-top: 20px;
`;

const Tag = styled(Box)`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #526362;
  color: var(--White-80, rgba(255, 255, 255, 0.8));
  font-size: 12px;
  font-style: normal;
  font-weight: 860;
  line-height: 16px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

interface MyStyleCardProps {
  image: string;
  styleName: string;
  likes: number;
  tags: string[];
  isPublic: boolean;
}

const MyStyleCard: React.FC<MyStyleCardProps> = ({
  image,
  styleName,
  likes,
  tags,
  isPublic,
}) => (
  <ContainerBox>
    <Box sx={{ position: 'relative' }}>
      <TestAvatar />
      <LikeBox>
        <FavoriteIcon sx={{ color: '#FF4438' }} />
        <LikeLabel>{likes}</LikeLabel>
      </LikeBox>
    </Box>
    <Box sx={{ flex: 1 }}>
      <TitleBox>
        <TitleLabel>{styleName}</TitleLabel>
        {isPublic && (
          <PublicBox>
            <GreenPoint />
            <PublicLabel>PUBLIC</PublicLabel>
          </PublicBox>
        )}
      </TitleBox>
      <TagsBox>
        {tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagsBox>
    </Box>
  </ContainerBox>
);

export default MyStyleCard;
