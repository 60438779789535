import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { UploadIconSvg } from 'assets/img';
import './CreateStyle.css';
import Back from 'components/Back';

import UploadedImagesPreview from './UploadedImagesPreview';

const UploadButton = styled(Button)`
  margin-top: 40px;
  width: 60%;
`;

const UploadIcon = styled(UploadIconSvg)`
  width: 100%;
  height: auto;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TitleLabel = styled(Typography)`
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
  margin-left: 24px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const UploadTitle = styled(Typography)`
  color: #53eb8d;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 590;
  line-height: 32px;
  margin-top: 88px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const UploadDesc = styled(Typography)`
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const CreateStyle: React.FC = () => {
  const [images, setImages] = useState<Array<{ src: string; tags: string[] }>>(
    [],
  );
  const [showPreview, setShowPreview] = useState(false);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const uploadedImages = Array.from(files).map((file) => ({
        src: URL.createObjectURL(file),
        tags: ['Cyberpunk', 'Sci-fi Movie'],
      }));
      setImages(uploadedImages);
      setShowPreview(true);
    }
  };

  return (
    <Container>
      <Back />
      <TitleLabel>Create my style</TitleLabel>
      {!showPreview ? (
        <ContentContainer>
          <UploadTitle>Upload image</UploadTitle>
          <UploadDesc>
            Upload 4-6 images fit the style you
            <br />
            want to create
          </UploadDesc>
          <UploadButton
            onClick={() => document.getElementById('fileInput')?.click()}
          >
            <UploadIcon />
          </UploadButton>
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            multiple
            hidden
            onChange={handleImageUpload}
          />
        </ContentContainer>
      ) : (
        <UploadedImagesPreview images={images} />
      )}
    </Container>
  );
};

export default CreateStyle;
