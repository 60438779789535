import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import styled, { keyframes, css } from 'styled-components';
import StyleCard from 'components/StyleCard';
import MainButton from 'components/MainButton';
import Back from 'components/Back';
import { StyleCardIconSvg } from 'assets/img';
import { useHistory } from 'react-router-dom';
import { serverRequestApi } from 'gql';
import {
  StyleVo,
  StyleDetailVo,
  StyleImageVo,
} from 'gql/server/__generated__/request';
import Loading from 'components/Loading';
import { useToast } from 'components/ToastContext';

// 定义跑马灯的动画效果
const marquee = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const MarqueeContainer = styled(Box)`
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  white-space: nowrap;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
`;

const LoadingBox = styled(Box)`
  width: 100%;
  height: 60vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerBox = styled(Box)`
  height: 100%;
  padding-top: calc(
    var(--tg-safe-area-inset-top) + var(--tg-content-safe-area-inset-top)
  );
  padding-bottom: env(safe-area-inset-bottom, 0px);
`;

const MarqueeContent = styled(Box)<{
  paused: boolean;
  animationDuration: string;
}>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  animation: ${(props) =>
    props.paused
      ? 'none'
      : css`
          ${marquee} ${props.animationDuration} linear infinite
        `};
  will-change: transform;
  min-width: 100%;
`;

interface StyledButtonProps {
  size: 'large' | 'medium' | 'small';
  highlighted?: string;
}

const StyledButton = styled(Button)<StyledButtonProps>`
  background: linear-gradient(
    94deg,
    rgba(60, 70, 70, 0.8) 4.44%,
    rgba(53, 60, 60, 0.7) 96.91%
  );
  border: 4px solid rgba(82, 101, 113, 0.5);
  padding: 10px 0px;
  margin: 0 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  transition: 0.3s;
  min-width: auto;
  flex-shrink: 0;
  border-radius: ${(props) => {
    switch (props.size) {
      case 'large':
      case 'medium':
        return '16px';
      case 'small':
      default:
        return '12px';
    }
  }};

  height: ${(props) => {
    switch (props.size) {
      case 'large':
        return '60px';
      case 'medium':
        return '48px';
      case 'small':
      default:
        return '40px';
    }
  }};

  &:hover {
    background-color: rgba(72, 255, 203, 0.2);
  }

  ${(props) =>
    props.highlighted === 'true' &&
    css`
      border: 4px solid #638070;
      background: radial-gradient(
        179.22% 92.71% at 50% 7.29%,
        #274446 0%,
        #546962 100%
      );
      box-shadow: 0px 6px 10.8px 0px rgba(203, 237, 188, 0.5) inset;
    `}
`;

const StyleAvatar = styled('img')<StyledButtonProps>`
  margin-left: 4px;
  margin-right: 12px;
  border-radius: 8px;

  width: ${(props) => {
    switch (props.size) {
      case 'large':
        return '44px';
      case 'medium':
        return '32px';
      case 'small':
      default:
        return '28px';
    }
  }};
  height: auto;
`;

const StyleTag = styled(Typography)<StyledButtonProps>`
  color: white;
  margin-right: 10px;

  font-size: ${(props) => {
    switch (props.size) {
      case 'large':
        return '20px';
      case 'medium':
        return '14px';
      case 'small':
      default:
        return '12px';
    }
  }};
  font-weight: ${(props) => (props.highlighted === 'true' ? 700 : 400)};
  font-family: 'Bai Jamjuree', sans-serif;
`;

const TitleBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 10px;
`;

const TitleLabel = styled(Typography)`
  color: white;
  margin-bottom: 40px;
  margin-top: 40px;
  margin-left: 24px;
  font-size: 32px;
  font-weight: 700;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const TagBox = styled(Box)`
  display: flex;
  width: 100px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 16px;
  background: #010500;
`;

const StyleCardIcon = styled(StyleCardIconSvg)`
  width: 24px;
  height: auto;
`;

const TagLabel = styled(Box)`
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 860;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
  margin-right: 5px;
`;

const CreateButtonBox = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 40px;
`;

const StyleLibrary: React.FC = () => {
  const history = useHistory();
  const { startLoading, stopLoading } = useToast();

  const [open, setOpen] = useState<boolean>(false);
  const [styleList, setStyleList] = useState<StyleVo[]>([]);
  const [selectedStyle, setSelectedStyle] = useState<StyleDetailVo>({});
  const [clickStyle, setClickStyle] = useState<StyleVo>({});
  const [imageList, setImageList] = useState<StyleImageVo[]>([]);

  const [pausedRows, setPausedRows] = useState<boolean[]>(Array(6).fill(false));
  const containerRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    async function fetchStyleList(page = 1, pageSize = 10) {
      startLoading();
      try {
        const response = await serverRequestApi.getStyleList({
          page,
          pageSize,
        });
        setStyleList(response.styles || []);
        stopLoading();
      } catch (error) {
        console.log('Error fetching style list:', error);
        stopLoading();
      }
    }
    fetchStyleList(1, 100);
  }, []);

  const speeds = useMemo(
    () => Array.from({ length: 6 }, () => `${20 + Math.random() * 20}s`),
    [],
  );

  const rows: StyleVo[][] = useMemo(() => {
    const stylesCopy = [...styleList];
    const generatedRows: StyleVo[][] = [];

    for (let i = 0; i < 6; i++) {
      const shuffledRow = stylesCopy
        .sort(() => 0.5 - Math.random())
        .slice(0, Math.floor(stylesCopy.length / (6 - i)));

      generatedRows.push(shuffledRow);

      shuffledRow.forEach((item) => {
        const index = stylesCopy.indexOf(item);
        if (index !== -1) stylesCopy.splice(index, 1);
      });
    }

    return generatedRows;
  }, [styleList]);

  const handleOpenCard = async (style: StyleVo) => {
    if (style) {
      setClickStyle(style);
      startLoading();
      await getStyleDetail(style.uuid || '');
      stopLoading();
      setOpen(true);
    }
  };

  const getStyleDetail = async (uuid: string) => {
    startLoading();
    try {
      const detailResponse = await serverRequestApi.getStyleDetail({ uuid });
      const imagesResponse = await serverRequestApi.getStyleImages({
        uuid,
        page: 1,
        pageSize: 20,
      });

      stopLoading();

      setSelectedStyle(detailResponse.style ?? {});
      console.log('detailResponse', detailResponse);
      setImageList(imagesResponse.styleImages ?? []);
      console.log('imagesResponse', imagesResponse);
    } catch (error) {
      console.log('Error fetching style detail:', error);
      stopLoading();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUseBtn = () => {
    setOpen(false);
    console.log('Use button clicked', clickStyle);
    history.replace('/chat', { selectedStyle: clickStyle });
  };

  const formatStyleSize = (size: number) => {
    switch (size) {
      case 0:
        return 'small';
      case 1:
        return 'medium';
      case 2:
      default:
        return 'large';
    }
  };

  const handleScroll = (index: number) => {
    setPausedRows((prev) => {
      const newPausedRows = [...prev];
      newPausedRows[index] = true;
      return newPausedRows;
    });

    if (containerRefs.current[index]) {
      clearTimeout((containerRefs.current[index] as any)?.scrollTimeout);
      (containerRefs.current[index] as any).scrollTimeout = setTimeout(() => {
        setPausedRows((prev) => {
          const newPausedRows = [...prev];
          newPausedRows[index] = false;
          return newPausedRows;
        });
      }, 1500);
    }
  };

  return (
    <ContainerBox>
      <Back />
      <TitleBox>
        <TitleLabel>The Styles</TitleLabel>
        <TagBox>
          <StyleCardIcon />
          <TagLabel>Model</TagLabel>
        </TagBox>
      </TitleBox>
      {styleList && styleList.length > 0
        ? rows.map((styles, rowIndex) => (
            <MarqueeContainer
              key={rowIndex}
              ref={(el) =>
                (containerRefs.current[rowIndex] = el as HTMLDivElement | null)
              }
              onScroll={() => handleScroll(rowIndex)}
            >
              <MarqueeContent
                paused={pausedRows[rowIndex]}
                animationDuration={speeds[rowIndex]}
              >
                {styles.map((style, idx) => {
                  const styleSize = formatStyleSize(style.size ?? 1);
                  const highlighted = style.highLight === 1 ? 'true' : 'false';
                  return (
                    <StyledButton
                      key={`${style.uuid}-${idx}`}
                      onClick={() => handleOpenCard(style)}
                      size={styleSize}
                      highlighted={highlighted}
                    >
                      <StyleAvatar
                        size={styleSize}
                        src={style.originalImagePath}
                      />
                      <StyleTag size={styleSize} highlighted={highlighted}>
                        {style.name}
                      </StyleTag>
                    </StyledButton>
                  );
                })}
              </MarqueeContent>
            </MarqueeContainer>
          ))
        : null}

      {/* <CreateButtonBox>
        <MainButton text="Create my own style" />
      </CreateButtonBox> */}

      <StyleCard
        open={open}
        styleDetail={selectedStyle}
        imageList={imageList}
        handleClose={handleClose}
        handleUseBtn={handleUseBtn}
        getStyleDetail={getStyleDetail}
      />
    </ContainerBox>
  );
};

export default StyleLibrary;
