import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider, { ThemedGlobalStyle } from './theme';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ApolloMultiClientProvider } from './ApolloMultiClientProvider';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ApolloMultiClientProvider>
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <ThemedGlobalStyle />
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </ApolloMultiClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
