import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
const abortController = new AbortController();
// import alert from 'stores/alert';
import user from 'stores/user';
import { showErrorToast } from 'components/ToastContext';
import { onError } from '@apollo/client/link/error';
import { GraphQLClient, RequestDocument, Variables } from 'graphql-request';
import {
  getSdk as getServerSDK,
  SdkFunctionWrapper,
} from './server/__generated__/request';
import WebApp from '@twa-dev/sdk';
import { UserProps } from 'stores/user';

function generateApolloClient(uri: string) {
  return new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: ApolloLink.from([
      //   authMiddleware,

      onError(({ graphQLErrors, networkError }) => {
        if (networkError) {
          // setTimeout(() => {
          //   alert.error({
          //     title: 'Network error',
          //     text: networkError?.message || 'Something went wrong.',
          //   });
          // }, 0);
          console.log(`[Network error]: ${networkError}`);
        }

        if (graphQLErrors) {
          // setTimeout(() => {
          //   alert.error({
          //     title: 'Error',
          //     text: graphQLErrors[0]?.message || 'Something went wrong.',
          //   });
          // }, 0);
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        }
      }),
      new HttpLink({
        uri: uri,
        fetchOptions: {
          signal: abortController.signal,
        },
      }),
    ]),
  });
}

export const serverApolloClient = generateApolloClient(
  process.env.REACT_APP_SERVER_URI || '',
);

const sdkWrapper: SdkFunctionWrapper = async (
  action: () => Promise<any>,
  operationName: string,
  operationType?: string,
  variables?: any,
): Promise<any> => {
  return action().catch((error) => {
    if (variables?._notCatchedBefore) {
      // throw error.response;
    } else {
      console.log('query error, method:', operationName, variables || '');
      console.log(JSON.stringify(error));
      // setTimeout(() => {
      //   alert.error({
      //     title: 'Error',
      //     text: error?.response?.errors?.length
      //       ? error?.response?.errors[0]?.message
      //       : 'Something went wrong.',
      //   });
      // }, 0);
    }
    return { data: {} };
  });
};

const client = new GraphQLClient(process.env.REACT_APP_SERVER_URI || '', {
  responseMiddleware: (response) => {
    if (response instanceof Error || response.errors) {
      setTimeout(() => {
        console.log(response);
        let errorMessage = '';
        if (
          (response as any)?.response?.errors &&
          (response as any)?.response?.errors.length
        ) {
          errorMessage = (response as any)?.response?.errors[0]?.message;
        } else if ((response as Error).message) {
          errorMessage = (response as Error).message;
        } else {
          errorMessage = 'Sorry!Something went wrong!';
        }
        if (errorMessage === 'Please log in first') {
          getUserInfo();
        } else {
          showErrorToast(errorMessage);
        }
      }, 0);
    }
  },
});

async function fetchAuthenticateUser(): Promise<string> {
  try {
    const initDataStr = WebApp.initData;
    const response = await serverRequestApi.authenticateUser({
      data: initDataStr,
    });

    return response.authenticateUser ?? '';
  } catch (error) {
    console.log('Error fetching AuthenticateUser:', error);

    return '';
  }
}

const getUserInfo = () => {
  user.logout();
  const initDataUnsafe = WebApp.initDataUnsafe;

  if (initDataUnsafe?.user) {
    const telegramUser = initDataUnsafe.user;
    fetchAuthenticateUser().then((token) => {
      console.log('token', token);

      if (token && token.length > 0) {
        const userInfo: UserProps = {
          id: telegramUser.id,
          username: telegramUser.username || '',
          firstName: telegramUser.first_name,
          lastName: telegramUser.last_name || '',
          photoUrl: telegramUser.photo_url || '',
          token: token,
          allows_write_to_pm: telegramUser.allows_write_to_pm || false,
          language_code: telegramUser.language_code || '',
          is_premium: telegramUser.is_premium || false,
        };

        user.setUserInfo(userInfo);
      } else {
        showErrorToast('Sorry, some errors occurred, please log in again.');
      }
    });
  }
};

const setAuthHeader = () => {
  const token = user.getToken();
  if (token) {
    client.setHeader('Authorization', `Bearer ${token}`);
  } else {
    client.setHeader('Authorization', '');
  }
};

export const serverRequestApi = getServerSDK(
  client,
  async (action, ...args) => {
    setAuthHeader();
    return sdkWrapper(action, ...args);
  },
);
