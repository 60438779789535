import Back from 'components/Back';
import Button from 'components/Button';
import { AutoColumn } from 'components/Column';
import Loading from 'components/Loading';
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { Character, CharacterTrainingStatus } from 'gql/query';
import { useCountdown } from 'usehooks-ts';
import Cell from 'components/Loading/cell';
import user from 'stores/user';
import { observer } from 'mobx-react';

const Wrapper = styled.div`
  padding: 0 20px;
  padding-top: 10px;
`;
function TrainingLoading() {
  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const [count, { startCountdown, stopCountdown }] = useCountdown({
    countStart: 10,
    intervalMs: 1000,
  });

  useEffect(() => {
    if (id) {
      const operaterCharWithStatus = async (character: Character) => {
        switch (character.status) {
          case CharacterTrainingStatus.PENDING:
            if (character.files.length > 0) {
              await user.trainingCharacter(character.id);
            } else {
              alert('分身没有对应的训练图片!');
              history.go(-1);
            }
            break;

          default:
            break;
        }
      };

      const updateCharacter = async () => {
        const res = await user.getServerCharacterInfo(id);
        // if (res) {
        //   await operaterCharWithStatus(res);
        // }
      };

      updateCharacter();

      const interval = setInterval(updateCharacter, 15000);

      return () => clearInterval(interval);
    }
  }, [id, startCountdown, user]);

  useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  useEffect(() => {
    if (count === 0) {
      stopCountdown();
      history.push('/my');
    }
  }, [count, stopCountdown]);

  return (
    <Wrapper>
      {/* <Back /> */}
      <AutoColumn gap="20px" justify="center">
        <TYPE.main fontSize={16} fontWeight={600} color={'t03'}>
          制作数字分身
        </TYPE.main>
        <AutoColumn style={{ marginTop: '30vh' }} justify="center" gap="20px">
          <Cell />
          <TYPE.main
            fontSize={24}
            mobileFz={24}
            fontWeight={600}
            color={'t04'}
            textAlign={'center'}
          >
            训练任务已提交
          </TYPE.main>
          <TYPE.main
            fontSize={16}
            mobileFz={16}
            fontWeight={600}
            color={'t03'}
            textAlign={'center'}
            lineHeight={1.6}
          >
            形象定制化训练中，每张图片约需1.5分钟，
            <br /> 请耐心等待...
          </TYPE.main>
          <TYPE.desc>{count}秒后将返回个人信息页面</TYPE.desc>
          <Button
            size="medium"
            style={{ marginTop: 80, width: '100%' }}
            onClick={() => history.push('/my')}
          >
            返回个人信息
          </Button>
        </AutoColumn>
      </AutoColumn>
    </Wrapper>
  );
}

export default observer(TrainingLoading);
