import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import fileStore from 'stores/fileStore';
import { UserProps } from 'stores/user';
import { serverRequestApi } from 'gql';

import Home from 'pages/home';
import My from 'pages/my';
import TaskDetail from 'pages/my/detail';
import ModelList from 'pages/models';
import Training from 'pages/TrainingLoading';
import SelectStyle from 'pages/selectStyle';
import CreateRole from 'pages/createRole';
import FirstCreation from 'pages/firstCreation';
import Chat from 'pages/chat';
import StyleLibrary from 'pages/styleLibrary';
import MyCreations from 'pages/MyCreations';
import AvatarDetail from 'pages/AvatarDetail';
import CreateStyle from 'pages/CreateStyle';
import StyleDetail from 'pages/styleDetail';
import MyReward from 'pages/MyReward';
import Explore from 'pages/Explore';
import Welcome from 'pages/Welcome';
import MyPhotos from 'pages/MyPhotos';

import WebApp from '@twa-dev/sdk';

import { CircularProgress } from '@mui/material';
import user from 'stores/user';
import storage from 'utils/storage';
import Login from 'pages/login';
import Lora from 'pages/my/lora';
import GlobalSimpleDialog from 'components/Dialog/GlobalSimpleDialog';
import GlobalAlert from 'components/Alert/GlobalAlert';
import { GridBgSvg } from 'assets/img';
import { useHistory } from 'react-router-dom';
import RewardDialog from 'components/RewardDialog';
import { isMobile } from 'react-device-detect';

import { ToastProvider, showErrorToast } from 'components/ToastContext';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  background: radial-gradient(
      69.32% 39.07% at 32.95% 14.69%,
      rgba(83, 178, 166, 0.3) 0%,
      rgba(25, 30, 38, 0.3) 100%
    ),
    linear-gradient(178deg, #101010 18.36%, #1d252f 98.19%);
  position: relative;
  z-index: 1;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 0;
  box-sizing: border-box;

  overflow-x: hidden;
  box-sizing: border-box;

  background: radial-gradient(
      69.32% 39.07% at 32.95% 14.69%,
      rgba(83, 178, 166, 0.3) 0%,
      rgba(25, 30, 38, 0.3) 100%
    ),
    linear-gradient(178deg, #101010 18.36%, #1d252f 98.19%);

  &.white {
    background-color: #fff;
  }

  position: relative;
  z-index: 1;
`;

const GradientTop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: url(${require('assets/img/layout/top.png')});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

const GradientBottom = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: url(${require('assets/img/layout/bottom.png')});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

const FixedLogoutWrapper = styled.div`
  position: fixed;
  top: 4px;
  right: 4px;
`;

const GridBg = styled(GridBgSvg)`
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
`;

interface NoticeProps {
  noticeId: number;
  title: string;
  content: string;
}

function App() {
  const [isFirstVisit, setIsFirstVisit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rewardOpen, setRewardOpen] = useState(false);
  const [notice, setNotice] = useState<NoticeProps | null>(null);

  useEffect(() => {
    if (typeof WebApp !== 'undefined') {
      console.log('WebApp is available');
    } else {
      console.error('WebApp is not available in this environment');
    }
    if (isMobile) {
      WebApp.requestFullscreen();
      WebApp.lockOrientation();
    }

    WebApp.expand();
    WebApp.enableClosingConfirmation();
    WebApp.disableVerticalSwipes();
    WebApp.setBackgroundColor('#17191B');
    WebApp.setBottomBarColor('#17191B');
    WebApp.setHeaderColor('#17191B');

    WebApp.ready();

    const initDataStr = WebApp.initData;

    async function fetchAuthenticateUser(): Promise<string> {
      try {
        const response = await serverRequestApi.authenticateUser({
          data: initDataStr,
        });

        return response.authenticateUser ?? '';
      } catch (error) {
        console.log('Error fetching AuthenticateUser:', error);

        return '';
      }
    }

    const hasVisited = storage.get('hasVisited14');

    if (!hasVisited || hasVisited !== 'true') {
      console.log('hasVisited', hasVisited);
      setIsFirstVisit(true);
    }

    const initDataUnsafe = WebApp.initDataUnsafe;
    console.log('initDataUnsafe', initDataUnsafe);
    const inviterId = initDataUnsafe.start_param;

    if (initDataUnsafe?.user && initDataStr && initDataStr.length > 0) {
      const telegramUser = initDataUnsafe.user;

      fetchAuthenticateUser().then((token) => {
        console.log('token', token);

        if (token && token.length > 0) {
          const userInfo: UserProps = {
            id: telegramUser.id,
            username: telegramUser.username || '',
            firstName: telegramUser.first_name,
            lastName: telegramUser.last_name || '',
            photoUrl: telegramUser.photo_url || '',
            token: token,
            allows_write_to_pm: telegramUser.allows_write_to_pm || false,
            language_code: telegramUser.language_code || '',
            is_premium: telegramUser.is_premium || false,
          };

          user.setUserInfo(userInfo);

          if (inviterId) {
            console.log('inviterId', inviterId);
            const inviteReq = { startapp: inviterId };
            serverRequestApi.invite({ inviteReq }).then(() => {});
          }

          setLoading(false);
        } else {
          showErrorToast('Sorry, some errors occurred, please log in again.');
        }
      });
    }
    // setLoading(false);
  }, []);

  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };

    const handleTouchStart = (event: TouchEvent) => {
      event.preventDefault();
    };

    const handleSelectStart = (event: Event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('selectstart', handleSelectStart);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('selectstart', handleSelectStart);
    };
  }, []);

  const [reconnectAttempts, setReconnectAttempts] = useState<number>(0);

  const MAX_RECONNECT_ATTEMPTS = 10;
  const RECONNECT_INTERVAL_BASE = 1000;

  const connectWebSocket = () => {
    const socket = new WebSocket(
      `wss://app.lazai.io/ws/connect/${user.user?.id}`,
    );

    socket.onopen = () => {
      console.log('WebSocket connected');
      setReconnectAttempts(0);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data) as NoticeProps;
      console.log('Received message:', data);
      if (data.title !== 'heartbeat' && data.noticeId && data.noticeId !== 0) {
        setNotice(data);
        setRewardOpen(true);
      }
    };

    socket.onerror = (error) => {
      console.log('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
      handleReconnect();
    };
  };

  const handleReconnect = () => {
    setReconnectAttempts((prev) => {
      if (prev >= MAX_RECONNECT_ATTEMPTS) {
        console.log('Max reconnect attempts reached. Giving up.');
        return prev;
      }

      const retryDelay = RECONNECT_INTERVAL_BASE * Math.pow(2, prev);
      console.log(`Reconnecting in ${retryDelay / 1000}s...${prev}`);

      setTimeout(() => {
        connectWebSocket();
      }, retryDelay);

      return prev + 1;
    });
  };

  useEffect(() => {
    if (user.isLoggedIn) {
      connectWebSocket();

      return () => {
        setReconnectAttempts(0);
      };
    }
  }, [user.isLoggedIn]);

  if (loading) {
    return (
      <ToastProvider>
        <LoadingWrapper>
          <GridBg />
          <CircularProgress sx={{ color: '#00ff87' }} />
        </LoadingWrapper>
      </ToastProvider>
    );
  }

  const handleCloseAction = () => {
    setRewardOpen(false);

    if (notice && notice.noticeId && notice.noticeId !== 0) {
      serverRequestApi.readNotice({ noticeId: notice?.noticeId }).then(() => {
        setNotice(null);
      });
    }
  };

  return (
    <ToastProvider>
      <Wrapper>
        {/* <audio src="./assets/audio/background.mp3" autoPlay={true} loop={true} /> */}
        <GridBg />
        {/* <GradientTop /> */}
        {/* <GradientBottom /> */}
        <Router>
          {/* <Web3ReactManager> */}
          <Switch>
            <Route exact path="/home" component={Home} />{' '}
            <Route exact path="/my" component={My} />
            <Route exact path="/my/lora/:id" component={Lora} />
            <Route exact path="/my/tasks/:id" component={TaskDetail} />
            <Route exact path="/models" component={ModelList} />
            <Route exact path="/selectstyle" component={SelectStyle} />
            <Route exact path="/trainingloading" component={Training} />
            <Route exact path="/createrole" component={CreateRole} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/firstCreation" component={FirstCreation} />
            <Route exact path="/styleLibrary" component={StyleLibrary} />
            <Route exact path="/myCreations" component={MyCreations} />
            <Route exact path="/avatarDetail" component={AvatarDetail} />
            <Route exact path="/createStyle" component={CreateStyle} />
            <Route exact path="/styleDetail" component={StyleDetail} />
            <Route exact path="/myReward" component={MyReward} />
            <Route exact path="/explore" component={Explore} />
            <Route exact path="/welcome" component={Welcome} />
            <Route exact path="/myPhotos" component={MyPhotos} />
            <Route
              exact
              path="/chat"
              render={(props) => <Chat {...props} fileStore={fileStore} />}
            />
            {isFirstVisit ? (
              <Redirect exact path="/" to="/welcome" />
            ) : (
              <Redirect exact path="/" to="/chat" />
            )}
          </Switch>
          {/* </Web3ReactManager> */}
        </Router>
        <GlobalSimpleDialog />
        <GlobalAlert />

        <RewardDialog
          rewardOpen={rewardOpen}
          fraction={notice?.content ?? ''}
          onClose={handleCloseAction}
        />

        {/* <FixedLogoutWrapper>
        <Button
          color="secondary"
          onClick={() => {
            user.logout();
            window.location.reload();
          }}
        >
          Logout
        </Button>
      </FixedLogoutWrapper> */}
      </Wrapper>
    </ToastProvider>
  );
}

export default observer(App);
