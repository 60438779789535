import React from 'react';
import { Dialog, DialogContent, Box, Typography, Button } from '@mui/material';
import styled from 'styled-components';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MainButton from 'components/MainButton';
import { CloseIconSvg, IntegrationIconSvg } from 'assets/img';

const CloseIcon = styled(CloseIconSvg)`
  width: 20px;
  height: auto;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 18px;
  right: 10px;
  min-width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
`;

const Container = styled(DialogContent)`
  position: relative;
  display: flex;
  padding: 0;
`;

const ContainerBox = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 27px;
  padding: 68px 20px 48px 20px;
  border-radius: 16px;
  opacity: 0px;
  background: radial-gradient(
    179.22% 92.71% at 50% 7.29%,
    #274446 48.5%,
    #39c391 100%
  );
  border: 2px solid #638070;
  box-shadow: -2px 4px 17.9px 0px #51e79659 inset;
  margin-bottom: 18vh;
`;

const Title = styled(Typography)`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  width: 100%;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const IntegrationBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const IntegrationIcon = styled(IntegrationIconSvg)`
  width: 66px;
  height: auto;
  margin-right: 6px;
  margin-top: 4px;
`;

const IntegrationText = styled(Box)`
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  color: #fff;
`;

interface RewardDialogProps {
  rewardOpen: boolean;
  fraction: string;
  onClose: () => void;
}

const RewardDialog: React.FC<RewardDialogProps> = ({
  rewardOpen,
  fraction,
  onClose,
}) => {
  return (
    <Dialog
      open={rewardOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
      }}
    >
      <Container>
        <ContainerBox>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
          <Title>
            Task completed!
            <br />
            Loyalty point added ✨
          </Title>
          <IntegrationBox>
            <IntegrationIcon />
            <IntegrationText>{`+${fraction}`}</IntegrationText>
          </IntegrationBox>
        </ContainerBox>
      </Container>
    </Dialog>
  );
};

export default RewardDialog;
