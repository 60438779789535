import React, { useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { IntegrationIconSvg } from 'assets/img';
import CountUp from 'react-countup';
import { opacify } from 'polished';
import MainButton from 'components/MainButton';

const ContainerBox = styled(Box)`
  width: 100%;
  height: 62vh;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PointBox = styled(Box)`
  margin-bottom: 1.5vh;
  display: flex;
  padding: 1.5vh 2vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1vh;
  align-self: stretch;
  border-radius: 16px 16px 16px 0px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: radial-gradient(
    55.63% 93.52% at 50% 6.48%,
    rgba(112, 234, 161, 0.32) 0%,
    rgba(165, 246, 197, 0.08) 100%
  );
  box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
  height: 18vh;
`;

const PointLabel = styled(Typography)`
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const PointText = styled(Box)`
  font-size: 43px;
  font-family: 'Bai Jamjuree', sans-serif;
  margin-left: 12px;
  background: linear-gradient(171deg, #7eff93 27.27%, #fff 93.89%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const CreateText = styled(Typography)`
  font-size: 20px;
  color: #fff;
  text-align: center;
  width: 100%;
  margin-top: 8vh;
  margin-bottom: 8vh;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const CreateButton = styled(Box)`
  display: flex;
  height: 52px;
  padding: 16px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 16px;
  background: radial-gradient(
    92.07% 129.88% at 7.99% 8.33%,
    #b2ff51 0%,
    #4ee88c 100%
  );
  box-shadow: 0px 8px 68px 0px rgba(0, 0, 0, 0.2);
  color: #111;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 4px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const DescBox = styled(Box)`
  display: flex;
  padding: 2vh 2.5vh;
  flex-direction: column;
  gap: 1.5vh;
  align-self: stretch;
  border-radius: 16px 16px 16px 0px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: radial-gradient(
    81.39% 83.59% at 79.14% 43.6%,
    rgba(112, 234, 161, 0.08) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
  align-items: center;
`;

const DescText = styled(Typography)`
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-family: 'Bai Jamjuree', sans-serif;
  font-weight: 400;
`;

const DescTextBox = styled(Typography)`
  width: 95%;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const PhaseBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vh;
`;

const PhaseLabel = styled(Typography)`
  text-align: center;
  font-size: 20px;
  line-height: 22px;
  font-weight: 590;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const PhaseText = styled(Typography)`
  text-align: center;
  font-family: 'Bai Jamjuree';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 4px;
  background: radial-gradient(
    58.08% 55.26% at 50.31% 32.46%,
    #7eff93 0%,
    #4ab776 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 200px;
`;

const PointIcon = styled(IntegrationIconSvg)`
  width: 15vw;
  height: auto;
`;

const CreateBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  color: #fff;
  width: 100%;
`;

interface WelcomeCardProps {
  points: number;
  phase: string;
}

const WelcomeCard: React.FC<WelcomeCardProps> = ({ points, phase }) => {
  const textSoundRef = useRef<HTMLAudioElement | null>(null);
  const history = useHistory();
  const [showButton, setShowButton] = useState(false);
  const soundRef = useRef<HTMLAudioElement | null>(null);

  const variants = {
    hidden: {
      opacity: 0,
      y: 80,
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 3,
        duration: i === 0 ? 1.5 : 1,
      },
    }),
  };

  const textVariants = {
    hidden: {
      opacity: 0,
      y: 220,
      height: 0,
      filter: 'blur(2px)',
    },
    visible: {
      opacity: 1,
      y: 0,
      height: 'auto',
      filter: 'none',
      transition: {
        duration: 2,
      },
    },
  };

  const fadeOutVariants = {
    initial: { opacity: 1 },
    fade: {
      opacity: 0,
      filter: 'blur(2px)',
      transition: {
        delay: 12,
        duration: 1.5,
      },
    },
  };

  const fadeInVariants = {
    hidden: {
      opacity: 0,
      filter: 'blur(2px)',
    },
    visible: {
      opacity: 1,
      filter: 'none',
      transition: {
        delay: 3,
        duration: 3,
        ease: 'easeOut',
      },
    },
  };

  const leftBraceVariants = {
    hidden: {
      x: 100,
      opacify: 0,
    },
    visible: {
      x: 0,
      opacify: 1,
      transition: {
        duration: 0.8,
        delay: 7,
      },
    },
  };

  const rightBraceVariants = {
    hidden: {
      opacify: 0,
      x: -100,
    },
    visible: {
      x: 0,
      opacify: 1,
      transition: {
        duration: 0.8,
        delay: 7,
      },
    },
  };

  const phaseVariants = {
    hidden: {
      opacity: 0,
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        delay: 7,
      },
    },
  };

  const goCreateAvatar = () => {
    if (soundRef.current) {
      soundRef.current
        .play()
        .then(() => {
          setTimeout(() => {
            history.push('/firstCreation');
          }, 500);
        })
        .catch((error) => {
          console.log('Error playing sound:', error);
          history.push('/firstCreation');
        });
    }
  };

  return (
    <ContainerBox>
      <audio ref={soundRef} src="/audio/textShow.mp3" preload="auto" />
      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={0}
        style={{ width: '100%' }}
      >
        <PointBox>
          <PointLabel>My LazAI Loyalty Point</PointLabel>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PointIcon />
            <PointText>
              <CountUp
                start={0}
                end={points}
                duration={1.5}
                delay={1.5}
                separator=","
                style={{
                  fontFamily: '"Bai Jamjuree", sans-serif',
                  fontWeight: 600,
                }}
              />
            </PointText>
          </Box>
        </PointBox>
      </motion.div>

      {showButton ? (
        <CreateBox>
          <motion.div
            variants={textVariants}
            initial="hidden"
            animate="visible"
            custom={0}
            style={{
              width: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
            // onAnimationStart={handleTextSoundPlay}
          >
            <CreateText>
              Transform your photo into multiple
              <br />
              unique avatars with filters. Use
              <br />
              them while earning points on
              <br />
              LazAI blockchain.
            </CreateText>
          </motion.div>
          <motion.div
            variants={fadeInVariants}
            initial="hidden"
            animate="visible"
            style={{ width: '100%' }}
          >
            <MainButton text="CREATE MY AVATAR" onClick={goCreateAvatar} />
          </motion.div>
        </CreateBox>
      ) : (
        <motion.div
          variants={fadeOutVariants}
          initial="initial"
          animate="fade"
          custom={3}
          style={{
            width: '100%',
          }}
          onAnimationComplete={() => {
            setShowButton(true);
          }}
        >
          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            custom={1}
            style={{ marginBottom: '1.5vh', width: '100%' }}
          >
            <DescBox sx={{ alignItems: 'flex-start' }}>
              <DescText>
                Earn Points Through: All activities on LazAI blockchain. Your
                Points Give You:
              </DescText>

              <DescTextBox>
                <Typography>• Participant Engagement</Typography>
                <Typography>• Better Airdrop Rewards</Typography>
                <Typography>• Priority Access & Node Rights</Typography>
                <Typography>• Community Voting Power</Typography>
              </DescTextBox>
            </DescBox>
          </motion.div>

          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            custom={2}
            style={{ width: '100%' }}
            // onAnimationStart={handleBoxSoundPlay}
          >
            <DescBox
              sx={{ justifyContent: 'center', padding: '1vh 2.5vh 1vh 2.5vh' }}
            >
              <DescText sx={{ textAlign: 'center' }}>
                You have participated in:
              </DescText>
              <PhaseBox>
                <motion.div
                  variants={leftBraceVariants}
                  initial="hidden"
                  animate="visible"
                >
                  <PhaseLabel>{'{'}</PhaseLabel>
                </motion.div>

                <motion.div
                  variants={phaseVariants}
                  initial="hidden"
                  animate="visible"
                >
                  <PhaseText>{phase}</PhaseText>
                </motion.div>

                <motion.div
                  variants={rightBraceVariants}
                  initial="hidden"
                  animate="visible"
                >
                  <PhaseLabel>{'}'}</PhaseLabel>
                </motion.div>
              </PhaseBox>
            </DescBox>
          </motion.div>
        </motion.div>
      )}
    </ContainerBox>
  );
};

export default WelcomeCard;
