import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import SpriteAnimation from 'components/SpriteAnimation';
import {
  MyRewardIconSvg,
  MyRewardSelectedSvg,
  WelcomeBotSvg,
  ExploreIconSvg,
  ExploreSelectedSvg,
} from 'assets/img';

const GlobalStyle = createGlobalStyle`
  .page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-bottom: 100px !important;
  }
`;

const Wrapper = styled(Paper)`
  position: relative;
  width: 100%;
  z-index: 99999;
  height: 80px;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-radius: 24px 24px 0px 0px;
  border-top: 1px solid rgba(255, 255, 255, 0);
  background: linear-gradient(180deg, rgba(23, 25, 27, 0.01) 0%, #17191b 20%);
  box-shadow: 0px -8px 40px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);

  .MuiBottomNavigation-root {
    height: 100%;
    background: transparent;
  }

  .MuiBottomNavigationAction-root {
    &.Mui-selected {
      color: #032b43;
    }
  }
`;

const NavImg = styled.div``;

const BotImg = styled.img`
  width: 68px;
  height: auto;
`;

const ChatIcon = styled(WelcomeBotSvg)`
  width: 68px;
  height: auto;
`;

const allowedPathsList = ['/explore', '/chat', '/myReward'];

interface BottomNavProps {
  onClick?: () => void;
}

const BottomNav: React.FC<BottomNavProps> = ({ onClick }) => {
  const location = useLocation();
  const history = useHistory();
  const [navVal, setNavVal] = useState<number>(1); //初始锚点到chat页面
  const [showNav, setShowNav] = useState<boolean>(true);

  useEffect(() => {
    const index = allowedPathsList.findIndex(
      (path) => path === location.pathname,
    );
    if (index !== -1) {
      setShowNav(true);
      setNavVal(index);
    } else {
      setShowNav(false);
    }
  }, [location.pathname]);

  const handleNavChange = (event: any, newVal: number) => {
    setNavVal(newVal);
    if (onClick) {
      onClick();
    }
    history.push(allowedPathsList[newVal]);
  };

  return (
    <Wrapper className={showNav ? '' : 'hide'} elevation={2}>
      <BottomNavigation showLabels value={navVal} onChange={handleNavChange}>
        <BottomNavigationAction
          icon={
            <NavImg>
              {navVal === 0 ? <ExploreSelectedSvg /> : <ExploreIconSvg />}
            </NavImg>
          }
        />
        <BottomNavigationAction
          icon={
            <NavImg>
              <SpriteAnimation
                frameCount={300}
                fps={60}
                folderPath="/BottomBall"
                filePrefix="qiuqiu2_"
                fileSuffix=".png"
                canvasWidth={'68px'}
                canvasHeight={'68px'}
                useLeadingZero={true}
              />
            </NavImg>
          }
        />
        <BottomNavigationAction
          icon={
            <NavImg>
              {navVal === 2 ? <MyRewardSelectedSvg /> : <MyRewardIconSvg />}
            </NavImg>
          }
        />
      </BottomNavigation>
    </Wrapper>
  );
};

export default BottomNav;
