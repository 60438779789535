import { LogoSvg } from 'assets/img';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 60px;
  position: relative;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 6px 20px;
  `}
`;

const Logo = styled(LogoSvg)`
  width: 144px;
  height: 32px;
`;

export default function Header() {
  return (
    <Wrapper>
      <Logo />
    </Wrapper>
  );
}
