import { gql } from '@apollo/client';
import { Character } from 'gql/query';
import axios from 'axios';

export function useCharacterOperations() {
  const createCharacter = async (title: string): Promise<Character | null> => {
    return Promise.resolve(null);
  };

  const uploadToCharacter = async (
    uploadPost: string,
    file?: File,
  ): Promise<boolean> => {
    if (!file) return false;
    const jsonUploadPost: any = JSON.parse(uploadPost);

    const formData = new FormData();
    jsonUploadPost.fields['Content-Type'] = file.type;
    for (const key in jsonUploadPost.fields) {
      formData.append(key, jsonUploadPost.fields[key]);
    }
    formData.append('file', file);
    try {
      const res = await axios.post(jsonUploadPost.url, formData);
      console.log(res);
      if (res) {
        return true;
      } else {
        throw new Error('图片上传失败!');
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const deleteCharacterImage = async (
    characterId: string,
    url: string,
  ): Promise<Character | null> => {
    return Promise.resolve(null);
  };

  return {
    createCharacter,
    uploadToCharacter,
    deleteCharacterImage,
  };
}
