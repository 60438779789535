import React from 'react';
import { Dialog, DialogContent, Box, Typography, Button } from '@mui/material';
import styled from 'styled-components';
import { StyleImageVo } from 'gql/server/__generated__/request';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { serverRequestApi } from 'gql';
import { useToast } from 'components/ToastContext';
import MainButton from 'components/MainButton';
import { CloseIconSvg } from 'assets/img';

const CloseIcon = styled(CloseIconSvg)`
  width: 20px;
  height: auto;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
`;

const Avatar = styled('img')`
  width: 100%;
  height: auto;
  min-height: 70vw;
  border-radius: 12px;
`;

const Photo = styled('img')`
  width: 100%;
  height: auto;
  min-height: 70vw;
  border-radius: 12px;
`;

const TitleTag = styled(Typography)`
  display: flex;
  padding: 4px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--white-pale, linear-gradient(90deg, #fff 0%, #e3ffe6 100%));
  color: #162a69;
  text-align: center;
  font-size: 14px;
  font-weight: 860;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;

  position: absolute;
  top: 10px;
  left: 10px;
`;

const ContainerBox = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  /* padding-top: 20px; */
`;

const ContainerPhotoBox = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
`;

const ImageBox = styled(Box)`
  width: 100%;
  position: relative;
`;

const PublicBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 16px;
  left: 16px;
  height: 52px;
`;

const GreenPoint = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #70f06b;
  margin-right: 4px;
`;

const PublicLabel = styled(Typography)`
  color: var(--White, #fff);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 590;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const LikeBox = styled(Box)`
  display: flex;
  position: absolute;
  bottom: 24px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  align-items: center;
  height: 36px;
`;

const LikeLabel = styled(Typography)`
  color: white;
  margin-left: 4px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const DescLabel = styled(Typography)`
  color: var(--White-80, rgba(255, 255, 255, 0.8));
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 100%;
  font-family: 'Bai Jamjuree', sans-serif;
`;

interface ImageDialogProps {
  imageOpen: boolean;
  imageSrc?: string | null;
  selectedImage?: StyleImageVo | null;
  onClose: () => void;
}

const ImageDialog: React.FC<ImageDialogProps> = ({
  imageOpen,
  imageSrc,
  selectedImage,
  onClose,
}) => {
  const { startLoading, stopLoading } = useToast();

  const handleShare = () => {
    startLoading();
    const setPublic = selectedImage?.isPublic === '0' ? '1' : '0';
    const styleLikeReq = {
      uuid: selectedImage?.uuid || '',
      isPublic: setPublic,
    };
    serverRequestApi
      .publicImage({ styleLikeReq })
      .then(() => {
        onClose();
        stopLoading();
      })
      .catch(() => {
        console.error('Error sharing image:');
        stopLoading();
      });
  };

  return (
    <Dialog
      open={imageOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
      }}
    >
      <DialogContent>
        {/* <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton> */}
        {imageSrc ? (
          <ContainerPhotoBox>
            <Photo src={imageSrc} />
          </ContainerPhotoBox>
        ) : (
          <ContainerBox>
            <ImageBox>
              <Avatar src={selectedImage?.generatedImagePath} />
              <TitleTag>Full NFT</TitleTag>

              {selectedImage?.isPublic === '1' && (
                <PublicBox>
                  <GreenPoint />
                  <PublicLabel>PUBLIC</PublicLabel>
                </PublicBox>
              )}
              <LikeBox>
                <FavoriteIcon sx={{ color: '#FF4438' }} />
                <LikeLabel>{selectedImage?.likes}</LikeLabel>
              </LikeBox>
            </ImageBox>

            <DescLabel>
              Share Your Avatar, Get Likes, Earn Points and Rewards! ✨
            </DescLabel>

            <MainButton
              text={
                selectedImage?.isPublic === '0'
                  ? 'Share & Earn!'
                  : 'Set Private'
              }
              onClick={handleShare}
            />
          </ContainerBox>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
