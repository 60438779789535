import React, { useState, useEffect } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import styled, { keyframes } from 'styled-components';

const ContainerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80px;
  justify-content: center;
`;

const StyledLinearProgressBox = styled(Box)`
  display: flex;
  width: 80%;
  height: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: linear-gradient(
    180deg,
    rgba(80, 234, 141, 0.06) 0%,
    rgba(255, 255, 255, 0.02) 100%
  );
`;

const StyledLinearProgress = styled(LinearProgress)`
  height: 6px;
  border-radius: 8px;
  background: transparent;

  & .MuiLinearProgress-bar {
    border-radius: 8px;
    background: linear-gradient(
      90deg,
      rgba(112, 234, 161, 0) 0%,
      #abffcd 66%,
      #00ff1a 99.5%
    );
    filter: blur(0.25px);
  }
`;

const ProgressText = styled(Box)`
  color: var(--White-60, rgba(255, 255, 255, 0.6));
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const dotFlashing = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const LoadingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled(Typography)<{ delay: string }>`
  width: 3px;
  height: 3px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 50%;
  background-color: currentColor;
  animation: ${dotFlashing} 1s infinite ease-in-out;
  animation-delay: ${({ delay }) => delay};
`;

interface CustomLinearProgressProps {
  onLoadingComplete: () => void;
}

const CustomLinearProgress: React.FC<CustomLinearProgressProps> = ({
  onLoadingComplete,
}) => {
  const [progress, setProgress] = useState(0);
  const [delayOver, setDelayOver] = useState(false);

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setDelayOver(true);
    }, 10500);

    return () => clearTimeout(delayTimer);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (delayOver) {
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(timer);
            onLoadingComplete();
            return 100;
          }
          return prevProgress + 10;
        });
      }, 500);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [delayOver, onLoadingComplete]);

  return (
    <ContainerBox>
      <StyledLinearProgressBox>
        <StyledLinearProgress
          variant="determinate"
          value={progress}
          sx={{ width: '96%' }}
        />
      </StyledLinearProgressBox>
      <ProgressText>
        TG identity loading
        <LoadingContainer>
          <Dot delay="0s" />
          <Dot delay="0.2s" />
          <Dot delay="0.4s" />
        </LoadingContainer>
      </ProgressText>
    </ContainerBox>
  );
};

export default CustomLinearProgress;
