import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Carousel from 'components/Carousel';
import WelcomeCard from 'components/WelcomeCard';
import { motion } from 'framer-motion';
import Spline from '@splinetool/react-spline';
import { Box } from '@mui/material';
import { serverRequestApi } from 'gql';
import { RankVo } from 'gql/server/__generated__/request';
import SpriteAnimation from 'components/SpriteAnimation';
import MainButton from 'components/MainButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-top: calc(
    var(--tg-safe-area-inset-top) + var(--tg-content-safe-area-inset-top)
  );
  padding-bottom: env(safe-area-inset-bottom, 0px);
`;

const WelcomeBot = styled(Box)<{ topValue: string }>`
  position: absolute;
  top: ${({ topValue }) => topValue};
  left: 50%;
  transform: translateX(-50%);
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const StyledButton = styled(motion.button)`
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;

  &:hover {
    background: #0056b3;
  }
`;

const buttonVariants = {
  visible: {
    opacity: 1,
    scale: 1,
  },
  hidden: {
    opacity: 0,
    scale: 0.95,
    transition: {
      duration: 0.5,
    },
  },
};

const WelcomeBotVariants = {
  hidden: {
    y: 0,
    scale: 1,
  },
  visible: {
    y: '-2.5vh',
    scale: 0.67,
    transition: {
      duration: 1.5,
    },
  },
};

const fadeInVariants = {
  hidden: {
    opacity: 0,
    filter: 'blur(6px)',
  },
  visible: {
    opacity: 1,
    filter: 'none',
    transition: {
      delay: 1,
      duration: 3,
      ease: 'easeOut',
    },
  },
};

const ScaleInVariants = {
  hidden: {
    opacity: 0,
    filter: 'blur(12px)',
  },
  visible: {
    opacity: 1,
    filter: 'none',
    transition: {
      duration: 2.5,
      ease: 'easeOut',
    },
  },
};

interface WelcomeBotProps {
  isAnimated: boolean;
}

const WelcomeBotIcon: React.FC<WelcomeBotProps> = ({ isAnimated }) => {
  const [topValue, setTopValue] = useState('7vh');

  useEffect(() => {
    const safeAreaTop = parseFloat(
      getComputedStyle(document.documentElement).getPropertyValue(
        '--tg-safe-area-inset-top',
      ) || '0',
    );
    const contentSafeTop = parseFloat(
      getComputedStyle(document.documentElement).getPropertyValue(
        '--tg-content-safe-area-inset-top',
      ) || '0',
    );

    const totalSafeAreaTop = safeAreaTop + contentSafeTop;

    if (totalSafeAreaTop > 0) {
      setTopValue('3vh');
    }
  }, []);

  return (
    <motion.div
      variants={WelcomeBotVariants}
      initial="hidden"
      animate={isAnimated ? 'visible' : 'hidden'}
      style={{ position: 'relative' }}
    >
      <WelcomeBot topValue={topValue}>
        <SpriteAnimation
          frameCount={200}
          fps={60}
          folderPath="/WelcomeBall"
          filePrefix="bigball_"
          fileSuffix=".png"
          canvasWidth={280}
          canvasHeight={280}
          useLeadingZero={true}
        />
      </WelcomeBot>
    </motion.div>
  );
};

interface StartButtonIconProps {
  onButtonClick: () => void;
  onAnimationComplete: () => void;
  isVisible: boolean;
}

const StartButtonIcon: React.FC<StartButtonIconProps> = ({
  onButtonClick,
  onAnimationComplete,
  isVisible,
}) => {
  return (
    <motion.div
      variants={fadeInVariants}
      initial="hidden"
      animate="visible"
      style={{ width: '100%' }}
    >
      <motion.div
        variants={buttonVariants}
        initial="visible"
        animate={isVisible ? 'visible' : 'hidden'}
        onAnimationComplete={onAnimationComplete}
        style={{ width: '100%', padding: '40px 32px' }}
      >
        <MainButton text="Start  LazAI  iDAO" onClick={onButtonClick} />
      </motion.div>
    </motion.div>
  );
};

export default function Welcome() {
  const soundRef = useRef<HTMLAudioElement | null>(null);

  const [isAnimated, setIsAnimated] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);
  const [myRank, setMyRank] = useState<RankVo>({});

  useEffect(() => {
    async function fetchMyRank() {
      try {
        const response = await serverRequestApi.getMyRank();
        setMyRank(response.myRank || {});
      } catch (error) {
        console.log('Error fetching top ranks:', error);
      }
    }

    fetchMyRank();

    const currentAudio = soundRef.current;

    return () => {
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
    };
  }, []);

  const handleButtonClick = () => {
    setIsButtonVisible(false);
    if (soundRef.current) {
      soundRef.current.loop = true;
      soundRef.current.play().catch((error) => {
        console.log('Error playing sound:', error);
      });
    }
  };

  return (
    <Wrapper>
      <audio ref={soundRef} src="/audio/background.mp3" preload="auto" />
      <motion.div
        variants={ScaleInVariants}
        initial="hidden"
        animate="visible"
        style={{ width: '100%' }}
      >
        <WelcomeBotIcon isAnimated={isAnimated} />
      </motion.div>
      {isAnimated ? (
        <WelcomeCard points={myRank.points ?? 0} phase="PHASE 1" />
      ) : isCarouselVisible ? (
        <Carousel onEndBootAction={() => setIsAnimated(true)} />
      ) : (
        <StartButtonIcon
          onButtonClick={handleButtonClick}
          isVisible={isButtonVisible}
          onAnimationComplete={() => setIsCarouselVisible(true)}
        />
      )}
    </Wrapper>
  );
}
