import { gql } from '@apollo/client';
import { Task, QUERY_TASK } from 'gql/query';

export function useTaskOperations() {
  const createTask = async (
    characterId: string,
    styleId: string,
  ): Promise<Task | null> => {
    return Promise.resolve(null);
  };

  const getTask = async (taskId: string): Promise<Task | null> => {
    return null;
  };

  return {
    createTask,
    getTask,
  };
}
