import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type ChatMessagesVo = {
  /**  answer */
  answer?: Maybe<Scalars['String']['output']>;
  /**  created time */
  createdAt?: Maybe<Scalars['String']['output']>;
  /**  message type 0-text, 1-image, 2-voice */
  messageType?: Maybe<Scalars['String']['output']>;
};

export type ChatReq = {
  /**  chat id */
  chatId: Scalars['String']['input'];
  /**  query */
  query: Scalars['String']['input'];
};

export type CreatStyleReq = {
  /**  enhancing face similarity */
  enhancingFaceSim: Scalars['String']['input'];
  /**  image urls */
  imageURLs: Scalars['String']['input'];
  /**  is public (0: no, 1: yes) */
  isPublic: Scalars['String']['input'];
  /**  style name */
  name: Scalars['String']['input'];
  /**  tags (prompts) */
  tags: Scalars['String']['input'];
};

export type CycleReward = {
  /**  condition */
  condition?: Maybe<Scalars['Int']['output']>;
  /**  reward */
  reward?: Maybe<Scalars['Int']['output']>;
};

export type FileVo = {
  /**  file id */
  fileId?: Maybe<Scalars['String']['output']>;
  /**  file name */
  fileUrl?: Maybe<Scalars['String']['output']>;
};

export type GenerateImgReq = {
  /**  file id */
  fileId: Scalars['String']['input'];
  /**  is public (0: no, 1: yes) */
  isPublic: Scalars['String']['input'];
  /**  style id */
  styleId: Scalars['String']['input'];
};

export type IdaoVo = {
  creatorName?: Maybe<Scalars['String']['output']>;
  creatorUrl?: Maybe<Scalars['String']['output']>;
  des?: Maybe<Scalars['String']['output']>;
  iDAOUrl?: Maybe<Scalars['String']['output']>;
  myPoints?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

export type InviteReq = {
  /**  startapp data */
  startapp: Scalars['String']['input'];
};

export type KeyValue = {
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MessageVo = {
  action?: Maybe<Scalars['String']['output']>;
  answer?: Maybe<Scalars['String']['output']>;
  extraInfo?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  authenticateUser?: Maybe<Scalars['String']['output']>;
  /**  cancel like image */
  cancelLikeImage?: Maybe<Scalars['String']['output']>;
  /**  create image */
  createImage?: Maybe<Scalars['String']['output']>;
  /**  user sign in */
  dailySign?: Maybe<Scalars['String']['output']>;
  /**  delete user file */
  deleteUserFile?: Maybe<Scalars['String']['output']>;
  /**  invite user */
  invite?: Maybe<Scalars['String']['output']>;
  /**  like image */
  likeImage?: Maybe<Scalars['String']['output']>;
  /**  like style */
  likeStyle?: Maybe<Scalars['String']['output']>;
  /**  new chat */
  newChat?: Maybe<Scalars['String']['output']>;
  /**  public image */
  publicImage?: Maybe<Scalars['String']['output']>;
  /**  public style */
  publicStyle?: Maybe<Scalars['String']['output']>;
  /**  read notice */
  readNotice?: Maybe<Scalars['String']['output']>;
};


export type MutationAuthenticateUserArgs = {
  data: Scalars['String']['input'];
};


export type MutationCancelLikeImageArgs = {
  styleLikeReq: StyleLikeReq;
};


export type MutationCreateImageArgs = {
  generateImgReq: GenerateImgReq;
};


export type MutationDeleteUserFileArgs = {
  fileId: Scalars['String']['input'];
};


export type MutationInviteArgs = {
  inviteReq: InviteReq;
};


export type MutationLikeImageArgs = {
  styleLikeReq: StyleLikeReq;
};


export type MutationLikeStyleArgs = {
  styleLikeReq: StyleLikeReq;
};


export type MutationPublicImageArgs = {
  styleLikeReq: StyleLikeReq;
};


export type MutationPublicStyleArgs = {
  styleLikeReq: StyleLikeReq;
};


export type MutationReadNoticeArgs = {
  noticeId: Scalars['Int']['input'];
};

export type NodeInfo = {
  /**  nodes completed */
  nodesCompleted?: Maybe<Scalars['Int']['output']>;
  /**  cycle rewards */
  nums?: Maybe<Array<Maybe<CycleReward>>>;
  /**  round */
  round?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  /**  get message */
  chatMessages?: Maybe<MessageVo>;
  /**  get iDAO */
  iDAO?: Maybe<Array<Maybe<IdaoVo>>>;
  /**  check image like */
  imageLikeCheck?: Maybe<Scalars['Int']['output']>;
  /**  get invite code */
  inviteCode?: Maybe<Scalars['String']['output']>;
  /**  get chat history */
  messageHistory?: Maybe<ChatMessagesVo>;
  /**  get user daily rank */
  myRank?: Maybe<RankVo>;
  /**  get my style images */
  myStyleImages?: Maybe<Array<Maybe<StyleImageVo>>>;
  /**  get my style list */
  myStyles?: Maybe<Array<Maybe<FilteredStyleVo>>>;
  /**  get user tasks detail */
  rewardsAndTasks?: Maybe<UserTasksDetailVo>;
  /**  get style detail by style id */
  style?: Maybe<StyleDetailVo>;
  /**  get style images by style id */
  styleImages?: Maybe<Array<Maybe<StyleImageVo>>>;
  /**  check style like */
  styleLikecCheck?: Maybe<Scalars['Int']['output']>;
  /**  get style list */
  styles?: Maybe<Array<Maybe<StyleVo>>>;
  /**  get generated task result */
  systemTaskResult?: Maybe<SystemTaskVo>;
  /**  get daily top 100 ranks */
  topRanks?: Maybe<Array<Maybe<RankVo>>>;
  /**  get user files */
  userFiles?: Maybe<Array<Maybe<FileVo>>>;
};


export type QueryChatMessagesArgs = {
  chatReq?: InputMaybe<ChatReq>;
};


export type QueryImageLikeCheckArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryMessageHistoryArgs = {
  chatId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMyStyleImagesArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMyStylesArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStyleArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryStyleImagesArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  uuid: Scalars['String']['input'];
};


export type QueryStyleLikecCheckArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryStylesArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySystemTaskResultArgs = {
  taskId: Scalars['String']['input'];
};


export type QueryUserFilesArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type R = {
  code: Scalars['Int']['output'];
  data?: Maybe<Scalars['String']['output']>;
  msg?: Maybe<Scalars['String']['output']>;
};

export type RFilteredStyleList = {
  code: Scalars['Int']['output'];
  data?: Maybe<Array<Maybe<FilteredStyleVo>>>;
  msg?: Maybe<Scalars['String']['output']>;
};

export type RInt = {
  code: Scalars['Int']['output'];
  data?: Maybe<Scalars['Int']['output']>;
  msg?: Maybe<Scalars['String']['output']>;
};

export type RRank = {
  code: Scalars['Int']['output'];
  data?: Maybe<Array<Maybe<RankVo>>>;
  msg?: Maybe<Scalars['String']['output']>;
};

export type RStyleDetail = {
  code: Scalars['Int']['output'];
  data?: Maybe<StyleDetailVo>;
  msg?: Maybe<Scalars['String']['output']>;
};

export type RStyleImage = {
  code: Scalars['Int']['output'];
  data?: Maybe<Array<Maybe<StyleImageVo>>>;
  msg?: Maybe<Scalars['String']['output']>;
};

export type RStyleList = {
  code: Scalars['Int']['output'];
  data?: Maybe<Array<Maybe<StyleVo>>>;
  msg?: Maybe<Scalars['String']['output']>;
};

export type RSystemTask = {
  code: Scalars['Int']['output'];
  data?: Maybe<SystemTaskVo>;
  msg?: Maybe<Scalars['String']['output']>;
};

export type RUserTasksDetail = {
  code: Scalars['Int']['output'];
  data?: Maybe<UserTasksDetailVo>;
  msg?: Maybe<Scalars['String']['output']>;
};

export type RankVo = {
  /**  user first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /**  user last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /**  points */
  points?: Maybe<Scalars['Int']['output']>;
  /**  rank */
  rank?: Maybe<Scalars['Int']['output']>;
  /**  user id */
  userId?: Maybe<Scalars['Float']['output']>;
  /**  user name */
  username?: Maybe<Scalars['String']['output']>;
};

export type StyleDetailVo = {
  /**  created time */
  createdAt?: Maybe<Scalars['String']['output']>;
  /**  enhancing face similarity */
  enhancingFaceSim?: Maybe<Scalars['String']['output']>;
  /**  user first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /**  is public (0: no, 1: yes) */
  isPublic?: Maybe<Scalars['String']['output']>;
  /**  user last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /**  likes */
  likes?: Maybe<Scalars['Int']['output']>;
  /**  loyalty points */
  loyaltyPoints?: Maybe<Scalars['Int']['output']>;
  /**  style name */
  name?: Maybe<Scalars['String']['output']>;
  /**  original image path */
  originalImagePath?: Maybe<Scalars['String']['output']>;
  /**  tags (prompts) */
  tags?: Maybe<Scalars['String']['output']>;
  /**  updated time */
  updatedAt?: Maybe<Scalars['String']['output']>;
  /**  user name */
  username?: Maybe<Scalars['String']['output']>;
  /**  style id */
  uuid?: Maybe<Scalars['String']['output']>;
};

export type StyleImageVo = {
  /**  generated image path */
  generatedImagePath?: Maybe<Scalars['String']['output']>;
  /**  is liked (0: no, 1: yes) */
  isLiked?: Maybe<Scalars['String']['output']>;
  /**  is public (0: no, 1: yes) */
  isPublic?: Maybe<Scalars['String']['output']>;
  /**  likes */
  likes?: Maybe<Scalars['Int']['output']>;
  /**  loyalty points */
  loyaltyPoints?: Maybe<Scalars['Int']['output']>;
  /**  original image path */
  originalImagePath?: Maybe<Scalars['String']['output']>;
  /**  status (0: failed, 1: success 2: processing) */
  status?: Maybe<Scalars['String']['output']>;
  /**  image id */
  uuid?: Maybe<Scalars['String']['output']>;
};

export type StyleLikeInput = {
  /**  style id */
  uuid: Scalars['String']['input'];
};

export type StyleLikeReq = {
  /**  is public */
  isPublic?: InputMaybe<Scalars['String']['input']>;
  /**  style id */
  uuid: Scalars['String']['input'];
};

export type StyleVo = {
  /**  created time */
  createdAt?: Maybe<Scalars['String']['output']>;
  /**  user id */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /**  enhancing face similarity */
  enhancingFaceSim?: Maybe<Scalars['Int']['output']>;
  /**  0: lowLight 1: highLight */
  highLight?: Maybe<Scalars['Int']['output']>;
  /**  is public (0: no, 1: yes) */
  isPublic?: Maybe<Scalars['String']['output']>;
  /**  likes */
  likes?: Maybe<Scalars['Int']['output']>;
  /**  loyalty points */
  loyaltyPoints?: Maybe<Scalars['Int']['output']>;
  /**  style name */
  name?: Maybe<Scalars['String']['output']>;
  /**  original image path */
  originalImagePath?: Maybe<Scalars['String']['output']>;
  /**  0: small 1: medium 2: large */
  size?: Maybe<Scalars['Int']['output']>;
  /**  tags (prompts) */
  tags?: Maybe<Scalars['String']['output']>;
  /**  updated time */
  updatedAt?: Maybe<Scalars['String']['output']>;
  /**  style id */
  uuid?: Maybe<Scalars['String']['output']>;
};

export type SystemTaskVo = {
  /**  created time */
  createdAt?: Maybe<Scalars['String']['output']>;
  /**  status (0: failed, 1: success 2: processing 3: pending) */
  status?: Maybe<Scalars['String']['output']>;
  /**  task type (0: image, 1: style) */
  taskType?: Maybe<Scalars['String']['output']>;
  /**  updated time */
  updatedAt?: Maybe<Scalars['String']['output']>;
  /**  generated image path */
  url?: Maybe<Scalars['String']['output']>;
};

export type TaskProgress = {
  /**  accumulated days */
  accumulatedDays?: Maybe<Scalars['Int']['output']>;
  /**  cycle length */
  cycleLength?: Maybe<Scalars['String']['output']>;
  /**  cycle rewards */
  cycleRewards?: Maybe<Array<Maybe<CycleReward>>>;
  /**  task description */
  description?: Maybe<Scalars['String']['output']>;
  /**  is daily (0: no, 1: yes) */
  isDaily?: Maybe<Scalars['String']['output']>;
  /**  is repeatable (0: no, 1: yes) */
  isRepeatable?: Maybe<Scalars['String']['output']>;
  /**  last completed time */
  lastCompleted?: Maybe<Scalars['String']['output']>;
  /**  task name */
  name?: Maybe<Scalars['String']['output']>;
  /**  next reset time */
  nextReset?: Maybe<Scalars['String']['output']>;
  /**  progress */
  progress?: Maybe<Scalars['Int']['output']>;
  /**  round */
  round?: Maybe<Scalars['Int']['output']>;
  /**  task id */
  taskId?: Maybe<Scalars['Int']['output']>;
  /**  total reward */
  totalReward?: Maybe<Scalars['Int']['output']>;
};

export type TaskProgressMapEntryVo = {
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<TaskProgressVo>;
};

export type TaskProgressVo = {
  rewards?: Maybe<Array<Maybe<TaskRewardsVo>>>;
  totalReward?: Maybe<Scalars['Float']['output']>;
};

export type TaskRewardsVo = {
  condition?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['String']['output']>;
  isFinish?: Maybe<Scalars['Int']['output']>;
  reward?: Maybe<Scalars['Float']['output']>;
};

export type UserTasksDetailVo = {
  /**  has signed in */
  hasSignedIn?: Maybe<Scalars['Int']['output']>;
  /**  node info */
  nodeInfo?: Maybe<NodeInfo>;
  /**  tasks */
  tasks?: Maybe<Array<Maybe<TaskProgressMapEntryVo>>>;
  /**  user points */
  userPoints?: Maybe<Scalars['Float']['output']>;
};

export type FilteredStyleVo = {
  /**  enhancing face similarity */
  enhancingFaceSim?: Maybe<Scalars['String']['output']>;
  /**  images */
  images?: Maybe<Array<Maybe<KeyValue>>>;
  /**  is public (0: no, 1: yes) */
  isPublic?: Maybe<Scalars['String']['output']>;
  /**  likes */
  likes?: Maybe<Scalars['Int']['output']>;
  /**  loyalty points */
  loyaltyPoints?: Maybe<Scalars['Int']['output']>;
  /**  style name */
  name?: Maybe<Scalars['String']['output']>;
  /**  tags (prompts) */
  tags?: Maybe<Scalars['String']['output']>;
  /**  style id */
  uuid?: Maybe<Scalars['String']['output']>;
};

export type GetStyleListQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetStyleListQuery = { styles?: Array<{ uuid?: string, name?: string, createdBy?: number, isPublic?: string, likes?: number, loyaltyPoints?: number, enhancingFaceSim?: number, originalImagePath?: string, tags?: string, createdAt?: string, updatedAt?: string, size?: number, highLight?: number }> };

export type GetStyleDetailQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type GetStyleDetailQuery = { style?: { firstName?: string, lastName?: string, username?: string, uuid?: string, name?: string, isPublic?: string, likes?: number, loyaltyPoints?: number, enhancingFaceSim?: string, originalImagePath?: string, tags?: string, createdAt?: string, updatedAt?: string } };

export type GetMyStyleListQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetMyStyleListQuery = { myStyles?: Array<{ uuid?: string, name?: string, tags?: string, enhancingFaceSim?: string, likes?: number, loyaltyPoints?: number, isPublic?: string, images?: Array<{ key: string, value?: string }> }> };

export type GetStyleImagesQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetStyleImagesQuery = { styleImages?: Array<{ uuid?: string, originalImagePath?: string, generatedImagePath?: string, likes?: number, isLiked?: string, status?: string, loyaltyPoints?: number, isPublic?: string }> };

export type GetMyStyleImagesQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetMyStyleImagesQuery = { myStyleImages?: Array<{ uuid?: string, originalImagePath?: string, generatedImagePath?: string, likes?: number, isLiked?: string, loyaltyPoints?: number, isPublic?: string, status?: string }> };

export type CheckStyleLikeQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type CheckStyleLikeQuery = { styleLikecCheck?: number };

export type CheckImageLikeQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type CheckImageLikeQuery = { imageLikeCheck?: number };

export type GetSystemTaskResultQueryVariables = Exact<{
  taskId: Scalars['String']['input'];
}>;


export type GetSystemTaskResultQuery = { systemTaskResult?: { url?: string, status?: string, taskType?: string, createdAt?: string, updatedAt?: string } };

export type GetTopRanksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTopRanksQuery = { topRanks?: Array<{ userId?: number, username?: string, firstName?: string, lastName?: string, points?: number, rank?: number }> };

export type GetRewardsAndTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRewardsAndTasksQuery = { rewardsAndTasks?: { userPoints?: number, hasSignedIn?: number, nodeInfo?: { nodesCompleted?: number, round?: number, nums?: Array<{ condition?: number, reward?: number }> }, tasks?: Array<{ key?: string, value?: { totalReward?: number, rewards?: Array<{ condition?: number, reward?: number, count?: string, isFinish?: number }> } }> } };

export type GetMyRankQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyRankQuery = { myRank?: { userId?: number, username?: string, firstName?: string, lastName?: string, points?: number, rank?: number } };

export type ChatMessagesQueryVariables = Exact<{
  chatReq?: InputMaybe<ChatReq>;
}>;


export type ChatMessagesQuery = { chatMessages?: { answer?: string, action?: string, extraInfo?: string } };

export type MessageHistoryQueryVariables = Exact<{
  chatId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MessageHistoryQuery = { messageHistory?: { messageType?: string, answer?: string, createdAt?: string } };

export type GetInviteCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInviteCodeQuery = { inviteCode?: string };

export type GetIdaoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIdaoQuery = { iDAO?: Array<{ title?: string, des?: string, type?: number, iDAOUrl?: string, creatorUrl?: string, creatorName?: string, myPoints?: number }> };

export type GetUserFilesQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetUserFilesQuery = { userFiles?: Array<{ fileId?: string, fileUrl?: string }> };

export type AuthenticateUserMutationVariables = Exact<{
  data: Scalars['String']['input'];
}>;


export type AuthenticateUserMutation = { authenticateUser?: string };

export type LikeStyleMutationVariables = Exact<{
  styleLikeReq: StyleLikeReq;
}>;


export type LikeStyleMutation = { likeStyle?: string };

export type CreateImageMutationVariables = Exact<{
  generateImgReq: GenerateImgReq;
}>;


export type CreateImageMutation = { createImage?: string };

export type LikeImageMutationVariables = Exact<{
  styleLikeReq: StyleLikeReq;
}>;


export type LikeImageMutation = { likeImage?: string };

export type CancelLikeImageMutationVariables = Exact<{
  styleLikeReq: StyleLikeReq;
}>;


export type CancelLikeImageMutation = { cancelLikeImage?: string };

export type PublicStyleMutationVariables = Exact<{
  styleLikeReq: StyleLikeReq;
}>;


export type PublicStyleMutation = { publicStyle?: string };

export type PublicImageMutationVariables = Exact<{
  styleLikeReq: StyleLikeReq;
}>;


export type PublicImageMutation = { publicImage?: string };

export type InviteMutationVariables = Exact<{
  inviteReq: InviteReq;
}>;


export type InviteMutation = { invite?: string };

export type DailySignMutationVariables = Exact<{ [key: string]: never; }>;


export type DailySignMutation = { dailySign?: string };

export type NewChatMutationVariables = Exact<{ [key: string]: never; }>;


export type NewChatMutation = { newChat?: string };

export type DeleteUserFileMutationVariables = Exact<{
  fileId: Scalars['String']['input'];
}>;


export type DeleteUserFileMutation = { deleteUserFile?: string };

export type ReadNoticeMutationVariables = Exact<{
  noticeId: Scalars['Int']['input'];
}>;


export type ReadNoticeMutation = { readNotice?: string };


export const GetStyleListDocument = gql`
    query getStyleList($page: Int!, $pageSize: Int) {
  styles(page: $page, pageSize: $pageSize) {
    uuid
    name
    createdBy
    isPublic
    likes
    loyaltyPoints
    enhancingFaceSim
    originalImagePath
    tags
    createdAt
    updatedAt
    size
    highLight
  }
}
    `;
export const GetStyleDetailDocument = gql`
    query getStyleDetail($uuid: String!) {
  style(uuid: $uuid) {
    firstName
    lastName
    username
    uuid
    name
    isPublic
    likes
    loyaltyPoints
    enhancingFaceSim
    originalImagePath
    tags
    createdAt
    updatedAt
  }
}
    `;
export const GetMyStyleListDocument = gql`
    query getMyStyleList($page: Int!, $pageSize: Int) {
  myStyles(page: $page, pageSize: $pageSize) {
    uuid
    name
    tags
    enhancingFaceSim
    likes
    loyaltyPoints
    isPublic
    images {
      key
      value
    }
  }
}
    `;
export const GetStyleImagesDocument = gql`
    query getStyleImages($uuid: String!, $page: Int!, $pageSize: Int) {
  styleImages(uuid: $uuid, page: $page, pageSize: $pageSize) {
    uuid
    originalImagePath
    generatedImagePath
    likes
    isLiked
    status
    loyaltyPoints
    isPublic
  }
}
    `;
export const GetMyStyleImagesDocument = gql`
    query getMyStyleImages($page: Int!, $pageSize: Int) {
  myStyleImages(page: $page, pageSize: $pageSize) {
    uuid
    originalImagePath
    generatedImagePath
    likes
    isLiked
    loyaltyPoints
    isPublic
    status
  }
}
    `;
export const CheckStyleLikeDocument = gql`
    query checkStyleLike($uuid: String!) {
  styleLikecCheck(uuid: $uuid)
}
    `;
export const CheckImageLikeDocument = gql`
    query checkImageLike($uuid: String!) {
  imageLikeCheck(uuid: $uuid)
}
    `;
export const GetSystemTaskResultDocument = gql`
    query getSystemTaskResult($taskId: String!) {
  systemTaskResult(taskId: $taskId) {
    url
    status
    taskType
    createdAt
    updatedAt
  }
}
    `;
export const GetTopRanksDocument = gql`
    query getTopRanks {
  topRanks {
    userId
    username
    firstName
    lastName
    points
    rank
  }
}
    `;
export const GetRewardsAndTasksDocument = gql`
    query getRewardsAndTasks {
  rewardsAndTasks {
    userPoints
    nodeInfo {
      nodesCompleted
      round
      nums {
        condition
        reward
      }
    }
    tasks {
      key
      value {
        totalReward
        rewards {
          condition
          reward
          count
          isFinish
        }
      }
    }
    hasSignedIn
  }
}
    `;
export const GetMyRankDocument = gql`
    query getMyRank {
  myRank {
    userId
    username
    firstName
    lastName
    points
    rank
  }
}
    `;
export const ChatMessagesDocument = gql`
    query chatMessages($chatReq: ChatReq) {
  chatMessages(chatReq: $chatReq) {
    answer
    action
    extraInfo
  }
}
    `;
export const MessageHistoryDocument = gql`
    query messageHistory($chatId: String!, $page: Int!, $pageSize: Int) {
  messageHistory(chatId: $chatId, page: $page, pageSize: $pageSize) {
    messageType
    answer
    createdAt
  }
}
    `;
export const GetInviteCodeDocument = gql`
    query getInviteCode {
  inviteCode
}
    `;
export const GetIdaoDocument = gql`
    query getIDAO {
  iDAO {
    title
    des
    type
    iDAOUrl
    creatorUrl
    creatorName
    myPoints
  }
}
    `;
export const GetUserFilesDocument = gql`
    query getUserFiles($page: Int!, $pageSize: Int) {
  userFiles(page: $page, pageSize: $pageSize) {
    fileId
    fileUrl
  }
}
    `;
export const AuthenticateUserDocument = gql`
    mutation authenticateUser($data: String!) {
  authenticateUser(data: $data)
}
    `;
export const LikeStyleDocument = gql`
    mutation likeStyle($styleLikeReq: StyleLikeReq!) {
  likeStyle(styleLikeReq: $styleLikeReq)
}
    `;
export const CreateImageDocument = gql`
    mutation createImage($generateImgReq: GenerateImgReq!) {
  createImage(generateImgReq: $generateImgReq)
}
    `;
export const LikeImageDocument = gql`
    mutation likeImage($styleLikeReq: StyleLikeReq!) {
  likeImage(styleLikeReq: $styleLikeReq)
}
    `;
export const CancelLikeImageDocument = gql`
    mutation cancelLikeImage($styleLikeReq: StyleLikeReq!) {
  cancelLikeImage(styleLikeReq: $styleLikeReq)
}
    `;
export const PublicStyleDocument = gql`
    mutation publicStyle($styleLikeReq: StyleLikeReq!) {
  publicStyle(styleLikeReq: $styleLikeReq)
}
    `;
export const PublicImageDocument = gql`
    mutation publicImage($styleLikeReq: StyleLikeReq!) {
  publicImage(styleLikeReq: $styleLikeReq)
}
    `;
export const InviteDocument = gql`
    mutation invite($inviteReq: InviteReq!) {
  invite(inviteReq: $inviteReq)
}
    `;
export const DailySignDocument = gql`
    mutation dailySign {
  dailySign
}
    `;
export const NewChatDocument = gql`
    mutation newChat {
  newChat
}
    `;
export const DeleteUserFileDocument = gql`
    mutation deleteUserFile($fileId: String!) {
  deleteUserFile(fileId: $fileId)
}
    `;
export const ReadNoticeDocument = gql`
    mutation readNotice($noticeId: Int!) {
  readNotice(noticeId: $noticeId)
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getStyleList(variables: GetStyleListQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetStyleListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetStyleListQuery>(GetStyleListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStyleList', 'query', variables);
    },
    getStyleDetail(variables: GetStyleDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetStyleDetailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetStyleDetailQuery>(GetStyleDetailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStyleDetail', 'query', variables);
    },
    getMyStyleList(variables: GetMyStyleListQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMyStyleListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMyStyleListQuery>(GetMyStyleListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMyStyleList', 'query', variables);
    },
    getStyleImages(variables: GetStyleImagesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetStyleImagesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetStyleImagesQuery>(GetStyleImagesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStyleImages', 'query', variables);
    },
    getMyStyleImages(variables: GetMyStyleImagesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMyStyleImagesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMyStyleImagesQuery>(GetMyStyleImagesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMyStyleImages', 'query', variables);
    },
    checkStyleLike(variables: CheckStyleLikeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CheckStyleLikeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CheckStyleLikeQuery>(CheckStyleLikeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'checkStyleLike', 'query', variables);
    },
    checkImageLike(variables: CheckImageLikeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CheckImageLikeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CheckImageLikeQuery>(CheckImageLikeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'checkImageLike', 'query', variables);
    },
    getSystemTaskResult(variables: GetSystemTaskResultQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSystemTaskResultQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSystemTaskResultQuery>(GetSystemTaskResultDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSystemTaskResult', 'query', variables);
    },
    getTopRanks(variables?: GetTopRanksQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTopRanksQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTopRanksQuery>(GetTopRanksDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getTopRanks', 'query', variables);
    },
    getRewardsAndTasks(variables?: GetRewardsAndTasksQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRewardsAndTasksQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRewardsAndTasksQuery>(GetRewardsAndTasksDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRewardsAndTasks', 'query', variables);
    },
    getMyRank(variables?: GetMyRankQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMyRankQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMyRankQuery>(GetMyRankDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMyRank', 'query', variables);
    },
    chatMessages(variables?: ChatMessagesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChatMessagesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChatMessagesQuery>(ChatMessagesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'chatMessages', 'query', variables);
    },
    messageHistory(variables: MessageHistoryQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MessageHistoryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MessageHistoryQuery>(MessageHistoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'messageHistory', 'query', variables);
    },
    getInviteCode(variables?: GetInviteCodeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetInviteCodeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetInviteCodeQuery>(GetInviteCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getInviteCode', 'query', variables);
    },
    getIDAO(variables?: GetIdaoQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetIdaoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetIdaoQuery>(GetIdaoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getIDAO', 'query', variables);
    },
    getUserFiles(variables: GetUserFilesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUserFilesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserFilesQuery>(GetUserFilesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserFiles', 'query', variables);
    },
    authenticateUser(variables: AuthenticateUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AuthenticateUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AuthenticateUserMutation>(AuthenticateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'authenticateUser', 'mutation', variables);
    },
    likeStyle(variables: LikeStyleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LikeStyleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LikeStyleMutation>(LikeStyleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'likeStyle', 'mutation', variables);
    },
    createImage(variables: CreateImageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateImageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateImageMutation>(CreateImageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createImage', 'mutation', variables);
    },
    likeImage(variables: LikeImageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LikeImageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LikeImageMutation>(LikeImageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'likeImage', 'mutation', variables);
    },
    cancelLikeImage(variables: CancelLikeImageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CancelLikeImageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelLikeImageMutation>(CancelLikeImageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cancelLikeImage', 'mutation', variables);
    },
    publicStyle(variables: PublicStyleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PublicStyleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PublicStyleMutation>(PublicStyleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'publicStyle', 'mutation', variables);
    },
    publicImage(variables: PublicImageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PublicImageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PublicImageMutation>(PublicImageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'publicImage', 'mutation', variables);
    },
    invite(variables: InviteMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<InviteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<InviteMutation>(InviteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'invite', 'mutation', variables);
    },
    dailySign(variables?: DailySignMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DailySignMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DailySignMutation>(DailySignDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'dailySign', 'mutation', variables);
    },
    newChat(variables?: NewChatMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<NewChatMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<NewChatMutation>(NewChatDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'newChat', 'mutation', variables);
    },
    deleteUserFile(variables: DeleteUserFileMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteUserFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteUserFileMutation>(DeleteUserFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteUserFile', 'mutation', variables);
    },
    readNotice(variables: ReadNoticeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ReadNoticeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReadNoticeMutation>(ReadNoticeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'readNotice', 'mutation', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;