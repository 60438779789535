import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { IntegrationIconSvg, CheckIconSvg } from 'assets/img';

const ContainerBox = styled(Box)`
  display: flex;
  padding-top: 4px;
  padding-right: 4px;
  position: relative;
`;

const ContentBox = styled(Box)`
  display: flex;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: relative;
`;

const IntegrationBox = styled(Box)`
  display: flex;
  padding: 12px var(--Space-0, 0px) 4px var(--Space-0, 0px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 11px 11px 8px 8px;
  box-shadow: 0px 12px 12.3px 0px rgba(0, 0, 0, 0.08);
`;

const IntegrationIcon = styled(IntegrationIconSvg)`
  width: 38px;
  height: auto;
  margin-right: 5px;
`;

const IntegrationText = styled(Typography)`
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const DayText = styled(Typography)`
  color: #fff;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 860;
  line-height: 16px;
`;

interface IntegrationCardProps {
  width?: string;
  points: number;
  dayText: string;
  selected?: boolean;
}

const CheckIcon = styled(CheckIconSvg)`
  width: 20px;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
`;

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  width = '78px',
  points,
  dayText,
  selected = false,
}) => {
  return (
    <ContainerBox>
      <ContentBox
        sx={{
          minWidth: width,
          background: selected
            ? 'var(--tab-highlighted, radial-gradient(87.44% 39.17% at 81.16% 0%, rgba(238, 252, 220, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(90deg, rgba(112, 234, 124, 0.35) 0%, rgba(112, 234, 161, 0.35) 100%))'
            : 'var(--Subtle-box-bg, radial-gradient(150.2% 50% at 50% 50%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.1) 100%))',
        }}
      >
        <IntegrationBox
          sx={{
            background: selected
              ? 'var(--Tab-ico-highlighted, radial-gradient(83.29% 84.26% at 31.25% 8.93%, #EAF2EB 14.6%, #B6FBBD 100%))'
              : 'var(--Subtle-box-bg, radial-gradient(150.2% 50% at 50% 50%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.1) 100%))',
          }}
        >
          <IntegrationIcon />
          <IntegrationText sx={{ color: selected ? '#111' : '#fff' }}>
            +{points === 0 ? '?' : points}
          </IntegrationText>
        </IntegrationBox>
        <DayText>{dayText}</DayText>
      </ContentBox>
      {selected && <CheckIcon />}
    </ContainerBox>
  );
};

export default IntegrationCard;
