import React, { useState } from 'react';
import { Box, Tabs, Tab, Button } from '@mui/material';
import styled from 'styled-components';
import Back from 'components/Back';
import EarnInPicwiz from './EarnInPicwiz';
import Leaderboard from './Leaderboard';
import BottomNav from 'components/BottomNav';
import { motion } from 'framer-motion';

const ContainerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  padding-top: calc(
    var(--tg-safe-area-inset-top) + var(--tg-content-safe-area-inset-top)
  );
  padding-bottom: env(safe-area-inset-bottom, 0px);
`;

const ContentBox = styled(Box)`
  margin-top: 8px;
  padding: 20px 20px 10px 20px;
  width: 100%;
`;

const CustomTabs = styled(Tabs)`
  border-radius: 8px;
  background-color: #101f1d;
  width: 100%;
  display: flex;
  .MuiTabs-indicator {
    display: none;
  }
`;

const MyReward: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const variants = {
    hidden: {
      opacity: 0,
      y: 80,
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.4,
        duration: 0.5,
      },
    }),
  };

  return (
    <ContainerBox>
      <Back />

      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={0}
        style={{ width: '100%' }}
      >
        <ContentBox>
          <CustomTabs value={selectedTab} onChange={handleTabChange} centered>
            <Tab
              sx={{
                flex: 1,
                borderRadius: '8px',
                color:
                  selectedTab === 0
                    ? 'white !important'
                    : 'rgba(255, 255, 255, 0.5)',
                background:
                  selectedTab === 0
                    ? 'var(--Bubble-highlight, radial-gradient(179.22% 92.71% at 50% 7.29%, #274446 0%, #546962 100%))'
                    : 'transparent',
                border:
                  selectedTab === 0
                    ? '2px solid var(--Bubble-border, #638070)'
                    : 'none',
                fontWeight: selectedTab === 0 ? 600 : 400,
                fontSize: '14px',
                fontFamily: '"Bai Jamjuree", sans-serif',
                textTransform: 'none',
              }}
              label="Earn in picwiz"
            />
            <Tab
              sx={{
                flex: 1,
                borderRadius: '8px',
                color:
                  selectedTab === 1
                    ? 'white !important'
                    : 'rgba(255, 255, 255, 0.5)',
                background:
                  selectedTab === 1
                    ? 'var(--Bubble-highlight, radial-gradient(179.22% 92.71% at 50% 7.29%, #274446 0%, #546962 100%))'
                    : 'transparent',
                border:
                  selectedTab === 1
                    ? '2px solid var(--Bubble-border, #638070)'
                    : 'none',
                fontWeight: selectedTab === 1 ? 600 : 400,
                fontSize: '14px',
                fontFamily: '"Bai Jamjuree", sans-serif',
                textTransform: 'none',
              }}
              label="LazAI Leaderboard"
            />
          </CustomTabs>
        </ContentBox>
      </motion.div>

      {selectedTab === 0 && <EarnInPicwiz />}
      {selectedTab === 1 && <Leaderboard />}
      <BottomNav />
    </ContainerBox>
  );
};

export default MyReward;
