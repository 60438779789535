import Back from 'components/Back';
import { AutoColumn, ColumnCenter } from 'components/Column';
import { RowCenter } from 'components/Row';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { TYPE } from 'theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useGetModels } from 'hooks/useModels';
import {
  CharacterTrainingStatus,
  Model,
  Style,
  Task,
  TaskStatus,
} from 'gql/query';
import { useTaskOperations } from 'hooks/useTask';
import Loading from 'components/Loading';
import Fab from '@mui/material/Fab';
import DrawIcon from '@mui/icons-material/AutoFixHigh';
import PrevIcon from '@mui/icons-material/ArrowBackIos';
import NextIcon from '@mui/icons-material/ArrowForwardIos';
import { getFacechainImageSrc } from 'utils/index';
import Button from 'components/Button';
import userStore from 'stores/user';
import { observer } from 'mobx-react';
import { LeftArrowSvg, RightArrowSvg } from 'assets/img';
import { useCountdown } from 'usehooks-ts';
const Wrapper = styled.div`
  padding: 0 10px;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FullLoading = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 99;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SliderWrapper = styled(Slider)`
  width: calc(100vw - 40px);
  margin-top: 10px;
  height: 120px;

  .slick-prev {
    left: -20px;
    z-index: 9;
  }

  .slick-next {
    right: -20px;
    z-index: 9;
  }

  div {
    width: 100%;
  }

  .slick-slide {
    padding: 20px;
  }
`;

const SliderImg = styled.img`
  width: 100%;
  cursor: pointer;
  opacity: 0.6;
  border-radius: 16px;

  &.selected {
    opacity: 1;
  }
`;

// const PreviewImg = styled.img`
//   // width: calc(100vw - 16px);
//   /* position: absolute; */
//   height: 100%;
//   width: auto;
//   border-radius: 16px;
//   // overflow: hidden;
// `;

const PreviewImg = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
  width: 100%;
`;

const FabBigButton = styled(Fab)`
  width: 100%;
  padding: 0 16px;
  border-radius: 24px;
  z-index: 2;
`;

function PrevBtn(props: any) {
  const { className, style, onClick } = props;

  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <PrevIcon />
    </div>
  );
}

function NextBtn(props: any) {
  const { className, style, onClick } = props;

  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <NextIcon />
    </div>
  );
}

function PrewArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <LeftArrowSvg
      className={className}
      style={{ ...style, display: 'block', width: '32px', height: '32px ' }}
      onClick={onClick}
    />
  );
}

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <RightArrowSvg
      className={className}
      style={{ ...style, display: 'block', width: '32px', height: '32px ' }}
      onClick={onClick}
    />
  );
}
function SelectStyle() {
  const [loading, setLoading] = useState<boolean>(false);
  const [styles, setStyles] = useState<Style[]>([]);
  const [selectedStyle, setSelectedStyle] = useState<Style>();
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const modelId = searchParams.get('model_id');
  const styleId = searchParams.get('style_id');
  const getModelsFunc = useGetModels();
  const { getTask, createTask } = useTaskOperations();
  const { user, serverUserInfo, getServerUserInfo } = userStore;

  const [count, { startCountdown, stopCountdown }] = useCountdown({
    countStart: 10,
    intervalMs: 1000,
  });

  useEffect(() => {
    if (count === 0) {
      stopCountdown();
      history.push('/my');
    }
  }, [count, stopCountdown]);

  useEffect(() => {
    if (!serverUserInfo?.defaultCharacter) {
      history.push('/createrole');
    }

    if (
      serverUserInfo?.defaultCharacter &&
      serverUserInfo.defaultCharacter.status !==
        CharacterTrainingStatus.COMPLETE
    ) {
      alert('您的分身尚未制作完成，请等待分身制作完成再操作!');
      history.push('/my');
    }
  }, [user]);

  useEffect(() => {
    if (modelId) {
      getModelsFunc().then((models: Model[]) => {
        for (const model of models) {
          if (model.id === modelId) {
            setStyles(model.styles);
            setSelectedStyle(model.styles[0]);
          }
        }
      });
    }
  }, [modelId]);

  useEffect(() => {
    if (styles.length > 0 && styleId) {
      for (const style of styles) {
        if (style.id === styleId) {
          setSelectedStyle(style);
        }
      }
    }
  }, [styles, styleId]);

  const handleStartGen = async () => {
    if (loading) {
      return;
    }

    if (!user || !selectedStyle || !serverUserInfo?.defaultCharacter) {
      return;
    }

    setLoading(true);
    await createTask(serverUserInfo.defaultCharacter.id, selectedStyle.id);
    startCountdown();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: <PrewArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <Wrapper>
      {loading && (
        <FullLoading>
          <AutoColumn style={{ marginTop: '30vh' }} justify="center" gap="20px">
            <Loading size={60} />
            <TYPE.main
              fontSize={24}
              mobileFz={24}
              fontWeight={600}
              color={'t04'}
              textAlign={'center'}
            >
              正在生成中
            </TYPE.main>
            <TYPE.main
              fontSize={16}
              mobileFz={16}
              fontWeight={600}
              color={'t03'}
              textAlign={'center'}
              lineHeight={1.6}
            >
              正在精心制作中...
              <br />
              可稍后在生成历史中查看
            </TYPE.main>
            <TYPE.desc>{count}秒后将返回个人信息页面</TYPE.desc>
            <Button
              size="medium"
              style={{ marginTop: 80, width: '100%' }}
              onClick={() => history.push('/my')}
            >
              返回个人信息
            </Button>
          </AutoColumn>
        </FullLoading>
      )}
      <Back />
      <ColumnCenter
        style={{
          // gridTemplateRows: 'auto auto 1fr auto auto',
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '8px',
          height: '100%',
        }}
      >
        <RowCenter style={{ marginBottom: '10px' }}>
          <TYPE.main fontSize={16} fontWeight={600} color={'t03'}>
            选择风格
          </TYPE.main>
        </RowCenter>
        <RowCenter style={{ marginBottom: '10px' }}>
          <TYPE.desc fontSize={12}>{selectedStyle?.title}</TYPE.desc>
        </RowCenter>
        <RowCenter
          style={{
            position: 'relative',
            flex: 1,
            marginBottom: '10px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <PreviewImg
            src={getFacechainImageSrc(selectedStyle?.image || '')}
            alt={selectedStyle?.title}
          /> */}
          {/* <div style={{backgroundImage: `url(${getFacechainImageSrc(selectedStyle?.image || '')})`, height: '100%'}}></div> */}
          <PreviewImg
            style={{
              backgroundImage: `url(${getFacechainImageSrc(
                selectedStyle?.image || '',
              )})`,
            }}
          />
        </RowCenter>

        <SliderWrapper {...settings}>
          {styles.map((style: Style) => {
            return (
              <div key={style.id}>
                <SliderImg
                  src={getFacechainImageSrc(style.image)}
                  alt={style.title}
                  onClick={() => setSelectedStyle(style)}
                  className={selectedStyle?.id === style.id ? 'selected' : ''}
                />
              </div>
            );
          })}
        </SliderWrapper>

        <RowCenter style={{ marginTop: '10px' }}>
          <FabBigButton color="primary" onClick={() => handleStartGen()}>
            立即生成
            <DrawIcon sx={{ ml: 2 }} />
          </FabBigButton>
        </RowCenter>
      </ColumnCenter>
    </Wrapper>
  );
}

export default observer(SelectStyle);
